import { Injectable } from '@angular/core';
import { Router, Resolve } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';

import { UsuarioService } from './usuario.service';
import { AgenteService } from './agente.service';
import {  } from './agente.service';

import { Usuario } from '../_interfaces/usuario';
import { Agente } from '../_interfaces/agente';
import { IDBResponse } from '../_interfaces/responses';
import { of } from 'rxjs/observable/of';

@Injectable()
export class UserResolver implements Resolve<Usuario | string> {
    
    constructor(private $usuario: UsuarioService, private $router: Router) {}
    
    resolve(): Observable<Usuario | string> {
        return new Observable((ob: Observer<Usuario | string>) => {
            this.$usuario.getSesionUsuario()                
                .switchMap(usuario => this.$usuario.getUsuario(usuario.usuario))
                .subscribe(
                    user => {
                        ob.next(user);
                        ob.complete();
                    },
                    (err: IDBResponse) => {
                        this.$router.navigateByUrl('/login');
                        ob.next(<string>err.data);
                        ob.complete();
                    }
                );
        });
    }
}

@Injectable()
export class AgentResolver implements Resolve<Agente | string> {
    
    constructor(private $agente: AgenteService, private $router: Router) {}
    
    resolve(): Observable<Agente | string> {

        return this.$agente.getSesionAgente()
            .catch(err => {
                this.$router.navigateByUrl('/login');
                return of(err)
            })
    }
}
