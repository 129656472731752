import { Component, OnInit, Input, OnChanges, SimpleChange, SimpleChanges, Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

interface IChanges extends SimpleChanges {
    src: SimpleChange
}

@Component({
    selector: 'audio-player',
    template: `
    <div class="row">
        <div class="col-xs-12" style="margin-bottom: 10px">
            <div class="progress" style="margin-bottom: 0px">
                <div class="progress-bar" role="progressbar" [attr.aria-valuemax]="audio.duration" 
                    [style.width]=" width + '%'">
                    {{(audio.currentTime || 0) | toDuracion | date:'HH:mm:ss'}}
                </div>
            </div>
        </div>
        <div class="col-xs-9">
            <div class="btn-group btn-group-justified" role="group" aria-label="...">
                <div class="btn-group" role="group">
                    <button type="button" class="btn btn-sm btn-success" (click)="play()"><i class="fa fa-play"></i></button>
                </div>
                <div class="btn-group" role="group">
                    <button type="button" class="btn btn-sm btn-success" (click)="stop()"><i class="fa fa-stop"></i></button>
                </div>
                <div class="btn-group" role="group">
                    <button type="button" class="btn btn-sm btn-success" (click)="pause()"><i class="fa fa-pause"></i></button>
                </div>
                <div class="btn-group" role="group">
                    <button type="button" class="btn btn-sm btn-success" (click)="fwd(-1)"><i class="fa fa-backward"></i></button>
                </div>
                <div class="btn-group" role="group">
                    <button type="button" class="btn btn-sm btn-success" (click)="fwd(1)"><i class="fa fa-forward"></i></button>
                </div>
            </div>
        </div>
        <div class="col-xs-3">
            <a [attr.href]="src" class="btn btn-sm btn-block btn-danger" alt="Download" download><i class="fa fa-download"></i></a>
        </div>
    </div>
    `
})
export class AudioComponent implements OnInit, OnChanges {
        
    private interval$: Subscription;
    private audio = new Audio();
    private width: number;
    
    @Input() src: string;
    constructor() { }

    ngOnInit() { 
        this.audio.onpause = this.endAudio
    }
    
    ngOnChanges(cambios: IChanges) {
        if (!!cambios.src && !!cambios.src.currentValue) {
            this.audio.src = cambios.src.currentValue;
        }
    }
    
    endAudio() {
        if (this.interval$ && !this.interval$.closed) {
            this.interval$.unsubscribe();
        }
    }
    
    play(): void {
        if (this.audio.currentTime === this.audio.duration) {
            this.audio.currentTime = 0;
        }

        this.audio.play();
        this.interval$ = Observable
            .interval(1000)
            .map(() => {
                return (this.audio.currentTime / this.audio.duration) * 100
            })
            .subscribe(width => this.width = width);
    }
    
    stop(): void {
        this.audio.pause();
        this.interval$.unsubscribe();
        this.width = 0;
        this.audio.currentTime = 0;
    }
    
    pause(): void {
        this.audio.pause();
        this.interval$.unsubscribe()
    }
    
    fwd(direccion: number): void {
        if (this.audio.currentTime + (4 * direccion) < this.audio.duration) {
            this.audio.currentTime  +=  (4 * direccion);
        }
    }
} 

@Pipe({ name: 'toDuracion', pure: false })
export class TimePipe implements PipeTransform {

    transform(value: number) {
        if (value !== undefined) {
            try {
                // let time = new Date(value * 1000);
                // time.setHours(time.getHours() - 19);
                const time = new Date('2017-01-01 00:00:00');
                value = (new Date(time.getTime() + value * 1000)).getTime();
            } catch (e) {}
        }
        return value;
    }
}
