import { Component, OnInit, Input } from '@angular/core';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';
import { Observable } from 'rxjs/Observable';

@Component({
    selector: 'auto-search',
    templateUrl: 'autosearch.component.html',
    providers: [
        { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: AutoSearchComponent }
    ]
})
export class AutoSearchComponent implements OnInit, ControlValueAccessor {
    
    private _onChange: (value: any) => void;    
    
    private busqueda = new FormControl();
    private _header: string = "Opciones";
    @Input() set header(h) {
        this._header = (h || 'Opciones')
    }
    get header() { return this._header; }
    @Input() opciones: string[] = [];
    @Input() styleClass: string = 'form-control';
    @Input() style: string = '';
    
    private _opciones: string[] = [];
    private opciones$: Observable<string[]>
    
    constructor() { 
        this.opciones$ = this.busqueda.valueChanges
            .filter((txt: string) => txt.length > 2)
            .debounceTime(150)
            .map((txt:string) => {
                let sArray = _.filter(this.opciones, opcion=> {
                    return opcion.toUpperCase().indexOf(txt.toUpperCase()) > -1
                });
                return (sArray.length > 0 ? sArray : [txt]);
            })
    }

    ngOnInit() { }
    
    agregar(o: string) {
        if(_.findIndex(this._opciones, _o => {
            return _o === o;
        }) < 0) {
            this._opciones.push(o);
            this._onChange(this._opciones)
        }
    }
    
    remover(o: string) {
        let index = _.findIndex(this._opciones, _o => {
                        return _o === o;
                    });
        if (index > -1 ) {
            this._opciones.splice(index, 1);
            this._onChange(this._opciones)
        }
    }
    
    writeValue(value: any) {
        this.busqueda.setValue('')
        this._opciones = [];
        if (!!value && Array.isArray(value)) {
            value.forEach(v => {
                this._opciones.push(v);
            });
        }
    }
    
    registerOnChange(fn: (value: any) => void) {
        this._onChange = fn;
    }
    
    registerOnTouched(){}
} 