import {Component, OnInit, Input} from '@angular/core';
import {FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import {IAgentMonitor, IChannel } from '../_interfaces/monitor';
import {IForma, ITipificacionLog, IFormaPregunta } from '../_interfaces/forma';
import * as moment from 'moment';
import * as _ from 'lodash';

import {FormasService } from '../_services/formas.service';
import { Observable } from 'rxjs/Observable';

function validacionRespuesta(tipo: string) {
    return (control: FormControl): null => {
        if (tipo === 'NUMERO' && ((control.value as string).includes(' ') || (control.value as string).includes('-')) ) {
            let nuevoValor = (control.value as string).replace(/ /g, '')
            nuevoValor = nuevoValor.replace(/-/g, '')
            control.setValue(nuevoValor);
        }
        return null; 
    }
}


@Component({
    selector: 'agente-tipificacion',
    templateUrl: 'tipificacion.component.html'
})
export class TipificacionComponent implements OnInit {
    
    private titulo: { texto: string, subtitulo: string };
    private llamada: IChannel;
    @Input() agente: IAgentMonitor;
    
    private frm: FormGroup;
    
    constructor(private $forma: FormasService, private fb: FormBuilder) {}

    ngOnInit() {
        this.frm = this.fb.group({
            log: this.fb.array([])            
        });
    }
    
    asignarLlamada(llamada: IChannel) {
        this.llamada = llamada ? {...llamada} : undefined; // _.cloneDeep(this.agente.channel);
        const preguntasFormArray = <FormArray>this.frm.controls['log'];
        
        for (let i = 0; i < preguntasFormArray.length; i++) {
            preguntasFormArray.at(i).patchValue({
                callid: this.llamada.UniqueID,
                fecha: new Date()
            });
        }
    }
    
    lanzarForma(forma: IForma, llamada: IChannel) {
        
        this.titulo = {
            texto: forma.titulo,
            subtitulo: forma.descripcion
        };
        Observable.of(forma.id)
            .switchMap(id => this.$forma.getPreguntasFormulario(id))
            .do(() => this.llamada = llamada ? {...llamada} : undefined)
            .subscribe(
                preguntas => {
                    
                    this.ngOnInit();
                    const logs = <FormArray> this.frm.controls['log'];

                    _.sortBy(preguntas, 'orden').forEach(p => {
                        
                        // Control para validar en tiempo real el input del cliente.
                        const respuestaCtrl = new FormControl('', Validators.compose([
                            Validators.required, 
                            Validators.minLength(1),
                            // validacionRespuesta(p.tipo)
                        ]))
                        
                        const log = this.fb.group({
                            callid: '',
                            idformulario: '',
                            fecha: '',
                            autor: '',
                            pregunta: '',
                            respuesta: respuestaCtrl,
                            tipo: '',
                            opciones: this.fb.array([])
                        });
                        
                        // Queda libre tipo y respuesta para ser diligenciado
                        log.patchValue({
                            callid: (!!llamada ? llamada.UniqueID : 'no-call_' + ObjectID()),
                            idformulario: p.idformulario,
                            fecha: moment().format('YYYY-MM-DD HH:mm:ss'),
                            autor: this.agente.idagente,
                            pregunta: p.pregunta,
                            tipo: p.tipo,
                            respuesta: ''
                        });
                        
                        const opciones = <FormArray> log.controls['opciones'];
                        _.sortBy((p.respuestas as {tipo: string, respuesta: string}[]), 'tipo').reverse().forEach(r => {
                            const _r = this.fb.group({
                                tipo: '',
                                respuesta: ''
                            });
                            _r.setValue(r);
                            opciones.push(_r);
                        });
                        
                        logs.push(log);
                        
                    });
                    $('#agenteTipificacionModal').modal({
                        backdrop: 'static',
                        keyboard: false
                    });
                }
            );
    }
    
    /**
     * @description Guardar el log en DB
     * @param formas: ITipificacionLog[]
     */
    guardarFormulario(formas: ITipificacionLog[]) {                
        this.$forma.guardarLog(formas)
            .subscribe(
                res => console.log(res),
                err => console.warn(err)
            );
        
        $('#agenteTipificacionModal').modal('hide');
    }
} 

export function ObjectID(): string {
    return Date.now().toString(16);
}
