import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IAuthKey, EApiContextos } from '../../../_interfaces/apikey';
import { of } from 'rxjs';
import { FormControl } from '@angular/forms';
import { filter, map, finalize, tap } from 'rxjs/operators';

@Component({
  selector: 'app-apikey',
  templateUrl: './apikey.component.html',
  styleUrls: ['./apikey.component.css']
})
export class ApikeyComponent implements OnInit {

    _apiContexto = Object.keys(EApiContextos).map(key => ({ nombre: key, contexto: EApiContextos[key] }));
    ipAddress = '';
    
    @Input() 
    apiKey: IAuthKey;

    @Output()
    apikeyMod = new EventEmitter<IAuthKey>();
    
    @Output()
    eliminar = new EventEmitter<IAuthKey>();

    constructor() { }

    ngOnInit() {
    }

    modificarContexto(contexto: string, apiKey: IAuthKey) {
        this.apikeyMod.emit(Object.assign({}, apiKey, { contexto: contexto }));
    }

    agregarHost(ip: string, apikey: IAuthKey) {
        of(ip).pipe(
            // Validar si la ip es valida o es === localhost
            filter(address => address === 'localhost' || this.validIP(address)),
            // verificar que no exista en la lista
            filter(address => !(apikey.host as string[]).some(h => h === address)),
            // Agregarla a la lista.
            map(address => Object.assign({}, apikey, { host: [...(apikey.host as string[]), address] })),
            // Limpiar el campo
            tap(() => this.ipAddress = ''),
            // emitir el cambio
        ).subscribe(newKey => this.apikeyMod.emit(newKey));
    }

    eliminarHost(ip: string, apikey: IAuthKey) {
        of(ip).pipe(
            // verificar que no exista en la lista
            filter(address => (apikey.host as string[]).some(h => h === address)),
            // Eliminarlo a la lista.
            map(address => Object.assign({}, apikey, { host: (apikey.host as string[]).filter(h => h !== address) })),
            // emitir el cambio
        ).subscribe(newKey => this.apikeyMod.emit(newKey));
    }

    eliminarApiKey(apiKey: IAuthKey) {
        this.eliminar.emit(apiKey);
    }

    private validIP(ipTest: string): boolean {
        const rx = /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/;
        return rx.test(ipTest);
    }
}
