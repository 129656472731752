import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'toTime'})
export class TimePipe implements PipeTransform {

    transform(value: number) {
        if (value !== undefined) {
            try {
                // 
                // let time = new Date(value * 1000);
                // time.setHours(time.getHours() - 19);
                const time = new Date('2017-01-01 00:00:00');
                value = (new Date(time.getTime() + value * 1000)).getTime();
            } catch (e) {}
        }
        return value;
    }
}
