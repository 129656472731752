import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';

import { Grupo } from '../_interfaces/grupo';
import { HeaderService } from './header.service';
import { IDBResponse } from '../_interfaces/responses';

@Injectable()
export class GruposService {
    
    constructor(private $http: Http, private $headers: HeaderService) {}
    
    getGrupos(): Observable<Grupo[]> {
        return this.$http.get(this.$headers.getAPIurl() + '/grupos', { headers: this.$headers.getHeaders() })
            .map(res => res.json())
            .catch((err: Response) => Observable.throw(err.json()));
    }
    
    /**
     * Funciones de edicion, creación y eliminacion de grupos
     */
    updateGrupo(data: { grupo: Grupo, update: boolean }): Observable<IDBResponse> {
        let http$: Observable<Response>;
        if (data.update === undefined) {
            http$ = this.$http.delete(this.$headers.getAPIurl() + '/grupo/' + data.grupo.idgrupo, { headers: this.$headers.getHeaders() })
        } else if (data.update) {
            http$ = this.$http.post(this.$headers.getAPIurl() + '/grupo', data.grupo, { headers: this.$headers.getHeaders() })
        } else {
            http$ = this.$http.put(this.$headers.getAPIurl() + '/grupo', data.grupo, { headers: this.$headers.getHeaders() })
        }
        return http$.map(res => res.json())
            .catch((err: Response) => Observable.throw(err.json()));
    }
}
