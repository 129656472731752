import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';
import { QueueComponent } from './queue/queue.component';
import { AuxiliaryComponent } from './auxiliary/auxiliary.component';
import { AgenteComponent } from './agente/agente.component';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
    imports: [
        CommonModule, 
        FormsModule, 
        ReactiveFormsModule, 
        FileUploadModule,
        PipesModule
    ],
    exports: [QueueComponent, AuxiliaryComponent, AgenteComponent],
    declarations: [QueueComponent, AuxiliaryComponent, AgenteComponent],
    providers: [],
})
export class ConsolaCallModule { }
