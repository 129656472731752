export class Queue {
    nombre: string;
    notificacion = false;
    estrategia = 'rrmemory';
    timeout = '12';
    modelo: string;
    enEspera = 'default';
    servicelevel = 60;
    poll = '';
    directo ? = '';
    //
    agentes: string | {idagente: string}[] = [];
    
    constructor() {} 
}
