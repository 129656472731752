import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IOperacion, ITopMenu, EVistas } from './adminmenu/adminMenu.component';
import { Usuario } from '../_interfaces/usuario';
import { UsuarioService } from '../_services/usuario.service';

import { ConsolaCallcenterComponent } from './consola_call/consola_call.component';

@Component({
    selector: 'admin-comp', 
    styleUrls: ['admin.component.css'],
    templateUrl: 'admin.component.html'
})
export class AdminComponent implements OnInit {
    
    @ViewChild(ConsolaCallcenterComponent)
    callcenter: ConsolaCallcenterComponent;
    
    private nombre: string;          // Nombre de la cola
    
    private vm = EVistas;           // variable usuada en el switch
    private usuario: Usuario;
    private _menu: ITopMenu = {};
    
    constructor (private $route: ActivatedRoute, private $usuario: UsuarioService) { 
        this.usuario = <Usuario>this.$route.snapshot.data['usuario'];
        console.log('Bienvenido ' + this.usuario.usuario);
        this.$usuario.usuario = this.usuario;
    }
    
    ngOnInit () { 
        this.ejecutarOperacion({ vista: EVistas.EXTENSIONES, subVista: '' });
    }
    
    /**
     * Función que cambia la vista o lanza nuevos componentes
     * @param op: IOperacion
     */
    ejecutarOperacion(op: IOperacion) {
        
        // Si es callcenter, obtener los colas disponibles
        if (op.vista === EVistas.CALLCENTER && !!op.subVista) {
            if (op.subVista === 'NUEVA') {
                this.callcenter.crearQueue();            
            } else {
                this._menu.nombre = op.subVista;
            }
        } else {
            // cambiar la vista
            this._menu = {
                current_view: op.vista,
                nombre: op.subVista
            };
        }
    }

    addMenuOptions (opciones: string[]) {
        this._menu.menu = opciones;
    }
    
    addStats (stats: {nombre: string, valor: string}[]) {
        this._menu.stats = stats;
    }
    
    changeMenuNombre (nombre: string) {
        console.log(nombre)
        this._menu.nombre = nombre;
    }
} 
