import { Component, OnInit } from '@angular/core';
import { SocketService, EComando, IComando } from '../../_services/socket.service';
import { NetworkService } from '../../_services/network.service';
import { Network } from '../../_interfaces/network';

import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
    selector: 'sistema-tag',
    templateUrl: 'sistema.component.html'
})
export class SistemaComponent implements OnInit {
    
    private nets: ENetwork[];
    private netEdit: ENetwork = {};
    private nuevaFecha: string;
    private sysInfo: ESysInfo;
    private editDate = false;
    
    private operacion: string;
    private mensaje: string;
    
    private fw = {
        allow: 1,
        ip: '',
        descr: '',
        operacion: 'add'
    }
    
    constructor(private $monitor: SocketService, private $network: NetworkService) { 
        this.$monitor.getSysInfo()
            .subscribe(
                (res: ESysInfo) => {
                    if (res && !(res.date instanceof Date)) {
                        res.date = new Date(res.date);
                    }this.sysInfo = res;
                },
                err => console.warn(err)
            )
    }
    
    editarFecha(evento) {
        this.editDate = true;
        this.nuevaFecha = moment(this.sysInfo.date.getTime()).format('YYYY-MM-DD HH:mm:ss');
    }
    
    cargarNets(net: ENetwork[]) {
        this.nets = net;
    }
    
    editarNet(net: string) {
        this.netEdit = _.cloneDeep(_.find(this.sysInfo.network, ['inet', net]));
    }
    
    guardarInet() {
        this.mensaje = 'Recuerda que si estas cambiando la IP de la interfaz de LAN deberas ingresar con esta nueva IP.';
        $('#dialogProcess').modal();
        const inet = _.clone(this.netEdit);
        delete inet.ipv6;
        this.$monitor.enviarComando({
                comando: EComando.KERBERUS_NETS,
                data: { inet: JSON.stringify(inet), opcion: 'PUT'}
            })
            .subscribe(
                res => {
                    console.log(res)
                    this.mensaje = <string>res.data;
                },
                err => console.warn(err)
            );
    }

    ngOnInit() { }
    
    setOperacion(op: string) {
        this.operacion = op;
        $('#dialogConfirm').modal();
    }
    
    /**
     * 
     */
    ejecutarOperacion() {
        let cmd: IComando;
        $('#dialogConfirm').modal('hide');
        switch (this.operacion) {
            case 'reiniciando':
                cmd = {
                    comando: EComando.KERBERUS_POWER,
                    data: { cmd: '-r'}
                }
                break;
            case 'apagando':
                cmd = {
                    comando: EComando.KERBERUS_POWER,
                    data: { cmd: '-h'}
                }
                break;
            default:
                break;
        }
        
        if (!!cmd) {
            this.$monitor.enviarComando(cmd)
                .subscribe(
                    res => {
                        console.log(res);
                        this.mensaje = 'El sistema se está ' + this.operacion + ' en este momento...';
                        $('#dialogProcess').modal();
                    },
                    err => console.warn(err)
                );
        }
    }

    generarBackUP() {
        this.$monitor.enviarComando({
                comando: EComando.BACK_UP_CONF,
                data: {}
            })
            .subscribe(
                res => {
                    console.log(res);
                    const backupFile = 'cmds/' + <string>res.data;
                    window.open(backupFile);
                },
                err => console.warn(err)
            );
    }
    
    mtto() {
        $('#modalWait_kerberus').modal();
        this.$monitor.enviarComando({
                comando: EComando.SQL_MTT,
                data: {}
            })
            .subscribe(
                res => {
                    console.log(res);
                    $('#modalWait_kerberus').modal('hide');
                    this.mensaje = 'Mantenimiento realizado exitosamente';
                    $('#dialogProcess').modal();
                },
                err => console.warn(err)
            );
    }
    
    actualizarFecha() {
        const fecha = this.nuevaFecha.split(' ');
        $('#modalWait_kerberus').modal();
        this.$monitor.enviarComando({
                comando: EComando.SET_DATE,
                data: {
                    fecha: fecha[0],
                    hora: fecha[1]
                }
            })
            .subscribe(
                res => {
                    this.editDate = false;
                    console.log(res);
                    $('#modalWait_kerberus').modal('hide');
                    this.mensaje = 'Tarea ejecutada exitosamente';
                    $('#dialogProcess').modal();
                },
                err => console.warn(err)
            );
    }
    
    generarRuta(nuevaRuta: {destino: string, gw: string}) {
        $('#modalWait_kerberus').modal();
        let ruta = '';
        if (nuevaRuta.destino.indexOf('/') > -1) { // net
            ruta = '-net ' + nuevaRuta.destino + ' gw ' + nuevaRuta.gw;
        } else {
            ruta = '-host ' + nuevaRuta.destino + ' gw ' + nuevaRuta.gw;
        }
        
        this.$monitor.enviarComando({
                comando: EComando.KERBERUS_ROUTES,
                data: { operacion: 'add', ruta: ruta}
            })
            .subscribe(
                res => {
                    console.log(res);
                    $('#modalWait_kerberus').modal('hide');
                },
                err => console.warn(err)
            );
    }
    
    
    eliminarRuta(ruta: ERuta) {
        $('#modalWait_kerberus').modal();
        // Encontrar el prefijo de la máscara        
        const maskNodes = ruta.mascara.match(/(\d+)/g);
        let cidr = 0;
        for (const i in maskNodes) {
            if (!!i) {
                const wise = Number.parseFloat(maskNodes[i]);
                cidr += ((( wise >>> 0 ).toString(2)).match(/1/g) || []).length;
            }
        }
        
        const _ruta = '-' + ruta.flag + ' ' + ruta.destino + '/' + cidr + ' gw ' + ruta.gateway;
        this.$monitor.enviarComando({
                comando: EComando.KERBERUS_ROUTES,
                data: { operacion: 'del', ruta: _ruta}
            })
            .subscribe(
                res => {
                    console.log(res);
                    $('#modalWait_kerberus').modal('hide');                    
                },
                err => console.warn(err)
            );
    }
    // </editor-fold>
    
    bloquarSIP() {
        $('#modalWait_kerberus').modal();
        this.$monitor.enviarComando({
                comando: EComando.FIREWALL,
                data: {
                    allow: 0,
                    ip: 'ALL_SIP',
                    descr: 'Bloqueo SIP',
                    operacion: 'add'
                }
            })
            .subscribe(
                res => {
                    console.log(res);
                    $('#modalWait_kerberus').modal('hide');
                },
                err => console.warn(err)
            );
    }
    
    crearRegla(regla: Object) {
        $('#modalWait_kerberus').modal();
        this.$monitor.enviarComando({
                comando: EComando.FIREWALL,
                data: regla
            })
            .subscribe(
                res => {
                    console.log(res);
                    $('#modalWait_kerberus').modal('hide');
                },
                err => console.warn(err)
            );
    }
    
    eliminarRegla(regla: EFirewall) {
        console.log(regla);
        let data;
        if (regla.fuente && regla.dport) {
            data = {
                    allow: 0,
                    ip: 'ALL_SIP',
                    descr: 'Bloqueo SIP',
                    operacion: 'del'
                }
        } else {
            data = {
                    allow: (regla.politica === 'ACCEPT' ? 1 : 0),
                    ip: regla.fuente,
                    descr: regla.descripcion,
                    operacion: 'del'
                }
        }
        
        $('#modalWait_kerberus').modal();
        this.$monitor.enviarComando({
                comando: EComando.FIREWALL,
                data: data
            })
            .subscribe(
                res => {
                    console.log(res);
                    $('#modalWait_kerberus').modal('hide');
                },
                err => console.warn(err)
            );
    }
} 

interface ESysInfo {
    hostname: string,
    date: Date,
    uptime: string,
    loadAverage: string,
    discos: EDisco[],
    mem: EMem[]
    network: ENetwork[],
    rutas: ERuta[],
    firewall: EFirewall[]
}

interface EDisco {
    Avail: string,
    Filesystem: string,
    Mounted: string,
    Size: string,
    Use: string,
    Used: string
}

interface EMem {
    tipo: string,
    free: string,
    used: string
    total: string
}

interface ENetwork {
    inet?: string,
    ip?: string,
    ipv6?: string,
    mac?: string,
    mascara?: string
}

interface ERuta {
    destino: string,
    gateway: string,
    mascara: string,
    flag: string
}

interface EFirewall {
    politica: string, 
    fuente: string, 
    descripcion: string,
    dport?: string
}
