import { Injectable} from '@angular/core';
import { Http, Response } from '@angular/http';
import { HeaderService } from './header.service';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { IDBResponse, EDBResponseType} from '../_interfaces/responses';
import { IVR } from '../_interfaces/ivr';

import { FileUploader, Headers } from 'ng2-file-upload/ng2-file-upload'

@Injectable()
export class IVRService {
    private upload$ = new Subject<IDBResponse>();
    uploader: FileUploader = new FileUploader({});
    
    constructor(private $http: Http, private $header: HeaderService) {
        const headers = <Headers[]>[{
                name: 'authorization',
                value: this.$header.getHeader('authorization')
            }];
        
        // Conf. 
        this.uploader.setOptions({ url: this.$header.getAPIurl() + '/ivr/audio', headers: headers, itemAlias: 'audio_attach' });
        
        // Funciones uploading...
        this.uploader.onCompleteItem =  (item, response: any, status) => {
            const res = <IDBResponse>JSON.parse(response);
            this.uploader.removeFromQueue(item);
            this.upload$.next(res);
        };
        
        this.uploader.onAfterAddingFile = (item) => {
            this.upload$.next({
                tipo: EDBResponseType.OK,
                data: item.file.name
            });
        }
        
        this.uploader.onErrorItem = (item: any, response: any, status: any) => {
            console.log('errr:', item, status, response);
            const res = <IDBResponse>JSON.parse(response);
            this.uploader.removeFromQueue(item)
            this.upload$.error(res);
        };
    }
    
    getUploadEvents() {
        return this.upload$.asObservable();
    }
    
    getUploader(): FileUploader {
        return this.uploader;
    }
    
    subirAudio(): void {
        this.uploader.queue.forEach(item => {
            if ((item.file.size / 1024 / 1024) > 10) {
                this.upload$.error(<IDBResponse>{
                    tipo: EDBResponseType.ERR,
                    data: 'El archivo pesa más de 10Mb'
                });
            } else {
                item.upload();
            }
        });
    }
    
    getDids(): Observable<IVR[]> {
        return this.$http.get(this.$header.getAPIurl() + '/ivrs', { headers: this.$header.getHeaders() })
            .map(res => res.json())
            .map((ivrs: IDBResponse) => ivrs.data as IVR[])
            .catch((err: Response) => Observable.throw(err.json()));
    }
    
    getDid(did: string): Observable<IVR> {
        return this.$http.get(this.$header.getAPIurl() + '/ivr/' + did, { headers: this.$header.getHeaders() })
            .map(res => (res.json() as IVR[]).pop())            
            .catch((err: Response) => Observable.throw(err.json()));
    }
    
    actualizarIVR(data: { ivr: IVR, update: boolean }): Observable<IDBResponse> {
        let http$: Observable<Response>;
        console.log(data.update)
        if (data.update === undefined) {
            http$ = this.$http.delete(this.$header.getAPIurl() + '/ivr/' + data.ivr.did, { headers: this.$header.getHeaders() })
        } else if (!data.update) {
            http$ = this.$http.put(this.$header.getAPIurl() + '/ivr', data.ivr, { headers: this.$header.getHeaders() })
        } else if (data.update) {
            http$ = this.$http.post(this.$header.getAPIurl() + '/ivr', data.ivr, { headers: this.$header.getHeaders() })
        }
        return http$.map(res => res.json())
            .catch((err: Response) => Observable.throw(err.json()));
    }
}
