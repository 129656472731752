import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'txtLimit'})
export class TextLimitPipe implements PipeTransform {
    transform(value: string, longitud: number): any {
        if (!!value && value.length > longitud) {
            return value.substring(0, (longitud - 3)) + '...';
        } else {
            return value;
        }
    }
}
