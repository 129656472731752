import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioService } from '../_services/usuario.service';
import { AgenteService } from '../_services/agente.service';
import { RELEASE_VERSION } from '../../_environments/ambient';

@Component({
    selector: 'login-comp',
    templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {
    errMensaje = '';
    RELEASE = RELEASE_VERSION;
    private loading = false;
    private defaultOp = 'AGENTE';
    constructor(private $route: Router, private $usuario: UsuarioService, private $agente: AgenteService) { }

    ngOnInit() { }
    
    validar(usr: { usuario: string, password: string, tipo: string | ETipoUsuario}) {
        
        this.loading = true;
        this.errMensaje = '';
        const tipo = ETipoUsuario[usr.tipo];

        // Validar usuario
        let validador$ = this.$usuario.validarUsuario(usr)
            .do(() => this.$route.navigateByUrl('/admin'));

        if (tipo === ETipoUsuario.AGENTE) {
            validador$ = this.$agente.loginAgente(usr)
                .do(() => this.$route.navigateByUrl('/agente'));
        }

        validador$
            .finally(() => this.loading = false)
            .subscribe(() => this.errMensaje = 'Un momento por favor...',
                err => {
                    console.log(err);
                    this.errMensaje = (err.data || err.msj);
                }
            );
    }
} 

enum ETipoUsuario {
    ADMIN, AGENTE
}
