import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckboxComponent } from './checkbox.component';
import { RadioComponent } from './radio.component';
import { AutoSearchComponent } from './autosearch.component';
import { DatePickerDirective } from '../datepicker/datepicker.directive';
import { ToggleComponent } from './toggle.component';
import { NewToggleComponent } from './toggle.componentV1';
import { QueueSelectorComponent } from './queue-selector/queue-selector.component';

@NgModule({
    imports: [BrowserModule, FormsModule, ReactiveFormsModule],
    declarations: [CheckboxComponent, RadioComponent, AutoSearchComponent, DatePickerDirective, ToggleComponent, NewToggleComponent, QueueSelectorComponent],
    exports: [CheckboxComponent, RadioComponent, AutoSearchComponent, DatePickerDirective, ToggleComponent, NewToggleComponent, QueueSelectorComponent]  
})
export class UIModule {}
