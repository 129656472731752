import { NgModule } from '@angular/core';

import { TimePipe } from './pipes';

@NgModule({
    imports: [],
    exports: [TimePipe],
    declarations: [TimePipe],
    providers: [],
})
export class PipesModule { }
