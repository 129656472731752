import { Injectable } from '@angular/core';
import * as json2csv from 'json2csv';

@Injectable()
export class FileHandlerService {

    constructor() { }

    exportCSVFile(headers: string[], items: Object[], fileTitle: string) {
        
        const jsonParser = json2csv.parse;
        const csv = jsonParser(items, {            
            fields: headers
        });
    
        const exportedFilenmae = fileTitle + '.csv' || 'export.csv';
    
        const blob = new Blob(['\ufeff', csv], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', exportedFilenmae);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }
}