export interface IAuthKey {
    key?: string;
    fecha_creacion?: string;
    host: string | string[];
    contexto: string;
    tipo?: EApiKeyTipo;
}

export enum EApiKeyTipo {
    API_KEY = 'API_KEY',
    MANAGER_KEY = 'MANAGER_KEY'
}

export enum EApiContextos {
    Ninguno = '*',
    Agente = '/agente',
    Callback = '/callback',
    CRM_Manager = '/manager'
}
