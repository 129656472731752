import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';
import { FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';
import {IForma} from '../../_interfaces/forma';
import {QueueService} from '../../_services/queue.service';
import {Subscription} from 'rxjs/Subscription';
import * as _ from 'lodash';

@Component({
    selector: 'formulario-tag',
    templateUrl: 'formulario.component.html',
    styleUrls: ['formulario.component.css']
})
export class FormularioComponent implements OnInit {
    
    private mostarOtro = true;
    private formulario: FormGroup;
    private queueSubs: Subscription;
    private formStatus: Subscription;
    private queueList: string[];
    @Output() isFormInvalid = new EventEmitter<boolean>();
    
    constructor(private fb: FormBuilder, private $queue: QueueService) {}

    ngOnInit() {
        this.formulario = this.fb.group({
            id: '',
            fecha: '',
            titulo: ['', Validators.required],
            descripcion: '',
            colas: '',
            pregunta: this.fb.array([])
        });
        
        if (!!this.queueSubs && !this.queueSubs.closed) {
            this.queueSubs.unsubscribe();
        }
        
        this.queueSubs = this.$queue.getQueues()
            .subscribe(queues => {
                this.queueList = [];
                queues.forEach(q => {
                    this.queueList.push(q.nombre);
                });
            });
        
        // Si es creado de nuevo, y aun esta suscrito
        if (!!this.formStatus && !this.formStatus.closed) {
            this.formStatus.unsubscribe();
        }
        
        // Iniciar el status 
        this.isFormInvalid.emit(this.formulario.invalid);
        // Observar cambios en el formulario y emtirlos
        this.formStatus = this.formulario.statusChanges
            .subscribe(
                status => this.isFormInvalid.emit((status === 'INVALID'))
            );
    }
    
    getFormularioValue(): IForma {
        return this.formulario.value as IForma;
    }
    
    iniciarPregunta() {
        return this.fb.group({
            orden: '',
            tipo: '',
            pregunta: ['', Validators.required],
            respuestas: this.fb.array([this.iniciarRespuesta()])
        });
    }
    
    iniciarRespuesta(tipo?: string) {
        const respuesta = this.fb.group({
            tipo: ['', Validators.required],
            respuesta: ''
        });
        respuesta.patchValue({
            tipo: (tipo || 'SIMPLE')
        });
        return respuesta;
    }
    
    addPregunta(tipoPregunta?: string) {
        const preguntasControl = <FormArray>this.formulario.controls['pregunta'];
        const pregunta = this.iniciarPregunta();
        pregunta.patchValue({
            orden: preguntasControl.length,
            tipo: (tipoPregunta || 'SELECCION')
        });
        preguntasControl.push(pregunta);
    }
    
    delPregunta(p: FormArray, index: number) {
        p.removeAt(index);
    }
    
    addRespuesta(r: FormArray, tipo?: string) {
        if (!!tipo && tipo.indexOf('OTRO') > -1) {
            this.mostarOtro = false;
        }
        r.push(this.iniciarRespuesta(tipo));
    }
    
    delRespuesta(r: FormArray, index: number) {
        const _v: {tipo: string}[] = r.value;
        if (_v[index].tipo.indexOf('OTRO') > -1) {
            this.mostarOtro = true;
        }
        r.removeAt(index);
    }
    
    cargarFormulario(forma: IForma) {
        this.ngOnInit();

        const preguntasFormArray = <FormArray>this.formulario.controls['pregunta'];
        this.formulario.patchValue({
            id: forma.id,
            fecha: forma.fecha,
            titulo: forma.titulo,
            descripcion: forma.descripcion,
            colas: forma.colas
        });

        _.sortBy(forma.pregunta, 'orden').forEach(pregunta => {
            // Crear la pregunta
            const preguntaGF = this.iniciarPregunta();
            const respuestaFormArray = <FormArray>preguntaGF.controls['respuestas'];
            respuestaFormArray.removeAt(0); // Eliminar por defecto

            preguntaGF.patchValue({
                orden: pregunta.orden,
                tipo: pregunta.tipo,
                pregunta: pregunta.pregunta,
            });

            const respuestas = <{tipo: string, respuesta: string}[]>pregunta.respuestas;
            respuestas.forEach(respuesta => {
                const resFG = this.iniciarRespuesta();                            
                resFG.setValue(respuesta);
                respuestaFormArray.push(resFG);
            });

            preguntasFormArray.push(preguntaGF);
        });
    }
} 

@Pipe({ name: 'otro', pure: false})
export class ContieneOtroPipe implements PipeTransform {
    transform(value: FormArray): boolean {
        const _v: { tipo: string }[] = value.value;
        return _.findIndex(_v, ['tipo', 'OTRO']) > -1;
    }
}
