import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'toNumber'})
export class NumberFormatPipe implements PipeTransform {

    transform(value: string | number) {
        if (value !== undefined) {
            try {
                value = Number.parseInt(value.toString());
            } catch (e) {}
        }
        return value;
    }
}
