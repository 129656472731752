import {Component, AfterViewInit, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormasService } from '../../_services/formas.service';
import { IForma } from '../../_interfaces/forma';
import {Subject} from 'rxjs/Subject';
import {Subscription} from 'rxjs/Subscription';
import {FormularioComponent} from './formulario.component';

import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
    selector: 'tipificacion-tag',
    templateUrl: 'tipificaciones.component.html'    
})
export class TipificacionesComponent implements AfterViewInit, OnInit, OnDestroy {
    
    @ViewChild(FormularioComponent)
    frmComponent: FormularioComponent;
    
    private isFormInvalid: boolean;
    private _idFormulario: string;    
    private updaterSubscription: Subscription;
    private updater$ = new Subject<boolean>();
    private formasDisponibles: IForma[];
    
    constructor(private $form: FormasService) { 
        $('#modalWait_kerberus').modal()
        this.updaterSubscription = this.updater$.asObservable()
            .filter(update => !!update) // solo si es true
            .switchMap(() => this.$form.getFormularios())
            .subscribe(
                formas => {
                    formas.forEach(f => {
                        f.fecha = moment(f.fecha).toDate()
                    });
                    this.formasDisponibles = formas;
                    $('#modalWait_kerberus').modal('hide')
                }
            )
    }
    
    ngOnDestroy() {
        this.updaterSubscription.unsubscribe();
    }
    
    ngOnInit() {}

    ngAfterViewInit() { 
        this.updater$.next(true);
    }
    
    setValid(value: boolean) {
        console.log(value)
        this.isFormInvalid = value;
    }
    
    ordernar(campo: string) {
        const formas = _.sortBy(_.cloneDeep(this.formasDisponibles), campo);
        this.formasDisponibles = _.cloneDeep(formas);
    }
    
    modalFormulario() {
        this.frmComponent.ngOnInit();
        $('#tipiForm').modal();
    }
    
    delForma(forma: IForma) {
        const eliminar = confirm(`Desea eliminar el formulario ${forma.titulo}?`);
        if (eliminar === true) {
            $('#modalWait_kerberus').modal()
            this.$form.guardarFormulario({
                update: undefined,
                frm: forma
            })
                .subscribe(
                    res => {                    
                        console.log(res);
                        this.updater$.next(true)
                        $('#modalWait_kerberus').modal('hide')
                    }
                )
        }
    }
    
    cargarFormulario(forma: IForma) {
        $('#modalWait_kerberus').modal()
        this.$form.getPreguntasFormulario(forma.id)
            .subscribe(
                r => {
                    forma.pregunta = r;
                    this.frmComponent.cargarFormulario(forma);
                    $('#modalWait_kerberus').modal('hide');
                    setTimeout(() => {
                        $('#tipiForm').modal()
                    }, 500);
                },
                err => console.log(err)
            );
    }
    
    guardarFormulario() {
        const forma = this.frmComponent.getFormularioValue();
        $('#tipiForm').modal('hide');
        $('#modalWait_kerberus').modal()
        this.$form.guardarFormulario({
            update: (!forma.id || forma.id === '' ? false : true),
            frm: forma// this.formulario.value
        }).subscribe(
            res => {
                console.log(res);
                this.updater$.next(true);
                $('#modalWait_kerberus').modal('hide');
            },
            err => console.warn(err)
        )
    }
    
    resultados(id: string) {
        this._idFormulario = id;
        $('#modalResultados').modal();
    }
} 

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'toFecha'})
export class ToFechaPipe implements PipeTransform {
    transform(value: any, formato: string): any {
        if (!!value) {
            return moment(value).format((formato || 'll'));
        } else {
            return value;
        }
    }
}


@Pipe({ name: 'txtLimit'})
export class TextLimitPipe implements PipeTransform {
    transform(value: string, longitud: number): any {
        if (!!value && value.length > longitud) {
            return value.substring(0, (longitud - 3)) + '...'
        } else {
            return value;
        }
    }
}

@Pipe({ name: 'Upper1st'})
export class Upper implements PipeTransform {
    transform(value: string, longitud: number): any {
        if (!!value) {
            return value.substring(0, 1).toLocaleUpperCase() + value.substring(1).toLocaleLowerCase();
        } else {
            return value;
        }
    }
}
