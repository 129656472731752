import { Pipe, PipeTransform } from '@angular/core';
import { Secuencia } from '../../../_interfaces/ivr';
import * as _ from 'lodash';

@Pipe({name: 'contexto'})
export class FiltroPorContextoPipe implements PipeTransform {

    transform(value: Secuencia[], tipo: string) {
        
        if (value !== undefined) {
            try {
                value = _.filter(value, ['contexto', tipo]);
            } catch (e) {}
        }
        return value;
    }
}
