import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IvrComponent } from './ivr.component'
import { DIDComponent } from './did/did.component';
import { SecuenciaComponent } from './secuencia/secuencia.component';
import { FiltroPorContextoPipe } from './pipes/contexto.pipe';

import { FileUploadModule } from 'ng2-file-upload/ng2-file-upload';

@NgModule({
    imports: [BrowserModule, FormsModule, ReactiveFormsModule, FileUploadModule],
    declarations: [IvrComponent, DIDComponent, SecuenciaComponent, FiltroPorContextoPipe],
    exports: [IvrComponent, DIDComponent, SecuenciaComponent, FiltroPorContextoPipe]
})
export class IvrModule {}
