import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { HeaderService } from './header.service'
import { Observable } from 'rxjs/Observable';

import {IForma, IFormaPregunta, ITipificacionLog, IResultadosQuery, EQueryType, IResultado } from '../_interfaces/forma';
import { IDBResponse } from '../_interfaces/responses'

@Injectable()
export class FormasService {

    constructor(private $http: Http, private $header: HeaderService) { }
    
    getFormularios(): Observable<IForma[]> {
        return this.$http.get(this.$header.getAPIurl() + '/formas', {headers: this.$header.getHeaders()})
            .map((res: Response) => res.json())
    }
    
    getPreguntasFormulario(id: string): Observable<IFormaPregunta[]> {
        return this.$http.get(this.$header.getAPIurl() + '/forma/' + id, { headers: this.$header.getHeaders() })
            .map(res => res.json())
    }
    
    guardarFormulario(data: { update: boolean, frm: IForma }): Observable<IDBResponse> {
        let http$: Observable<Response>;
        
        if (data.update === undefined) { // Eliminar
            http$ = this.$http.delete(this.$header.getAPIurl() + '/forma/' + data.frm.id, { headers: this.$header.getHeaders() });
        } else if (!!data.update) { // Actualizar
            http$ = this.$http.post(this.$header.getAPIurl() + '/forma', data.frm, { headers: this.$header.getHeaders() });
        } else { // guardar
            http$ = this.$http.put(this.$header.getAPIurl() + '/forma', data.frm, { headers: this.$header.getHeaders() });
        }
        
        return http$.map(res => res.json())
            .catch((err: Response) => Observable.throw(err.json()));
    }
    
    guardarLog(forma: ITipificacionLog[]): Observable<IDBResponse> {
        return this.$http.put(this.$header.getAPIurl() + '/forma_log', forma, { headers: this.$header.getHeaders() })
            .map(res => res.json())
            .catch((err: Response) => Observable.throw(err.json()));
    }
    
    buscarResultados(query: IResultadosQuery): Observable<IResultado[]> {
        return this.$http.post(this.$header.getAPIurl() + '/resultados_forma_log', query, { headers: this.$header.getHeaders() })
            .map(res => res.json() as IDBResponse)            
            .map(response => response.data as IResultado[])
            .catch((err: Response) => Observable.throw(err.json()));
    }
}
