import { Component, OnInit, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'ui-checkbox',
    templateUrl: 'checkbox.component.html',
    providers: [
        { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: CheckboxComponent }
    ]
})
export class CheckboxComponent implements OnInit, ControlValueAccessor {
    
    private value = false;
    private _onChange: (value: any) => void;
    @Input() texto: string;
    
    private _style = 'btn-default';
    @Input() set style(s) {
        this._style = (s || 'btn-default')
    }
    get style() {return this._style }
    
    constructor() { }

    ngOnInit() {}
    
    writeValue(value: boolean) {
        console.log(value, 'Valor')
        this.value = !!value;
        if (value === undefined) {
            this._onChange(this.value)
        }
    }
    
    registerOnChange(fn: (value: any) => void) {
        this._onChange = fn;
    }
    
    registerOnTouched() {}
    
    cambiarValor(value: boolean) {
        this.value = !value;
        this._onChange(this.value);
    }
} 
