import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { Queue } from '../../../_interfaces/queue';
import { Agente } from '../../../_interfaces/agente';
import { IDBResponse, EDBResponseType } from '../../../_interfaces/responses';
import { Observable } from 'rxjs/Observable';
import * as _ from 'lodash';

import { AgenteService } from '../../../_services/agente.service';
import { QueueService } from '../../../_services/queue.service';
import { SocketService, EComando } from '../../../_services/socket.service';
import { PollsService, IEncuentaFrm } from '../../../_services/polls.service';

interface IChanges extends SimpleChanges {
    queue: SimpleChange
}

@Component({
    selector: 'queue-edit',
    templateUrl: 'queue.component.html'
})
export class QueueComponent implements OnInit, OnChanges {
    
    @Input() readOnly: boolean;
    @Input() queue: Queue;
    @Output() queueEmitter = new EventEmitter<{queue: Queue, update: boolean}>();
    @Output() sts = new EventEmitter<string>();
    
    private audio_ann = new Audio();
    private audio_hold = new Audio();
    private agentes: Observable<Agente[]>;
    private modoArchivo: string;
    private polls$: Observable<IEncuentaFrm[]>
    
    constructor(private $agente: AgenteService, private $queue: QueueService, private $monitor: SocketService, private $poll: PollsService) { 
        this.agentes = this.$agente.getAgentes();
    }
    
    ngOnChanges(cambio: IChanges) {
        if (!!cambio.queue && !!cambio.queue.currentValue) {
            if (!!this.queue.nombre) {
                this.audio_hold.src = '/ivr/q_' + this.queue.nombre + '/hold.wav';
                this.audio_ann.src = '/ivr/' + this.queue.nombre + '_ann.wav';
            }
        }
    }

    ngOnInit() { 
        this.polls$ = this.$poll.getEncuestas();
        this.$queue.getUploaderEvent()
            // Transformarlo
            .filter(res => res.tipo === EDBResponseType.QUERY_OK)
            .flatMap(res => {
                let out: string;
                if (this.modoArchivo === 'HOLD') {
                    out = 'ivr/q_' + this.queue.nombre + '/hold.wav';
                } else {
                    out = 'ivr/' + this.queue.nombre + '_ann.wav';
                }

                return this.$monitor.enviarComando({
                    comando: EComando.AUDIO_FORMAT,
                    data: { in: res.data, out: out}
                })}
            )
            .subscribe(
                res => console.log(res),
                err => console.log(err)
            );
    }
    
    playAnn() {
        this.audio_ann.play();
    }
    
    stopAnn() {
        this.audio_ann.pause();
        this.audio_ann.currentTime = 0;
    }
    
    playHold() {
        this.audio_hold.play();
    }
    
    stopHold() {
        this.audio_hold.pause();
        this.audio_hold.currentTime = 0;
    }
    
    seleccionarAdjunto(tipo: string) {
        this.modoArchivo = tipo;
        $('#audio_attach').click();
    }
    
    fileChange(evento: any) {
        const file = <{ target: { files: FileList } }>evento;
        if (!!file.target.files && !!file.target.files[0]) {
            this.$queue.subirAudio();
        }
    }
    
    agregarAgente(idagente: string) {
        if (idagente && idagente !== '') {
            let agentesPresentes = _.cloneDeep(<{ idagente }[]>this.queue.agentes);
            // Buscarlo antes de agregarlo
            if (_.findIndex(agentesPresentes, ['idagente', idagente]) < 0) {
                agentesPresentes.push({
                    idagente: idagente
                });
                agentesPresentes = _.cloneDeep(_.sortBy(agentesPresentes, ['idagente']));
            }            
            this.queue.agentes = agentesPresentes;
        }
    }
    
    removerAgente(idagente: string) {
        const agentesPresentes = _.cloneDeep(<{ idagente }[]>this.queue.agentes);
        const index = _.findIndex(agentesPresentes, ['idagente', idagente]);
        if (index > -1) {
            agentesPresentes.splice(index, 1);
        }
        this.queue.agentes = agentesPresentes;
    }
    
    guardarCola(queue: Queue) {
        queue.nombre = queue.nombre.split(' ').join('_');
        this.queueEmitter.emit({
            queue: queue,
            update: this.readOnly
        });
    }
    
    eliminarCola(queue: Queue) {
        const eliminar = confirm(`Desea eliminar la cola ${queue.nombre}?`)
        if (eliminar === true) {
            this.queueEmitter.emit({
                queue: queue,
                update: undefined
            });
        }
    }
    
    reiniciarStats(queue: Queue) {
        this.sts.emit(queue.nombre);
    }
} 
