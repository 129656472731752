import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MomentModule } from 'ngx-moment';
import { ApiManagerService } from '../../_services/apimanager.service';
import { ApikeyComponent } from './apikey/apikey.component';
import { KeyTypePipe } from './pipes/key-type.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MomentModule
  ],
  declarations: [ApikeyComponent, KeyTypePipe],
  exports: [ApikeyComponent, KeyTypePipe],
  providers: [ApiManagerService],
})
export class ApiManagerModule { }
