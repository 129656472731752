import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { IAgentMonitor, EestadoAgentMonitor, IChannel } from '../../../_interfaces/monitor';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
    selector: 'agente-callcenter',
    template: `
    <div class="btn-group-vertical btn-block" role="group" aria-label="..." *ngIf="agente">
        <button type="button" class="btn btn-sm" [ngClass]="btn_color" (click)="opcionesAgente()">
            <div class="text-left">  
            <span style="font-size: 13px">
                <div class="col-xs-5" style="padding: 0">
                    <i class="fa" [ngClass]="icono" ></i> {{agente?.idagente}} 
                </div>
                <div class="col-xs-7 text-right" style="padding: 0">
                    {{(agente?.channel?.Duration || tiempo_pausa || 0) | toTime | date:'HH:mm:ss'}}
                </div>
            </span>
            </div>
        </button>
        <button type="button" class="btn btn-sm" [ngClass]="btn_color" style="padding-top: 2px; line-height: 20px" (click)="opcionesAgente()">
            <div class="text-left roboto">
                <span style="font-size: 12px">
                    <strong>{{agente?.nombre}}</strong><br>
                    <div class="col-xs-4 text-left" style="padding: 0">
                        <i class="fa fa-phone"></i> : {{(agentStats.colgadasUsuario + agentStats.colgadasAgente)}}                    
                    </div>
                    <div class="col-xs-4 text-center" style="padding: 0">
                        <i class="fa fa-check-square-o"></i> : {{agentStats.colgadasUsuario}}
                    </div>
                    <div class="col-xs-4 text-right" style="padding: 0">
                        <i class="fa fa-close"></i> : {{agentStats.colgadasAgente}}
                    </div><br>
                    <i class="fa fa-volume-control-phone"></i> : {{agente?.channel?.CallerID || "-"}}<br>
                </span>
            </div>
        </button>
    </div>
    `
})
export class AgenteComponent implements OnInit, OnChanges {
    @Input() agente: IAgentMonitor;
    @Input() queueName: string;
    @Input() calls: Object[];
    @Output() agenteEmitter = new EventEmitter <IAgentMonitor>();
    agentStats: { tiempo: number, recibidas: number, colgadasAgente: number, colgadasUsuario: number } = { tiempo: 0, recibidas: 0, colgadasAgente: 0, colgadasUsuario: 0 };
    
    private icono = '';
    private btn_color = '';
    private tiempo_pausa = 0;
    constructor() { }

    ngOnInit() { }
    
    ngOnChanges(cambios: IChange) {
        if (!!cambios.agente && !!cambios.agente.currentValue) {
            
            const agente = <IAgentMonitor>cambios.agente.currentValue;
            switch (EestadoAgentMonitor[agente.estado]) {
                case EestadoAgentMonitor.INBOUND_IDLE:
                    this.icono = 'fa-user';
                    this.btn_color = 'btn-success';
                    break;
                case EestadoAgentMonitor.OFFLINE:
                    this.icono = 'fa-user';
                    this.btn_color = 'btn-default';
                    break;
                case EestadoAgentMonitor.OUTBOUND_IDLE: {
                    this.icono = 'fa-user';
                    this.btn_color = 'btn-info';
                    
                    const timepoAgente = Number.parseInt(agente.fecha);
                    if (!!agente.last_call_date && !(agente.last_call_date instanceof Date)) {
                        agente.last_call_date = moment(agente.last_call_date).toDate();
                    } else if (!agente.last_call_date) {
                        agente.last_call_date = new Date();
                    }
                    this.tiempo_pausa = (timepoAgente - agente.last_call_date.getTime()) / 1000;
                    
                    } break;
                case EestadoAgentMonitor.INBOUND_BUSY:
                    this.icono = 'fa-user';
                    this.btn_color = 'btn-danger';
                    break;
                case EestadoAgentMonitor.INBOUND_BUSY_PAUSE:
                    this.icono = 'fa-clock-o';
                    this.btn_color = 'btn-danger';
                    break;
                case EestadoAgentMonitor.OUTBOUND_BUSY:
                    this.icono = 'fa-user';
                    this.btn_color = 'btn-primary';
                    break;
                case EestadoAgentMonitor.PAUSE:
                    this.icono = 'fa-clock-o';
                    this.btn_color = 'btn-warning';
                    
                    const _estado     = (!(agente.estado_fecha instanceof Date)) ? moment(agente.estado_fecha).toDate() : agente.estado_fecha;
                    const _lastCall   = (!!agente.last_call_date && !(agente.last_call_date instanceof Date)) ? moment(agente.last_call_date).toDate() : _estado;
                    
                    const currentTime = Number.parseInt(this.agente.fecha);
                    if (_lastCall.getTime() > _estado.getTime()) {  // Tomar el tiempo de 
                        this.tiempo_pausa = (currentTime - _lastCall.getTime()) / 1000;
                    } else { 
                        this.tiempo_pausa = (currentTime - _estado.getTime()) / 1000;
                    }
                        
                    break;
                default:
                    this.icono = 'fa-user';
                    this.btn_color = 'btn-default';
                    break;
            }
            
            let stats: {
                cola: string,
                completeAgente: number,
                completeTiempo: number,
                completeUsuario: number
            };
//            if (this.agente.modoAgente !== "OUTBOUND")
            stats = _.find(this.agente.stats, ['cola', this.queueName]);
//            else
//                stats = _.find(this.agente.stats, ['cola', 'outbound-queue'])
            if (stats) {
                this.agentStats = {
                    tiempo: stats.completeTiempo,
                    recibidas: Number(this.agente.complete), 
                    colgadasAgente: Number(stats.completeAgente), 
                    colgadasUsuario: Number(stats.completeUsuario)
                }
            }
        } 
        
        if (!!cambios.calls && !!cambios.calls.currentValue) {
            const calls = <IChannel[]>cambios.calls.currentValue;
            if (calls.length > 0) {
                
                if (this.agente.estado.indexOf('BUSY') > -1) {
                    
                    /* testing || Canal para el colgado de llamadas*/
                    const chanAG = _.find(calls, c => {
                        return c.Channel.indexOf('/' + this.agente.idagente + '@') > -1 && c.Application === 'AppQueue'
                    });
                    if (!!chanAG) {
                        this.agente.agentChannel = _.find(calls, c => {
                            return c.Application === 'Queue' && c.Bridged === chanAG.Bridged;
                        })
                    }
                    /* testing */
                                        
                    const chan_agente = _.find(calls, c => {
                        return c.Application.indexOf('AgentLogin') > -1 && c.Data === this.agente.idagente
                    });

                    if (chan_agente) { // Inbound Automatic
                        // Ubicar el canal con el request
                        this.agente.channel = _.find(calls, c => {
                            return c.Bridged === chan_agente.Bridged && c.Application.indexOf('AgentRequest') > -1;
                        });
                    } else { // Ringback
                        this.agente.channel = _.find(calls, c => {
                            return c.Context.indexOf('agentCallback') > -1 && c.Exten === this.agente.idagente && c.Stats === 'Up'
                        });
                    }
                } else if (this.agente.estado.indexOf('OUTBOUND') > -1) {
                    
                    this.agente.channel = _.find(calls, c => {
                        return  c.Channel.indexOf('/' + this.agente.logged_on + '-') > -1 ;
                    });
                    
                    if (!!this.agente.channel) {
                        this.agente.channel.CallerID = this.agente.channel.Exten;
                        
                        // Colores de estado
                        if (this.agente.channel.Stats.indexOf('Up') > -1) {
                            this.icono = 'fa-user';
                            this.btn_color = 'btn-primary';
                        }
                    }
                }
            }
        }
    }    
    
    opcionesAgente() {
        this.agenteEmitter.emit(this.agente);
    }
    
    enviarComando() {
        
    }
} 

interface IChange extends SimpleChanges {
    agente: SimpleChange,
    queueName: SimpleChange,
    calls: SimpleChange
}
