import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Agente } from '../../_interfaces/agente';
import { IDBResponse, EDBResponseType } from '../../_interfaces/responses';
import { SocketService, EComando } from '../../_services/socket.service';
import { AgenteService } from '../../_services/agente.service';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import * as _ from 'lodash';

@Component({
    selector: 'agente-edit',
    templateUrl: 'agente.component.html'
})
export class AgenteEdicionComponent implements OnInit, AfterViewInit {
    
    private readOnly: boolean;
    private errMsj: string;
    reload$ = new Subject<boolean>();
    agentes$: Observable<Agente[]>;
    nuevoAgente: Agente;
    
    constructor(private $monitor: SocketService, private $agente: AgenteService) { 
        this.agentes$ = this.reload$.asObservable()
            .switchMap(() => this.$agente.getAgentes())        
    }

    ngOnInit() {}
    
    ngAfterViewInit() {
        this.reload$.next(true);
    }
    
    editarAgente(agente: Agente) {
        this.nuevoAgente = _.cloneDeep(agente);
        this.readOnly = true;
        $('#nuevoAgente').modal()
    }
    
    crearNuevoAgente() {
        this.nuevoAgente = new Agente();
        this.readOnly = false;
        $('#nuevoAgente').modal();
    }
    
    guardarAgente(cambios: { agente: Agente, update: boolean }) {
        this.$agente.updateAgente(cambios)
            .flatMap(() => this.$monitor.enviarComando({
                comando: EComando.KERBERUS_FILE,
                data: {tipo: 'AGENTS'}
            }))
            .subscribe(res => {
                    this.errMsj = undefined;
                    console.log(res);
                    if (cambios.update === false || !cambios.update) {
                        this.nuevoAgente = undefined;
                    }                    
                    this.reload$.next(true);
                    $('#nuevoAgente').modal('hide')
                }, (err: IDBResponse) => {
                    if (err.tipo === EDBResponseType.QUERY_ERR) {
                        this.errMsj = 'Éste agente ya existe, trata con un nuevo id de agente.';
                    }
                }
            )
    }
} 
