import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { PlayerModule } from '../../_extra/player/player.module';
import { Ng2PaginationModule } from 'ng2-pagination';

import { CallLogComponent } from './call_log.component';
import { QueueLogComponent } from './queue_log.component';
import { AgentLogComponent } from './agent_log.component';
import { CallcenterStatsComponent } from './callcenter.component';

import { TimePipe } from './pipes/totime.pipe';
import { StringReplacePipe } from './pipes/stringreplace.pipe';
import { NumberFormatPipe } from './pipes/toNumber.pipe';
import { TimeFormatPipe } from './pipes/timeFormat.pipe';
import { FiltroPorTipoPipe, TiempoTotalPipe, TiempoPromedioPipe } from './pipes/stats.pipe'

import { ChartModule } from 'angular2-highcharts';
import { UIModule } from '../../_extra/ui/ui.module';
import * as highcharts from 'highcharts';

@NgModule({
    imports: [FormsModule, ReactiveFormsModule, BrowserModule, Ng2PaginationModule, PlayerModule, ChartModule.forRoot(highcharts), UIModule],
    declarations: [CallLogComponent, QueueLogComponent, AgentLogComponent, CallcenterStatsComponent, TimePipe, StringReplacePipe, NumberFormatPipe, TimeFormatPipe, 
        FiltroPorTipoPipe, TiempoTotalPipe, TiempoPromedioPipe
    ],
    exports: [CallLogComponent, QueueLogComponent, AgentLogComponent, CallcenterStatsComponent, TimePipe, StringReplacePipe, NumberFormatPipe, TimeFormatPipe, 
        FiltroPorTipoPipe, TiempoTotalPipe, TiempoPromedioPipe
    ]
})
export class ReportesModule {}
