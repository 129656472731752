import { Component, OnInit } from '@angular/core';
import { Http, Response } from '@angular/http';
import { HeaderService } from '../../_services/header.service';
import { QueueService } from '../../_services/queue.service';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import * as _ from 'lodash';

@Component({
    selector: 'callcenter-stats',
    templateUrl: 'callcenter.component.html'
})
export class CallcenterStatsComponent implements OnInit {
    llamadas: IQueue_timeline[];
    agentes: IQueue_Agents[];
    
    optionsQueueStats: Highcharts.Options;
    optionsTimeLine: any;
    _optionsTimeLine: any[] = [];
    optionsQueueAgents: any;
    _optionsQueueAgents: any[] = [];
    
    queue_stats: Highcharts.ChartObject;
    queue_timeline: Highcharts.ChartObject;
    queue_stats_date: Highcharts.ChartObject;
    
    private http_: Subscription;
    private colas: string[] = [];
    
    constructor(private $http: Http, private $header: HeaderService, private $queue: QueueService) {
        
        // <editor-fold defaultstate="collapsed" desc="queueStats">
        this.optionsQueueStats = {
            title : { text : 'Informe de llamadas x Cola' },
            subtitle: {
                text: 'Resumen de atención'
            },
            
            chart: {
                type: 'bar',
                width: 900,
                height: 120
            },
            colors: ['#75B5EC', '#90ED7D', '#BD0B0B'],
            xAxis: {
                categories: [],
                title: {
                    text: null
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Llamadas',
                    align: 'high'
                },
                labels: {
                    overflow: 'justify'
                }
            },
            series: [{
                name: 'Recibidas',
                data: []
            }, {
                name: 'Atendidas',
                data: []
            }, {
                name: 'Abandonadas',
                data: []
            }, {
                name: 'Tiempo Expirado',
                data: []
            }],
            credits: {
                enabled: false
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true
                    }
                }
            },
        };
        // </editor-fold>
        
        // <editor-fold defaultstate="collapsed" desc="optionsTimeLine">
        this.optionsTimeLine = {
            chart: {                
                width: '450',
                height: '300'
            },
            title: {
                text: 'Timeline Atención de Llamadas'
            },
            subtitle: {
                text: 'Recepción de llamadas por hora'
            },
            xAxis: {
                categories: [],
                crosshair: true
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Llamadas x Hora'
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key} Horas</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y}</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                name: 'Recibidas',
                data: []
            }, {
                name: 'Atendidas',
                data: []
            }],
            credits: {
                enabled: false
            }
        }
        // </editor-fold>
        
        // <editor-fold defaultstate="collapsed" desc="optionsQueueAgents">
        this.optionsQueueAgents = {
            title : { text : '' },
            subtitle: {
                text: 'Resumen de atención x Agente + Pausas'
            },
            chart: {
                type: 'column',
                width: '900',
                height: '300'
            },
            xAxis: {
                categories: [],
                title: {
                    text: null
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Llamadas',
                    align: 'high'
                },
                labels: {
                    overflow: 'justify'
                }
            },
            series: [{
                name: 'Atendidas',
                data: []
            }, {
                name: 'Pausas',
                data: []
            }],
            credits: {
                enabled: false
            },
            plotOptions: {
                column: {
                    dataLabels: {
                        enabled: true
                    }
                }
            },
        };
        // </editor-fold>
        
        $queue.getQueues().subscribe(
            colas => {
                colas.forEach(c => {
                    this.colas.push(c.nombre);
                })
            }
        )
    }

    ngOnInit() {}
    
    passChart(chart, tipo: string) {
        switch (tipo) {
            case 'stats': {
                this.queue_stats = chart;
            } break;
            case 'timeline': {
                this.queue_timeline = chart;
            } break;
            case 'byDate': {
                this.queue_stats_date = chart;
            }
        }        
    }
    
    ejecutarBusqueda(query: IQuery) {
        $('#modalWait_kerberus').modal();
        if (!!this.http_ && !this.http_.closed) {
            this.http_.unsubscribe();
        }

        this.http_ = this.ejecutarQueueStats(query)
            .flatMap(() => this.ejecutarTimeLine(query))
            .flatMap(() => this.ejecutarAgentStats(query))
            .subscribe(
                res => $('#modalWait_kerberus').modal('hide'),
                err => console.log(err)
            );
    }
    
    // <editor-fold defaultstate="collapsed" desc="ejecutarQueueStats(query: IQuery): Observable<boolean>">
    ejecutarQueueStats(query: IQuery): Observable<boolean> {
        return this.$http.post(this.$header.getAPIurl() + '/queue_stats', query, { headers: this.$header.getHeaders() })
            .map(res => res.json())
            .catch((err: Response) => Observable.throw(err.json()))
            .map((llamadas: IQueue_ststs) => {
                    this.queue_stats.xAxis[0].setCategories(llamadas.queues);
                    this.queue_stats.series[0].setData(llamadas.recibidas)
                    this.queue_stats.series[1].setData(llamadas.atendidas)
                    this.queue_stats.series[2].setData(llamadas.abandonos)
                    this.queue_stats.series[3].setData(llamadas.timeouts)
                    
                    this.queue_stats.setSize(900, ((55 * llamadas.queues.length) + 170), true);
                    this.queue_stats.redraw(true);
                    return true;
                }
            );
    }
    // </editor-fold>

    // <editor-fold defaultstate="collapsed" desc="ejecutarTimeLine(query: IQuery): Observable<boolean>">
    ejecutarTimeLine(query: IQuery): Observable<boolean> {
        this._optionsTimeLine = [];
        return this.$http.post(this.$header.getAPIurl() + '/queue_stats_timeline', query, { headers: this.$header.getHeaders() })
            .map(res => res.json())
            .catch((err: Response) => Observable.throw(err.json()))
            .map((llamadas: IQueue_timeline[]) => {
                    this.llamadas = llamadas;
                    this.llamadas.forEach(res => {
                        const opt = _.cloneDeep(this.optionsTimeLine);
                        opt.title.text = 'TimeLine de ' + res.queuename;
                        opt.xAxis = {
                            categories: res.fecha,
                            crosshair: true
                        }
                        opt.series = [{
                            name: 'Recibidas',
                            data: res.recibidas
                        }, {
                            name: 'Atendidas',
                            data: res.atendidas
                        }];
                        this._optionsTimeLine.push(opt);
                    });                  
                    return true;
                }
            );
    }
    // </editor-fold>

    // <editor-fold defaultstate="collapsed" desc="ejecutarAgentStats(query: IQuery): Observable<boolean>">
    ejecutarAgentStats(query: IQuery): Observable<boolean> {
        this._optionsQueueAgents = [];
        return this.$http.post(this.$header.getAPIurl() + '/queue_stats_agents', query, { headers: this.$header.getHeaders() })
            .map(res => res.json())
            .catch((err: Response) => Observable.throw(err.json()))
            .map((llamadas: IQueue_Agents[]) => {
                    this.agentes = llamadas;
                    this.agentes.forEach(res => {
                        const opt = _.cloneDeep(this.optionsQueueAgents);
                        opt.title.text = 'Rendimiento de Agentes en ' + res.queues;
                        opt.xAxis = {
                            categories: res.agentes,
                            title: {
                                text: null
                            }
                        }
                        opt.series = [{
                            name: 'Atentidas',
                            data: res.atendidas
                        }, {
                            name: 'Pausas',
                            data: res.pausas
                        }, {
                            name: 'Transferencias',
                            data: res.xfers
                        }];
                        this._optionsQueueAgents.push(opt);
                    });                  
                    return true;
                }
            );
    }
    // </editor-fold>
} 

export interface IQuery {
    fecha?: {
        inicio: string, 
        fin: string
    },
    cola?: string,
}

export interface IQueue_ststs { 
    queues: string[], 
    recibidas: number[], 
    atendidas: number[], 
    abandonos: number[],
    timeouts: number[] 
}

export interface IQueue_timeline { 
    queuename: string, 
    fecha: string[], 
    recibidas: number[], 
    atendidas: number[]
}

export interface IQueue_Agents { 
    queues: string, 
    agentes: string[],
    atendidas: number[], 
    xfers: number[],
    pausas: number[] 
};
