import { Pipe, PipeTransform } from '@angular/core';
import { ICall_logQuery, EQueryOperacion, ECallDisposicion, EQueueDisposicion, ICallLogQueryResult, IQueueLogResult } from '../../../_interfaces/informes';
import * as _ from 'lodash';

@Pipe({name: 'filtroPorTipo'})
export class FiltroPorTipoPipe implements PipeTransform {

    transform(value: ICallLogQueryResult[] | IQueueLogResult[], tipo: ECallDisposicion | EQueueDisposicion, call: boolean) {
        let _value: ICallLogQueryResult[] | IQueueLogResult[] = [];
        if (value !== undefined) {
            try {
                if (call === true) {
                    const queryResult: ICallLogQueryResult[] = <ICallLogQueryResult[]>value;
                    _value = queryResult.filter(r => r.disposition === tipo);
                } else if (call === false) {
                    const queryResult: IQueueLogResult[] = <IQueueLogResult[]>value;
                    if (tipo.toString().indexOf('%') > -1) {
                        _value = _.filter(queryResult, v  => {
                            return v.event.indexOf(tipo.toString().replace('%', '')) > -1;
                        });
                    } else {
                        _value = _.filter(queryResult, ['event', tipo])
                    }
                }
            } catch (e) {}
        }
        return _value;
    }
}

@Pipe({name: 'tTotal'})
export class TiempoTotalPipe implements PipeTransform {
    
    transform(value: ICallLogQueryResult[] | IQueueLogResult[], call: boolean) {
        let time = 0;
        if (value !== undefined) {
            try {
                if (call === true) {
                    const queryResult: ICallLogQueryResult[] = <ICallLogQueryResult[]>value;
                    queryResult.forEach(llamada => {
                        time += llamada.billsec;
                    });
                } else if (call === false) {
                    
                    const queryResult: IQueueLogResult[] = <IQueueLogResult[]>value;
                    queryResult.forEach(llamada => {
                        if (llamada.event.indexOf('COMPLETE') > -1 || 
                            llamada.event.indexOf('ANSWERED') > -1 ||
                            llamada.event.indexOf('TRANSFER') > -1
                            ) {
                            
                            time += Number.parseInt(llamada.data2);
                        }                            
                    });
                    
                }
            } catch (e) {}
        }
        return time;
    }
}

@Pipe({name: 'tPromedio'})
export class TiempoPromedioPipe implements PipeTransform {

    transform(value: ICallLogQueryResult[] | IQueueLogResult[], call: boolean) {
        let time = 0;
        if (value !== undefined && value.length > 0) {
            try {
                if (call === true) {
                     const queryResult: ICallLogQueryResult[] = <ICallLogQueryResult[]>value;
                    queryResult.forEach(llamada => {
                        time += llamada.billsec;
                    });
                    time = time / value.length;
                } else if (call === false) {
                    let complete = 0;
                    const queryResult: IQueueLogResult[] = <IQueueLogResult[]>value;
                    queryResult.forEach(llamada => {
                        if (llamada.event.indexOf('COMPLETE') > -1 || 
                            llamada.event.indexOf('ANSWERED') > -1 ||
                            llamada.event.indexOf('TRANSFER') > -1
                            ) {
                            time += Number.parseInt(llamada.data2);
                            complete++;
                        }
                    });
                    time = time / complete;
                }
                
            } catch (e) {}
        }
        return time;
    }
}
