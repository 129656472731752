import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Secuencia, EOpcion } from '../../../_interfaces/ivr';
import { Peer } from '../../../_interfaces/peer';
import { Grupo } from '../../../_interfaces/grupo';
import { Queue } from '../../../_interfaces/queue';
import { IVR } from '../../../_interfaces/ivr';
import { Troncal } from '../../../_interfaces/troncal';
import * as _ from 'lodash';
import { Observable } from 'rxjs/Observable';
import { EDBResponseType} from '../../../_interfaces/responses';

import { PeerService } from '../../../_services/peer.service';
import { QueueService } from '../../../_services/queue.service';
import { GruposService } from '../../../_services/grupo.service';
import { IVRService } from '../../../_services/ivr.service';
import { TroncalService } from '../../../_services/troncal.service';

interface IOpcionesIVR {
    opcion: EOpcion,
    operacion: string
}

@Component({
    selector: 'secuencia-tag',
    templateUrl: 'secuencia.component.html',
    styles: [`.li-ivr { padding-top: 2px; padding-bottom: 2px; font-size: 13px }`]
})
export class SecuenciaComponent implements OnInit {
    @Input() did: string;
    @Input() readOnly: boolean;
    @Input() secuencia: Secuencia[];
    @Output() audio = new EventEmitter<string>();
    @Output() upload = new EventEmitter <boolean>();
    
    private audioSeleccionado: string;
    private secuenciaEdicion: Secuencia;    // Secuencia inicializada
    private secArray: Secuencia[];          // Secuencia actual donde se empuja el nuevo valor
    
    private audio_ivr = new Audio();
    private ops = <IOpcionesIVR[]>[];
    private o = EOpcion;
    
    // Variable para inicializar el formulario de creación de 
    private secuenciaForm = {index : '', opcion: ''};
    
    private peer$: Observable<Peer[]>;
    private grupo$: Observable<Grupo[]>;
    private queue$: Observable<Queue[]>;
    private ivr$: Observable<IVR[]>;
    private troncal$: Observable<any[]>;
    
    constructor(private $peer: PeerService, private $grupo: GruposService, private $queue: QueueService, private $ivr: IVRService, private $troncal: TroncalService) {
        this.peer$      = this.$peer.getPeers();
        this.grupo$     = this.$grupo.getGrupos();
        this.queue$     = this.$queue.getQueues();
        this.ivr$       = this.$ivr.getDids();
        this.troncal$   = this.$troncal.getTroncalesPorContexto('interx');
    }

    ngOnInit() { 
        this.ops = [
            {
                opcion: EOpcion.EXTENSION,
                operacion: 'Gosub(marcacionInterna,s,1(%g,%d))' // 'Macro(marcacionInterna,%g,%d)'
            }, 
            {
                opcion: EOpcion.GRUPO,
                operacion: 'Gosub(marcacionGrupo,s,1(${G_%g},%d))'
            },
            {
                opcion: EOpcion.QUEUE,
                operacion: 'Gosub(Queues,${EXTEN},1(%g))'    // %g = queuename,prio
            },
            {
                opcion: EOpcion.SUBIVR,
                operacion: 'Goto(%g_%d,%d,1)'        // s, Goto(context, did, 1)
            },
            {
                opcion: EOpcion.BACKGROUND,
                operacion: 'Background(ivr/%d_%g)'
            },
            {
                opcion: EOpcion.PLAYBACK,
                operacion: 'Playback(ivr/%d_%g)'
            },
            {
                opcion: EOpcion.DID,
                operacion: 'Goto(Entrante,%g,1)'
            },
            {
                opcion: EOpcion.DATABASE,
                operacion: '-'
            },
            {
                opcion: EOpcion.INTERX,
                operacion: 'Gosub(interx,s,1(%g))'       // %g Troncal, DID
            },
            {
                opcion: EOpcion.MARCACION_EXT,
                operacion: 'Gosub(kerberus,%g,1(FROM_IVR))'
            },
            {
                opcion: EOpcion.COLGAR,
                operacion: 'Hangup()'
            }
        ];
        
        this.ops = _.sortBy(this.ops, ['opcion']);        
        
        this.$ivr.getUploadEvents()
            .filter(e => e.tipo === EDBResponseType.OK) // Solo si se seleccionó
            .subscribe(audio => this.audioSeleccionado = <string>audio.data);
    }
    
    play(src: string) {
        try {
            this.audio_ivr.src = 'ivr/' + this.did + '_' + src + '.wav';
            console.log(this.audio_ivr.src);
            this.audio_ivr.play();
        } catch (e) {
            console.log()
        }
    }
    
    stop() {
        this.audio_ivr.pause();
        this.audio_ivr.currentTime = 0;
    }
    
    seleccionarAudio(nombre: string) {    
        this.audio.emit(nombre.split(' ').join('_'));
    }
    
    /**
     * Funcion para eliminar un objeto del IVR
     * @param Secuencia[] actual
     * @param Secuencia a eliminar
     */
    eliminarSecuencia(secuencia: Secuencia[], toDel: Secuencia) {    
        
        // Encontrar el objeto exacto a eliminar
        const index = _.findIndex(secuencia, s => {
            return (s.did === toDel.did && s.contexto === toDel.contexto && s.index === toDel.index && s.opcion_ivr === toDel.opcion_ivr && s.valor === toDel.valor)
        });
        
        if (index > -1) {
            let clone = false;
            if (this.secuencia === secuencia) { // Si es base de raiz
                clone = true;
            }
            secuencia.splice(index, 1);
            
            // Reordenar indexes
            const _seq = _.filter(secuencia, ['contexto', toDel.contexto]);
            for (let i = 0; i < _seq.length; i++) {
                if (_seq[i].opcion_ivr === '') {
                    _seq[i].index = (i + 1);
                }
            }
            
            if (!!clone) {
                this.secuencia = _.cloneDeep(secuencia);
            }
        }
    }
    // </editor-fold>
    
    agregarSecuencia(secuencia: Secuencia[], contexto: string) {
        this.audioSeleccionado = '';
        this.secuenciaEdicion = new Secuencia();
        this.secuenciaForm = {
            index: '',
            opcion: ''
        };
                
        // <editor-fold defaultstate="collapsed" desc="Secuencia SUBMENU">
        if (!!secuencia) {
            this.secuenciaEdicion = {
                did: this.did,
                contexto: contexto,
                index: _.filter(secuencia, sec => {
                        return sec.contexto === contexto && sec.opcion_ivr === '';
                    }).length + 1,
                opcion: '',
                valor: '',
                operacion: '',
                opcion_ivr: ''
            }
            this.secArray = secuencia;
            // secuencia.push(this.secuenciaEdicion);
        } else { // Si no existe, es raiz de IVR
            this.secuenciaEdicion = {
                did: this.did,
                contexto: contexto,
                index: _.filter(this.secuencia, sec => {
                        return sec.contexto === contexto && sec.opcion_ivr === '';
                    }).length + 1,
                opcion: '',
                valor: '',
                operacion: '',
                opcion_ivr: ''
            }
            // Sanitize pure filter
            this.secArray = this.secuencia;
        }
        
        $('#modalOpcionIVR').modal();
    }
    
    generarSecuencia(_secForm: Secuencia) {
        const secForm = _.cloneDeep(_secForm);
        secForm.opcion = EOpcion[secForm.opcion];
        // console.log(secForm);
        // Iniciar la carga del archivo
        if (EOpcion[secForm.opcion as string] === EOpcion.BACKGROUND || EOpcion[secForm.opcion as string] === EOpcion.PLAYBACK) {
            this.upload.emit(true);
        }
        
        this.secuenciaEdicion.index         = (secForm.index || this.secuenciaEdicion.index);
        this.secuenciaEdicion.opcion        = secForm.opcion;
        this.secuenciaEdicion.valor         = secForm.valor.split(' ').join('_');
        this.secuenciaEdicion.operacion     = _.find(this.ops, ['opcion', EOpcion[secForm.opcion]]).operacion;
        this.secuenciaEdicion.opcion_ivr    = secForm.opcion_ivr;
        this.secuenciaEdicion.subMenu       = secForm.subMenu;
        
        if (this.secArray === this.secuencia) {
            this.secuencia.push(this.secuenciaEdicion);
            this.secuencia = _.cloneDeep(this.secuencia);
        } else {
            this.secArray.push(this.secuenciaEdicion);
        }
            
        $('#modalOpcionIVR').modal('hide');
    }
} 
