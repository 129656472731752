export interface IDialerCampana {
    id?: string;
    fecha?: string;
    nombre: string;
    destino: string;
    proveedor: string;
    tasa: number;
    estado?: boolean;
}

export interface IDialerNumero {
    idnumero?: number;
    fecha_mod?: Date;
    idcampana: string;
    numero: number;
    variable: Object;
    estado: EEstadoNumeroDialer;
}

export enum EEstadoNumeroDialer {
    EN_ESPERA = 'EN_ESPERA',
    EN_CURSO = 'EN_CURSO',
    EN_ESCUCHA = 'EN_ESCUCHA',
    EN_LLAMADA = 'EN_LLAMADA',
    FINALIZADO = 'FINALIZADO',
    FALLIDO = 'FALLIDO'
}
