import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TroncalService } from '../../_services/troncal.service';
import { SocketService } from '../../_services/socket.service';
import { Troncal } from '../../_interfaces/troncal';
import { Queue } from '../../_interfaces/queue';
import { IDialerNumero, IDialerCampana, EEstadoNumeroDialer } from '../../_interfaces/dialer';

import { QueueService } from '../../_services/queue.service';
import { DialerService } from '../../_services/dialer.service';
import { ObjectID } from '../../_services/_objectID';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { PaginationInstance } from 'ng2-pagination';

@Component({
    selector: 'dialer-app',
    templateUrl: './dialer.component.html'
})

export class DialerComponent implements OnInit, AfterViewInit, OnDestroy {

    private reloadCampana$ = new Subject<boolean>();
    private eCall = EEstadoNumeroDialer;
    private numeros = '';
    private troncales$: Observable<Troncal[]>;
    private queues$: Observable<Queue[]>;
    private campanas$: Observable<{campana: IDialerCampana, numeros: IDialerNumero[]}[]>;
    private numeros$ = new Subject<IDialerNumero[]>();
    private campanaID$ = new Subject<string>();
    private dialerMonitorSub: Subscription;

    private frm: FormGroup;

    public config: PaginationInstance = {
        id: 'custom',
        itemsPerPage: 20,
        currentPage: 1
    };

    constructor (
        private $troncal: TroncalService, private $queue: QueueService, private $dialer: DialerService,
        private $fb: FormBuilder, private $monitor: SocketService
    ) { }

    ngOnInit() { 
        this.initForm();
        this.troncales$ = this.$troncal.getTroncales();
        this.queues$ = this.$queue.getQueues();
        /*this.campanas$ = this.reloadCampana$.flatMap( () => this.$dialer.getCampanas$() );
        this.dialerMonitorSub = Observable.combineLatest(
                this.campanaID$,
                this.$monitor.getDialer() 
            )
            .switchMap(latest => {
                const [campanaID, enCurso] = latest;
                return Observable.from(enCurso)
                    .filter(numero => numero.idcampana === campanaID)
                    .reduce((acc, curr) => [...acc, curr], [])
                })
            .filter(enCurso => enCurso.length > 0)
            .do(enCurso => this.numeros$.next(enCurso))
            .subscribe();*/
    }

    initForm() {
        this.frm = this.$fb.group({
            id: '',
            nombre: ['', Validators.required],
            proveedor: ['', Validators.required],
            destino: ['', Validators.required],
            tasa: ['', Validators.required],
            offset: ['', [Validators.required, Validators.min(0)] ],
            retrytime: ['', [Validators.required, Validators.min(0)] ],
            intentos: ['', [ Validators.required, Validators.min(0)] ]
        })

        this.frm.patchValue({
            intentos: 0,
            offset: 0,
            retrytime: 15
        })
    }
    
    ngAfterViewInit() {
        this.reloadCampana$.next(true);
    }

    generarNumeros(numero: string, cant: string): void {
        const numeros: number[] = [];
        // tslint:disable-next-line:radix
        for (let i = 0; i < parseInt(cant); i++) {
            // tslint:disable-next-line:radix
            numeros.push(parseInt(numero) + i);
        }
        this.numeros = numeros.join('\n');
    }

    generarNumerosDesdeLista(lista: string) {
        this.numeros = lista;
        $('#modalNumeros').modal('hide');
    }

    cargarCampana(data: {campana: IDialerCampana, numeros: IDialerNumero[]}) {
        this.numeros = '';
        this.frm.patchValue(data.campana)

        const numerosTemp = []
        /*data.numeros.forEach(numero => {
            numerosTemp.push(numero.numero + ',' + 
                (!!numero.variable ? numero.variable.split('$').join(',') : '')
            )
        });*/
        this.numeros = numerosTemp.join('\n');
    }

    verNumerosCampana(numeros: IDialerNumero[]) {        
        if (numeros.length > 0) {
            this.campanaID$.next(numeros[0].idcampana);
            this.numeros$.next(numeros);
        }
    }

    guardarCampana(campana: IDialerCampana, numerosStr: string) {
        const numeros: IDialerNumero[] = [];
        let action$: Observable<any>;

        if (campana.id === null || campana.id === '') {
            campana.id = ObjectID();
            numerosStr.split('\n').forEach(numero => {
                const _numero = numero.split(',')
                if (_numero[0] !== '') {
                    numeros.push({
                        // tslint:disable-next-line:radix
                        numero: parseInt(_numero.pop()),
                        idcampana: campana.id,
                        estado: EEstadoNumeroDialer.EN_ESPERA,
                        variable: (_numero.length > 0 ? _numero.join('$') : '')
                    })
                }
            })
            action$ = this.$dialer.guardarNuevaCampana$(campana, numeros)
        } else {
            action$ = this.$dialer.guardarCampana$(campana)
        }

        $('#modalWait_kerberus').modal({
            backdrop: 'static',
            keyboard: false
        })
        
        action$
            .subscribe( 
            res => {
                    $('#modalWait_kerberus').modal('hide')
                    this.reloadCampana$.next(true);
                    console.log(res)
                }, 
                err => {
                    $('#modalWait_kerberus').modal('hide')
                    console.error(err)
                }
            )
    }

    setEstadoCampana(campana: IDialerCampana, estado: number) {
        // cambiar el estado de la campaña y de los números de ésta campaña
        $('#modalWait_kerberus').modal({
            backdrop: 'static',
            keyboard: false
        })

        Observable.of(Object.assign({}, campana, {estado: estado === 1}))
            .do(c => console.log(c))
            .flatMap(campain => this.$dialer.guardarCampana$(campain))
            .subscribe(
                res => {
                    $('#modalWait_kerberus').modal('hide')
                    this.reloadCampana$.next(true);
                    console.log(res)
                }, 
                err => {
                    $('#modalWait_kerberus').modal('hide')
                    console.error(err)
                }
            )
    }
            
    eliminarCampana(id: string) {
        $('#modalWait_kerberus').modal({
            backdrop: 'static',
            keyboard: false
        });
        this.$dialer.eliminarCampana(id)
        .subscribe( 
            res => {
                $('#modalWait_kerberus').modal('hide')
                this.reloadCampana$.next(true);
                console.log(res)
            }, 
            err => {
                $('#modalWait_kerberus').modal('hide')
                console.error(err)
            }
        )
    }

    cargarNumeros(numeros: string) {
        $('#modalNumeros').modal({
            backdrop: 'static',
            keyboard: false
        })
    }

    ejecutarSalto(numeros: IDialerNumero[], cantidad: string) {
        $('#modalWait_kerberus').modal({
            backdrop: 'static',
            keyboard: false
        })

        if (Array.isArray(numeros) && numeros.length > 0) {
        // tslint:disable-next-line:radix
        this.$dialer.ejecutarSalto(numeros[0].idcampana, parseInt(cantidad))
            .subscribe( 
                res => {
                    $('#modalWait_kerberus').modal('hide')
                    this.reloadCampana$.next(true);
                    console.log(res)
                }, 
                err => {
                    $('#modalWait_kerberus').modal('hide')
                    console.error(err)
                }
            )
        }
    }

    actualizarEstadoNumeros(estado: EEstadoNumeroDialer, idcampana: string) {
        $('#modalWait_kerberus').modal({
            backdrop: 'static',
            keyboard: false
        });
        this.$dialer.actualizarNumerosCampana(idcampana, estado)
            .subscribe( 
                res => {
                    $('#modalWait_kerberus').modal('hide')
                    this.reloadCampana$.next(true);
                    console.log(res)
                }, 
                err => {
                    $('#modalWait_kerberus').modal('hide')
                    console.error(err)
                }
            )
    }

    ngOnDestroy() {
        if (!this.dialerMonitorSub.closed) {
            this.dialerMonitorSub.unsubscribe();
        }
    }
}
