import { Component, OnInit } from '@angular/core';
import { Http, Response } from '@angular/http';
import { IQueue_logQuery, IQueueLogResult, EQueryOperacion, ECallDisposicion, EModoAgenteQuery, EQueueDisposicion, EQueryType } from '../../_interfaces/informes';
import { PaginationInstance } from 'ng2-pagination';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subscription } from 'rxjs/Subscription';

import { HeaderService } from '../../_services/header.service';
import { SocketService, EComando } from '../../_services/socket.service';
import { QueueService } from '../../_services/queue.service';
import { from } from 'rxjs/observable/from';
import { map, toArray, tap, finalize } from 'rxjs/operators';
import { FileHandlerService } from '../../_services/file.service';

@Component({
    selector: 'queue_log',
    templateUrl: 'queue_log.component.html'
})
export class QueueLogComponent implements OnInit {
    
    llamadas: IQueueLogResult[];
    private llamada: IQueueLogResult;
    private http_: Subscription;
    
    private campo = '';
    private _q = EQueryType;
    private m = EModoAgenteQuery;
    private o = EQueryOperacion;
    private d = ECallDisposicion;
    private q = EQueueDisposicion;
    private autosearch_colas: string[] = [];
    
    private queryParams = {
        fuente: 0, 
        destino: 0,
        modoAgente: 1
    };
    
    public config: PaginationInstance = {
        id: 'custom',
        itemsPerPage: 12,
        currentPage: 1
    };
    
    constructor(private $http: Http, private $header: HeaderService, private $monitor: SocketService, private $queue: QueueService, private $file: FileHandlerService) { }

    ngOnInit() { 
        this.$queue.getQueues().subscribe(
            colas => {
                colas.forEach(c => {
                    this.autosearch_colas.push(c.nombre);
                })
            }
        )
    }
    
    ejecutarBusqueda(_query: IQueue_logQuery, qType: EQueryType) {
        $('#modalWait_kerberus').modal();
        const query = _.cloneDeep(_query);
        query.queryType = qType;
        
        if (query.fuente.valor === '') {
            delete query.fuente;
        }
        if (query.destino.valor === '') {
            delete query.destino;
        }
            
        Observable.of(query)
            .switchMap(body => this.$http.post(this.$header.getAPIurl() + '/queue_log', body, { headers: this.$header.getHeaders() }))
            .map(res => res.json())
            .finally(() => ($('#modalWait_kerberus').modal('hide')))
            .subscribe(
                resultados => {
                    if (qType === EQueryType.LIST) {
                        this.campo = '';
                        resultados = _.sortBy(<IQueueLogResult[]>resultados, ['calldate']).reverse();
                        this.llamadas = resultados;
                    } else {
                        const _res = <{ filename: string }>resultados;
                        window.open(_res.filename);
                    }
                },
                err => console.log(err)
            )
    }
    
    sortBy(campo: string) {
        if (this.campo !== campo) { 
            this.llamadas = _.cloneDeep(_.sortBy(this.llamadas, [campo]));
        } else {
            this.llamadas = _.cloneDeep(this.llamadas.reverse())
        }
        this.campo = campo;
    }
    
    escuchar(llamada: IQueueLogResult) {        
        this.llamada = llamada;
        const audioSrc = llamada.userfield.split(';');
        let idLlamada = '';
        
        if (audioSrc.length > 0) {
            while (true) {
                idLlamada = audioSrc.shift();
                if ((!!idLlamada && idLlamada.length > 0 ) || audioSrc.length === 0) {
                    break;
                }
            }
        } else {
            idLlamada = llamada.userfield;
        }
        
        if (!!this.http_ && !this.http_.closed) {
            this.http_.unsubscribe();
        }
        
        $('#modalWait_kerberus').modal({
            backdrop: 'static',
            keyboard: false
        });
        this.http_ = this.$http.get(this.$header.getAPIurl() + '/audio/' + idLlamada, { headers: this.$header.getHeaders() })
            .map(res => res.json())
            .flatMap((src: {src: string}) => {
                if (src.src.indexOf('.gsm') > -1) {
                    return this.$monitor.enviarComando({
                        comando: EComando.AUDIO_XFORM,
                        data: {
                            in: src.src,
                            out: src.src.replace('.gsm', '.mp3')
                        }
                    })
                    .map(() => src.src.replace('.gsm', '.mp3'));
                } else {
                    const obs = new ReplaySubject<string>(1);
                    obs.next(src.src);
                    return obs.asObservable();
                }
            })
            .catch((err: Response) => Observable.throw(err.json()))
            .subscribe(
                (src: string) => {       
                    $('#modalWait_kerberus').modal('hide');
                    this.llamada.audio = src;
                    setTimeout(() => {
                        $('#modalAudioQueue').modal({
                            backdrop: 'static',
                            keyboard: false
                        });
                    }, 1000);
                },
                err => {
                    $('#modalWait_kerberus').modal('hide');
                    console.log(err)
                }
            )
    }

    descargarQueuelog(llamadas: IQueueLogResult[]) {
        
        const auxFecha = moment().set({'hours': 0, 'minutes': 0, 'seconds' : 0});
        $('#modalWait_kerberus').modal();
        from(llamadas).pipe(
            map(call => {

                let tLlamada: Date,
                    hLlamada: Date;
                    
                if (call.event.indexOf('COMPLE') > -1 || call.event.indexOf('ANSW') > -1 || call.event.indexOf('FAILED') > -1 || call.event.indexOf('TRANSFER') > -1) {
                    tLlamada = _.cloneDeep(auxFecha).add(call.data2, 'seconds').toDate() // new Date(Number.parseInt(l.data2) * 1000);
                    hLlamada = _.cloneDeep(auxFecha).add(call.data1, 'seconds').toDate()
                } else if (call.event.indexOf('ABAND') > -1) {
                    tLlamada = _.cloneDeep(auxFecha).toDate()
                    hLlamada = _.cloneDeep(auxFecha).add(call.data3, 'seconds').toDate()
                } else {
                    tLlamada = _.cloneDeep(auxFecha).toDate()
                    hLlamada = _.cloneDeep(auxFecha).toDate()
                }

                return {
                    CALLID: call.callid,
                    FECHA: moment(call.calldate).format('YYYY-MM-DD'),
                    HORA: moment(call.calldate).format('HH:mm:ss'),
                    FUENTE: call.src,
                    DESTINO: call.dst,
                    COLA: call.queuename,
                    'ID AGENTE': call.agent,
                    AGENTE: call.agenteNombre,
                    EVENTO: call.event,
                    DURACION: moment(tLlamada.getTime()).format('HH:mm:ss'),
                    'TIEMPO DE ESPERA': moment(hLlamada.getTime()).format('HH:mm:ss'),
                    'POSICION INICIAL': call.event.indexOf('COMPLE') > -1 ? call.data3 : call.data2
                }
            }),
            toArray(),
            tap(data => this.$file.exportCSVFile(Object.keys(data[0]), data, 'CallcenterLog_' + new Date().getTime())),
            finalize(() => $('#modalWait_kerberus').modal('hide'))
        ).subscribe()
    }
    
    csv() {
        $('#modalWait_kerberus').modal();
        
        const toCSV: {
            Fecha: string,
            Hora: string,
            Fuente: string, 
            Cola: string,
            Agente: string,
            Nombre_Agente: string,
            Evento: string,
            Tiempo_Llamada: string,
            Tiempo_Espera: string, 
            Posicion_Inicial: string,
            Destino: string
        }[] = [];
        
        const _fecha = moment().set('hours', 0).set('minutes', 0).set('seconds', 0);
        
        this.llamadas.forEach(l => {
            
            let tLlamada: Date;
            let hLlamada: Date;
            if (l.event.indexOf('COMPLE') > -1 || l.event.indexOf('ANSW') > -1 || l.event.indexOf('FAILED') > -1) {
                tLlamada = _.cloneDeep(_fecha).add(l.data2, 'seconds').toDate() // new Date(Number.parseInt(l.data2) * 1000);
                hLlamada = _.cloneDeep(_fecha).add(l.data1, 'seconds').toDate()
            } else if (l.event.indexOf('ABAND') > -1) {
                tLlamada = _.cloneDeep(_fecha).toDate()
                hLlamada = _.cloneDeep(_fecha).add(l.data3, 'seconds').toDate()
            } else {
                tLlamada = _.cloneDeep(_fecha).toDate()
                hLlamada = _.cloneDeep(_fecha).toDate()
            }
            
            toCSV.push({
                Fecha: moment(l.calldate).format('YYYY-MM-DD'),
                Hora: moment(l.calldate).format('HH:mm:ss'),
                Fuente: l.src,
                Cola: l.queuename,
                Agente: l.agent,
                Nombre_Agente: l.agenteNombre,
                Evento: l.event, 
                Tiempo_Llamada: moment(tLlamada.getTime()).format('HH:mm:ss'),
                Tiempo_Espera: moment(hLlamada.getTime()).format('HH:mm:ss'),
                Posicion_Inicial: l.event.indexOf('COMPLE') > -1 ? l.data3 : l.data2,
                Destino: l.dst
            });
        });
        
        if (!!this.http_ && !this.http_.closed) {
            this.http_.unsubscribe();
        }
        
        this.http_ = this.$http.post(this.$header.getAPIurl() + '/csv', toCSV, { headers: this.$header.getHeaders() })
            .map(res => res.json())
            .catch((err: Response) => Observable.throw(err.json()))
            .subscribe(
                (res: {file: string}) => {
                    $('#modalWait_kerberus').modal('hide');
                    window.open(res.file);
                },
                err => console.log(err)
            )
    }
} 
