import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { HeaderService } from './header.service';

import { Observable } from 'rxjs/Observable';
import { IDBResponse } from '../_interfaces/responses';
import { Troncal } from '../_interfaces/troncal';
import * as _ from 'lodash';

@Injectable()
export class TroncalService {
    constructor(private $http: Http, private $header: HeaderService) {}
    
    getTroncales(): Observable<Troncal[]> {
        return this.$http.get(this.$header.getAPIurl() + '/troncal', { headers: this.$header.getHeaders() })
            .map(res => res.json())
            .catch((err: Response) => Observable.throw(err.json()))
    }
    
    getTroncalesPorContexto(contexto: string): Observable<Troncal[]> {
        return this.$http.get(this.$header.getAPIurl() + '/troncal', { headers: this.$header.getHeaders() })
            .map(res => res.json())
            .map((troncales: Troncal[]) => {
                return _.filter(troncales, troncal => {
                    return _.filter(troncal.prefijos, ['contexto', contexto]).length > 0
                })
            })
            .catch((err: Response) => Observable.throw(err.json()))
    }
    
    actualizarTroncal(data: { troncal: Troncal, update: boolean }): Observable<IDBResponse> {
        let http$: Observable<Response>;
        if (data.update === undefined) {
            http$ = this.$http.delete(this.$header.getAPIurl() + '/troncal/' + data.troncal.nombre, { headers: this.$header.getHeaders() })
        } else if (data.update) {
            http$ = this.$http.post(this.$header.getAPIurl() + '/troncal', data.troncal, { headers: this.$header.getHeaders() })
        } else {
            http$ = this.$http.put(this.$header.getAPIurl() + '/troncal', data.troncal, { headers: this.$header.getHeaders() })
        }
        return http$.map(res => res.json())
            .catch((err: Response) => Observable.throw(err.json()));
    }
}

