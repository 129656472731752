import { Component, OnInit, Input } from '@angular/core';
import { IPeerMonitor } from '../../../_interfaces/monitor';

@Component({
    selector: 'troncal',
    templateUrl: './troncal-monitor.component.html'
})
export class TroncalMonitorComponent implements OnInit {
    @Input() troncal: IPeerMonitor;
    constructor() { }

    ngOnInit() { }
} 
