import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { tap, switchMap } from 'rxjs/operators';
import { SMSService } from '../../_services/sms.service';
import { Observable, Subject, of } from 'rxjs';
import { ICampanaSMS, INumeroSMS } from '../../_interfaces/sms';
import { NumeracionSMSComponent } from './numeracion-sms/numeracion-sms.component';

@Component({
  selector: 'app-sms',
  templateUrl: './sms.component.html',
  styleUrls: ['./sms.component.css']
})
export class SmsComponent implements OnInit {

    @ViewChild('config') tabConfig: ElementRef;
    @ViewChild('numeracion') tabNumeracion: ElementRef;
    @ViewChild('importacion') tabImportacion: ElementRef;
    @ViewChild('configPill') pillConfig: ElementRef;
    @ViewChild('numeracionPill') pillNumeracion: ElementRef;
    @ViewChild('importacionPill') pillImportacion: ElementRef;

    @ViewChild(NumeracionSMSComponent) smsNumeracion: NumeracionSMSComponent;

    campanaEditar: ICampanaSMS;
    estadoCampana = new Subject<boolean>();
    campanas$: Observable<ICampanaSMS[]>;
    
    constructor(private $sms: SMSService) { }

    ngOnInit() {
        this.estadoCampana.pipe(
            tap(estado => console.log(estado))
        );

        this.campanas$ = this.$sms.getCampanas();
    }

    changeStateCampana(estado: boolean, campana: ICampanaSMS) {
        of(Object.assign({}, campana, { estado: estado })).pipe(
            switchMap(c => this.$sms.saveCampana(c))
        )
        .subscribe(response => console.log(response));
    }

    setCampanaEdicion(campana: ICampanaSMS) {
        this.campanaEditar = Object.assign({}, campana);
    }

    resetEdicion() {
        this.campanaEditar = undefined;
    }

    guardarCampana(campana: ICampanaSMS) {
        this.$sms.saveCampana(campana).pipe(
            tap(() => this.campanas$ = this.$sms.getCampanas())
        )
        .subscribe(response => console.log(response));
    }

    guardarNumeros(numeros: INumeroSMS[]) {
        this.$sms.guardarNumeros(numeros)
        .subscribe(response => {
            console.log(response);
            this.tabConfig.nativeElement.classList.remove('active');
            this.tabNumeracion.nativeElement.classList.add('active');
            this.tabImportacion.nativeElement.classList.remove('active');
            this.pillConfig.nativeElement.classList.remove('active');
            this.pillNumeracion.nativeElement.classList.add('active');  
            this.pillImportacion.nativeElement.classList.remove('active');

            this.smsNumeracion.cargarNumeros(this.campanaEditar.id);
        });
    }

    eliminar(idx: string) {
        this.$sms.removeCampain(idx).pipe(
            tap(() => this.campanas$ = this.$sms.getCampanas())
        )
        .subscribe(response => console.log(response));
    }

}
