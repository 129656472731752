import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Observable, combineLatest, Subject } from 'rxjs';
import { INumeroSMS, EEstadoNumeroSMS } from 'src/app/_interfaces/sms';
import { SMSService } from 'src/app/_services/sms.service';
import { tap, map, startWith } from 'rxjs/operators';
import * as _ from 'lodash';

interface IChange extends SimpleChange {
    previousValue: number,
    currentValue: number
}

interface ISimpleChanges extends SimpleChanges {
    campanaId: IChange
}

@Component({
  selector: 'app-numeracion-sms',
  templateUrl: './numeracion-sms.component.html',
  styleUrls: ['./numeracion-sms.component.css']
})
export class NumeracionSMSComponent implements OnInit, OnChanges {

    estados = EEstadoNumeroSMS;

    frmNumero: FormGroup;
    numeros$: Observable<INumeroSMS[]>;
    numerosActualizados$ = new Subject<INumeroSMS[]>();

    @Input() 
    campanaId: number;

    constructor(private $fb: FormBuilder, private $sms: SMSService) { }

    ngOnInit() {
        this.iniciarFormulario();
    }

    iniciarFormulario(numero?: INumeroSMS) {
        this.frmNumero = this.$fb.group({
            idnumero: undefined,
            fecha_mod: undefined,
            idcampana: ['', Validators.required],
            numero: ['', Validators.required],
            variable: this.$fb.array([]),
            estado: ['']
        });

        // Inicializar valores.
        if (!numero && !!this.campanaId) {
            this.frmNumero.patchValue({
                idcampana: this.campanaId, 
                estado: EEstadoNumeroSMS.EN_ESPERA
            });
        } else if (numero) {
            const varArray = this.frmNumero.get('variable') as FormArray;
            (numero.variable as Array<{nombre: string, valor: string}>).forEach(v => {
                varArray.push(this.crearVariableNumero(v))
            });

            this.frmNumero.patchValue({
                idnumero: numero.idnumero,
                fecha_mod: numero.fecha_mod,
                idcampana: numero.idcampana,
                numero: numero.numero,
                estado: numero.estado
            });
        }
    }

    crearVariableNumero(data?: {nombre: string, valor: string}) {
        const grpVariable = this.$fb.group({
            nombre: ['', Validators.required],
            valor: ['', Validators.required]
        });

        if (data) {
            grpVariable.patchValue({
                nombre: data.nombre,
                valor: data.valor
            });
        }
        return grpVariable;
    }

    cargarNumeros(idCampana: number) {
        this.numeros$ = combineLatest(
            this.$sms.getNumerosEnCampana(idCampana),
            this.numerosActualizados$.pipe(
                startWith([])
            )
        ).pipe(
            map(latest => {
                let numerosAPublicar: INumeroSMS[] = [];
                const [numerosEnDB, actualizados] = latest;
                
                // Si existen numeros en db
                if (numerosEnDB) {
                    numerosAPublicar = [...numerosEnDB];
                    if (actualizados) {
                        // Buscar números en numerosEnDB
                        actualizados.forEach(n => {
                            numerosAPublicar = [...numerosAPublicar.filter(p => p.idnumero !== n.idnumero), n];
                        });
                    }
                }
                return numerosAPublicar;
            }),
            map(numeros => {
                const enviando = numeros.filter(n => n.estado === EEstadoNumeroSMS.ENVIANDO);
                const en_espera = numeros.filter(n => n.estado === EEstadoNumeroSMS.EN_ESPERA);
                const enviado = numeros.filter(n => n.estado === EEstadoNumeroSMS.ENVIADO);
                const fallido = numeros.filter(n => n.estado === EEstadoNumeroSMS.FALLIDO);
                const ordernado = (nums: INumeroSMS[]) => _.orderBy(nums, 'fecha_mod', 'desc');

                return [...ordernado(enviando), ...ordernado(en_espera), ...ordernado(enviado), ...ordernado(fallido)];
            })
        );
    }

    agregarVariable() {
        const arrVariables = this.frmNumero.get('variable') as FormArray;
        arrVariables.push(this.crearVariableNumero());
    }

    ngOnChanges(cambios: ISimpleChanges) {
        if (cambios.campanaId && cambios.campanaId.currentValue !== cambios.campanaId.previousValue) {
            this.cargarNumeros(cambios.campanaId.currentValue);
            this.iniciarFormulario();
        }
    }

    guadarNumero(numero: INumeroSMS) {
        this.$sms.guardarNumeros([numero]).pipe(
            tap(() => this.cargarNumeros(numero.idcampana))
        ).subscribe(res => {
            console.log(res);
            this.iniciarFormulario();
        });
    }


    eliminarVariable(idx: number) {
        const arrVariables = this.frmNumero.get('variable') as FormArray;
        arrVariables.removeAt(idx);
    }

    editarNumero(numero: INumeroSMS) {
        this.iniciarFormulario(numero);
    }

    eliminarNumero(numero: INumeroSMS) {
        this.$sms.eliminarNumero(numero.idnumero).pipe(
            tap(() => this.cargarNumeros(numero.idcampana))
        ).subscribe(res => {
            this.iniciarFormulario();
        });
    }

}
