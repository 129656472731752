import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { TimePipe } from './totime.pipe';
import { TextLimitPipe } from './txtLimit.pipe';
import {FormFilterPipe} from './formFilter.pipe';
import { AgenteComponent } from './agente.component';
import { TipificacionComponent } from './tipificacion.component';
import { UIModule } from '../_extra/ui/ui.module';

@NgModule({
    imports: [FormsModule, ReactiveFormsModule, BrowserModule, UIModule],
    declarations: [AgenteComponent, TipificacionComponent, TimePipe, TextLimitPipe, FormFilterPipe],
    exports: [TimePipe, AgenteComponent, TipificacionComponent, TextLimitPipe, FormFilterPipe]
})
export class AgenteConsoleModule { }
