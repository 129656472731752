import { Component, OnInit, ViewChild } from '@angular/core';
import { PollsService, IEncuentaFrm } from '../../_services/polls.service';
import { Observable } from 'rxjs/Observable';
import { PollComponent } from './poll/poll.component';

@Component({
    selector: 'polls-tag',
    templateUrl: 'polls.component.html'
})

export class PollsComponent implements OnInit {
    
    private polls$: Observable<IEncuentaFrm[]>
    private encuestaSeleccionada: IEncuentaFrm;

    @ViewChild(PollComponent)
    pollComponent: PollComponent;

    constructor(private $polls: PollsService) { }

    ngOnInit() { 
        $('#modalWait_kerberus').modal();
        this.polls$ = this.$polls.getEncuestas()
            .do(data => $('#modalWait_kerberus').modal('hide'));
        this.encuestaSeleccionada = undefined;
    }

    setEncuesta(encuesta: IEncuentaFrm) {
        this.encuestaSeleccionada = encuesta;
    }

    eliminarEncuesta(id: string) {
        this.$polls.eliminarEncuesta(id)
            .do(() => this.pollComponent.resetFrm())
            .subscribe(() => this.ngOnInit())
    }
}
