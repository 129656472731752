import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { HeaderService } from './header.service';
import { Observable } from 'rxjs/Observable';
import { IDBResponse, EDBResponseType } from '../_interfaces/responses';
import { Network } from '../_interfaces/network';

@Injectable()
export class NetworkService {
    
    constructor(private $http: Http, private $header: HeaderService){}
    
    
}