import {Pipe, PipeTransform} from '@angular/core';
import { IForma } from '../_interfaces/forma';

@Pipe({name: 'formfilter'})
export class FormFilterPipe implements PipeTransform {
    transform(formularios: IForma[], agenteColas: string[]): IForma[] {
        const frmPublicos: IForma[] = [];

        if (!!formularios && Array.isArray(formularios)) {
            frmPublicos.push(...formularios.filter(f => f.colas.length === 0));
            agenteColas.forEach(nombre => {
                const frms = formularios.filter(f => (f.colas as string[]).indexOf(nombre) > -1);
                frmPublicos.push(...frms);
            });
        }
        return frmPublicos;
    }
}
