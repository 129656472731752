import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { HeaderService } from './header.service';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import {MD5, SHA1} from 'crypto-js';

import { IDBResponse, EDBResponseType } from '../_interfaces/responses';
import { IAgentStatusReason, Agente } from '../_interfaces/agente';

import {LocalStorageService} from 'angular-2-local-storage';
import { switchMap } from 'rxjs/operators/switchMap';
import { map } from 'rxjs/operators/map';
import { of } from 'rxjs/observable/of';
import { _throw as throwError } from 'rxjs/observable/throw';
import { catchError } from 'rxjs/operators/catchError';

@Injectable()
export class AgenteService {
    
    private httpSubs: Subscription;

    constructor(private $http: Http, private $header: HeaderService, private $localStorage: LocalStorageService) {}
    
    setReason(reason: IAgentStatusReason): Observable<IDBResponse> {
        return this.$http.put(this.$header.getAPIurl() + '/agente/reason', reason, { headers: this.$header.getHeaders() })
            .map(res => res.json())
            .catch((err: Response) => Observable.throw(err.json()))
    }
    
    getReason(idreason: string): Observable<IAgentStatusReason[]> {
        return this.$http.get(this.$header.getAPIurl() + '/agente/reason/' + idreason, { headers: this.$header.getHeaders() })
            .map(res => res.json())
            .catch((err: Response) => Observable.throw(err.json()))
    }
    
    updateAgente(data: { agente: Agente, update: boolean }): Observable<IDBResponse> {
        let http$: Observable<Response>;
        if (data.update === undefined) {
            http$ = this.$http.delete(this.$header.getAPIurl() + '/agente/' + data.agente.idagente, { headers: this.$header.getHeaders() });
        } else if (data.update) {
            http$ = this.$http.post(this.$header.getAPIurl() + '/agente', data.agente, { headers: this.$header.getHeaders() });
        } else {
            http$ = this.$http.put(this.$header.getAPIurl() + '/agente', data.agente, { headers: this.$header.getHeaders() });
        }
        
        return http$
            .map(res => res.json())
            .catch((err: Response) => Observable.throw(err.json()))
    }
    
    getAgentes(): Observable<Agente[]> {
        return this.$http.get(this.$header.getAPIurl() + '/agente', { headers: this.$header.getHeaders() })
            .map(res => res.json())
            .catch((err: Response) => Observable.throw(<IDBResponse>err.json()))
    }
    
    /**
     * @returns Observable<{token: string, usuario: string}>
     */
    getSesionAgente(): Observable<{token: string, idagente: string}> {
        
        const sesion = this.$localStorage.get('kerberus_ipbx_token') as string;
        
        if (!!sesion) {
            this.$header.setHeader({nombre: 'authorization', valor: sesion})
            return this.$http.get(this.$header.getAPIurl() + '/agente/auth', {headers: this.$header.getHeaders()})
                .map(res => res.json());
        } else {
            return throwError({'ERR': 'Sin logueo de agente'});
        }
        
    }

    /**
     * @description Autenticación de agentes
     * @returns Observable boolean true si la autenticación en exitosa
     */
    loginAgente(agente: { usuario: string, password: string}): Observable<{token: string}> {

        return of(agente).pipe(
            map(orgAgente => Object.assign({}, orgAgente, {password: MD5(SHA1(orgAgente.password).toString()).toString()}) ),
            switchMap(usuario => this.$http.post(this.$header.getAPIurl() + '/agente/auth', usuario)),
            map(res => res.json() as IDBResponse),
            map(response => {
                if (response.tipo === EDBResponseType.AUTH_OK) {
                    this.$localStorage.set('kerberus_ipbx_token', response.data as string)
                    return {token: response.data as string}
                } else { 
                return undefined;
                }
            }),
            catchError((err: Response) => throwError(err.json()))
        );
    }
    
    /**
     * @returns Observable string 
     */
    cerrarSesion(): Observable<string> {
        return this.$http.delete(this.$header.getAPIurl() + '/agente/auth', {headers: this.$header.getHeaders()})
            .map(res => (res.json() as IDBResponse))
            .map(res => {
                if (res.tipo === EDBResponseType.OK) {
                    this.$localStorage.remove('kerberus_ipbx_token')
                }
                return res.data as string;
            })
            .catch(err => Observable.of(err))
    }
    
}
