import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, Observer } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { HeaderService } from './header.service';
import { ICallBack, ILocalCallback } from '../_interfaces/callback';

@Injectable()
export class CallbackService {
    constructor(private httpClient: HttpClient, private headers$: HeaderService, private $router: Router) { }

    getCallbacks(): Observable<ICallBack[]> {
        return of(this.headers$.getHttpHeaders()).pipe(
            switchMap(headers => this.httpClient.get<ICallBack[]>(this.headers$.getAPIurl() + '/callback', { headers: headers })),
            this.validationMiddle$([])
        );
    }
    
    guardarCallback(callback: ICallBack): Observable<boolean> {
        return of(callback).pipe(
            switchMap(rule => this.httpClient.put<boolean>(this.headers$.getAPIurl() + '/callback', rule, { headers: this.headers$.getHttpHeaders() })),
            this.validationMiddle$(false)
        );
    }

    eliminarCallbackRule(id: string): Observable<boolean> {
        return of(id).pipe(
            switchMap(callbackID => this.httpClient.delete<boolean>(this.headers$.getAPIurl() + '/callback/' + callbackID, { headers: this.headers$.getHttpHeaders() })),
            this.validationMiddle$(false)
        );
    }

    ejecutarCallbackLocal(callback: ILocalCallback): Observable<boolean> {
        return of(callback).pipe(
            switchMap(localCallback => this.httpClient.post<boolean>(this.headers$.getAPIurl() + '/callback/local' + localCallback, { headers: this.headers$.getHttpHeaders() })),
            this.validationMiddle$(false)
        );
    }

    /**
     * Función operable para devolver al usuario a login 
     * en caso de que falle su petición con respuesta 401: Unauthorized
     */
    private validationMiddle$ = (response: Object) => <T>(source: Observable<T>) => new Observable((o: Observer<any>) => {
        return source.pipe(
            catchError((err: HttpErrorResponse) => {
                if (err.status === 401) {
                    this.$router.navigateByUrl('/login');
                }
                return of(response);
            })
        ).subscribe({
            next(x) { o.next(x); },
            error(e) { o.error(e); },
            complete() { o.complete(); }  
        });
    });

}
