import {Component, Input} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'ui-toggleV1',
    template: `<input type="checkbox" [checked]="activo" [ngClass]="style" class="hidden"/>
        <label (click)="toggle(!activo)" class="lbl"></label>`,
    providers: [
        {provide: NG_VALUE_ACCESSOR, multi: true, useExisting: NewToggleComponent }
    ],
    styles: [`
          .lbl {
            position: relative;
            display: inline-block;
            height: 15px;
            width: 40px;
            background: #898989;
            border-radius: 100px;
            cursor: pointer;
            transition: all 0.3s ease;
          }

          .lbl:after {
            position: absolute;
            left: -2px;
            top: -3px;
            display: block;
            width: 21px;
            height: 21px;
            border-radius: 100px;
            background: #ddd;
            box-shadow: 0px 3px 3px rgba(0,0,0,0.05);
            content: '';
            transition: all 0.3s ease;
          }

          .lbl:active:after { transform: scale(1.15, 0.85); }

          .cbx:checked ~ label { background: #99bd99; }

          .cbx:checked ~ label:after {
            left: 20px;
            background: #5CB85C;
          }

          .cbx:disabled ~ label {
            background: #d5d5d5;
            pointer-events: none;
          }

          .cbx:disabled ~ label:after { background: #bcbdbc; }
          
          .hidden { display: none; }
          
          .cbx-success:checked ~ label { background: #99bd99; }
          .cbx-success:checked ~ label:after {
            left: 20px;
            background: #5CB85C;
          }
          
          .cbx-primary:checked ~ label { background: #7F9EB8; }
          .cbx-primary:checked ~ label:after {
            left: 20px;
            background: #337Ab7;
          }
    `]
})
export class NewToggleComponent implements ControlValueAccessor {
    
    private _style = 'cbx-success';
    @Input() set style(v) {
        this._style = (v || 'cbx-success');
    }
    get style() {return this._style; }
    
    activo: boolean;
    _onChange: (value: any) => void;
    
    constructor() {}

    toggle(opt: boolean) {
        this.activo = opt;
        this._onChange(opt);
    }
    
    writeValue(value: any) {
        this.activo = !!value;
    }
    
    registerOnChange(fn: (value: any) => void) {
        this._onChange = fn;
    }
    
    registerOnTouched() {}

}
