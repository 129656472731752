export interface IPeerMonitor {
    usuario: string;
    tipo: string | ETipoPeer;
    host: string;
    puerto: string;
    estado: string;
    estado_class: string;
    
    protocolo?: string;
    callerid?: string;
    comedia?: string;
    extra?: {
        tiempo: number,
        canal: string,
        app: string
    }
    channels?: IChannel[]
}

export enum ETipoPeer {
    TRUNK, PEER
}

export interface IChannel {
    Channel: string, 
    Context: string, 
    Exten: string, 
    Priority: string, 
    Stats: string, 
    Application: string, 
    Data: string, 
    CallerID: string, 
    Accountcode: string, 
    peerAccount: string, 
    Unidentified: string, 
    Duration: number, 
    Bridged: string, 
    UniqueID: string
}
export interface IQueueMonitor {
    nombre: string,
    callers: IQueueCaller[],
    stats: {
        holdtime: string,
        talktime: string,
        answered: string,
        abandon: string,
        serviceLevel: string,
        coverage: string,
    },
    agents: IAgentMonitor[]
}
export interface IAgentMonitor {
    fecha: string,
    nombre: string,
    idagente: string,
    estado: string,
    logged_on: string,
    last_call_date: Date,
    estado_idreason: string,
    estado_fecha: Date,
    complete: string,    
    stats: {
        cola: string,
        completeAgente: number,
        completeTiempo: number,
        completeUsuario: number
    }[],
    modoAgente: string,
    agentChannel?: IChannel,
    channel?: IChannel;
}

export enum EestadoAgentMonitor {
    OFFLINE,
    INBOUND_IDLE,
    OUTBOUND_IDLE,
    INBOUND_BUSY,
    INBOUND_BUSY_PAUSE,
    OUTBOUND_BUSY,
    PAUSE
}

interface IQueueCaller {
    canal: string | IChannel,
    espera: string,
    posicion: string | number,
    prioridad: string
}

export interface IQueueAlert {
    agente: string,
    cola: string,
    evento: string,
    fecha: string,
    tiempo_espera: string,
    uniqueid: string
}
