import { Pipe, PipeTransform } from '@angular/core';
import { IDialerNumero, EEstadoNumeroDialer } from '../../../_interfaces/dialer';
import * as _ from 'lodash';

@Pipe({
    name: 'orderDailerNumber'
})

export class DialerNumbersPipe implements PipeTransform {
    transform(numeros: IDialerNumero[]): IDialerNumero[] {
        
        if (Array.isArray(numeros)) {
            let _numeros = Object.assign([], [...this.ordenar(numeros, EEstadoNumeroDialer.EN_ESCUCHA)])
            _numeros = Object.assign([], [..._numeros, ...this.ordenar(numeros, EEstadoNumeroDialer.EN_LLAMADA)])
            _numeros = Object.assign([], [..._numeros, ...this.ordenar(numeros, EEstadoNumeroDialer.EN_CURSO) ])
            _numeros = Object.assign([], [..._numeros, ...this.ordenar(numeros, EEstadoNumeroDialer.EN_ESPERA)])
            _numeros = Object.assign([], [..._numeros, ...this.ordenar(numeros, EEstadoNumeroDialer.FALLIDO)])
            _numeros = Object.assign([], [..._numeros, ...this.ordenar(numeros, EEstadoNumeroDialer.FINALIZADO)])
            return _numeros;
        }
        return numeros;
    }

    private ordenar(numeros: IDialerNumero[], estado: EEstadoNumeroDialer): IDialerNumero[] {
        return _.orderBy(numeros.filter(n => n.estado === estado), ['idnumero'])
    }
}
