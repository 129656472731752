import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IPeerMonitor } from '../../../_interfaces/monitor';

@Component({
    selector: 'extension',
    templateUrl: './extension-monitor.component.html'
})
export class ExtensionMonitorComponent implements OnInit {
    zeroDate: number;
    @Input() mod: boolean;
    @Input() exten: IPeerMonitor;
    @Output() editar = new EventEmitter <IPeerMonitor>();
    
    constructor() { 
        const fechaAux = new Date(0);
        fechaAux.setHours(0);
        this.zeroDate = fechaAux.getTime();
    }
    
    editarExt(peer: IPeerMonitor) {
        if (this.mod) {
            this.editar.emit(peer);
        }
    }

    ngOnInit() { }
} 
