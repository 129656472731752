import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { SocketService } from '../_services/socket.service';
import { AgenteService } from '../_services/agente.service';
import { QueueService } from '../_services/queue.service';
import { GruposService } from '../_services/grupo.service';
import { PeerService } from '../_services/peer.service';
import { IVRService } from '../_services/ivr.service';
import { TroncalService } from '../_services/troncal.service';
import { NetworkService } from '../_services/network.service';
import { PollsService } from '../_services/polls.service';

import { AdminMenuComponent } from './adminmenu/adminMenu.component';

import { AgenteEdicionComponent } from './agente/agente.component';
import { AgenteDetalleComponent } from './agente/agentedetalles.component';
import { TroncalSetupComponent } from './troncal/troncal.component';
import { UsuarioComponent } from './usuarios/usuarios.component';
import { TipificacionesComponent } from './tipificacion/tipificaciones.component';

import { GrupoComponent } from './grupos/grupo.component';
import { GruposComponent } from './grupos/grupos.component';

import { SistemaComponent } from './sistema/sistema.component'

import { Tipificacion} from './tipificacion/tipificacion.module';
import { FileUploadModule } from 'ng2-file-upload/ng2-file-upload';
import { ChartModule } from 'angular2-highcharts';
import { MomentModule } from 'ngx-moment';
import { PipesModule } from '../pipes/pipes.module';

import { UIModule} from '../_extra/ui/ui.module';
import { PollsModule } from './polls/polls.module';
import { PollsComponent } from './polls/polls.component';
import { ConsolaComponent } from './consola-ext/consola-ext.component';
import { ConsolaExtModule } from './consola-ext/consola-ext.module';
import { ConsolaCallcenterComponent } from './consola_call/consola_call.component';
import { ConsolaCallModule } from './consola_call/consola_call.module';
import { CallbackComponent } from './callback/callback.component';
import { CallbackModule } from './callback/callback.module';
import { RouterModule } from '@angular/router';
import { ApiManagerModule } from './apimanager/apimanager.module';
import { ApiManagerComponent } from './apimanager/apimanager.component';
import { DialerModule } from './dialer/dialer.module';
import { DialerComponent } from './dialer/dialer.component';
import { Ng2PaginationModule } from 'ng2-pagination';
import { SmsModule } from './sms/sms.module';
import { SmsComponent } from './sms/sms.component';
import { SMSService } from '../_services/sms.service';


@NgModule({
    providers: [SocketService, AgenteService, QueueService, GruposService, PeerService, IVRService, TroncalService, NetworkService, PollsService, SMSService],
    imports: [
        RouterModule,
        BrowserModule, FormsModule, ReactiveFormsModule, FileUploadModule, ChartModule, UIModule, 
        ConsolaCallModule,
        ConsolaExtModule,
        DialerModule,
        CallbackModule,
        Tipificacion, 
        PollsModule,
        SmsModule,
        ApiManagerModule,
        PipesModule,
        MomentModule,
        Ng2PaginationModule
    ],
    declarations: [AdminMenuComponent, ConsolaComponent, 
        ConsolaCallcenterComponent,
        AgenteEdicionComponent,
        AgenteDetalleComponent,
        GrupoComponent, 
        GruposComponent,
        TroncalSetupComponent,
        UsuarioComponent,
        CallbackComponent,
        SmsComponent,
        SistemaComponent,
        ApiManagerComponent,
        TipificacionesComponent, 
        DialerComponent,
        PollsComponent
    ],
    exports: [AdminMenuComponent, ConsolaComponent, 
        ConsolaCallcenterComponent,
        AgenteEdicionComponent,
        AgenteDetalleComponent,
        GrupoComponent, 
        GruposComponent,
        TroncalSetupComponent,
        CallbackComponent,
        UsuarioComponent,
        SistemaComponent,
        SmsComponent,
        ApiManagerComponent,
        TipificacionesComponent,
        DialerComponent,
        PollsComponent
    ]
})
export class AdminModule {}
