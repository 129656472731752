import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, toArray, map } from 'rxjs/operators';
import { Observable, merge } from 'rxjs';
import { IDialerCampana, IDialerNumero, EEstadoNumeroDialer } from '../_interfaces/dialer';
import { HeaderService } from './header.service';
import { concat } from 'rxjs-compat/operator/concat';

@Injectable()
export class DialerService {
    constructor(private httpClient: HttpClient, private readonly $header: HeaderService) { }
    
    getCampanas$(): Observable<IDialerCampana[]> {
        return this.httpClient.get<IDialerCampana[]>(this.$header.getAPIurl() + '/dialer', { headers: this.$header.getHttpHeaders() });
    }

    guardarCampana$(campana: IDialerCampana): Observable<boolean> {
        return this.httpClient.post<boolean>(this.$header.getAPIurl() + '/dialer', campana, { headers: this.$header.getHttpHeaders() });
    }

    eliminarCampana(idCampana: string): Observable<boolean> {
        return this.httpClient.delete<boolean>(this.$header.getAPIurl() + '/dialer/' + idCampana, { headers: this.$header.getHttpHeaders() });
    }

    guardarNuevaCampana$(campana: IDialerCampana, numeros: IDialerNumero[]): Observable<boolean> {
        return merge(
            this.httpClient.post<boolean>(this.$header.getAPIurl() + '/dialer', campana, { headers: this.$header.getHttpHeaders()}),
            this.httpClient.post<boolean>(this.$header.getAPIurl() + '/dialer/numeros', numeros, { headers: this.$header.getHttpHeaders()})
        ).pipe(
            toArray(),
            map(data => data.length === 0)
        );
    }

    ejecutarSalto(idcampana: string, salto: number): Observable<boolean> {
        return this.httpClient.put<boolean>(this.$header.getAPIurl() + '/dialer/salto', 
            { idCampana: idcampana, salto: salto },
            { headers: this.$header.getHttpHeaders() }
        );
    }

    actualizarNumerosCampana(idCampana: string, estado: EEstadoNumeroDialer): Observable<boolean> {
        return this.httpClient.put<boolean>(this.$header.getAPIurl() + '/dialer/numeros', 
            { idCampana: idCampana, estado: estado },
            { headers: this.$header.getHttpHeaders() }
        );
    }
    
}
