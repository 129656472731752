import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioService } from '../../_services/usuario.service';
import { Usuario, EUsuarioRol } from '../../_interfaces/usuario';
import * as _ from 'lodash';
import { SocketService } from '../../_services/socket.service';

@Component({
    selector: 'admin-menu',
    styleUrls: ['../admin.component.css', './adminMenu.component.css'],
    templateUrl: './adminMenu.component.html'
})
export class AdminMenuComponent implements OnInit {
        
    private vm = EVistas; // variable usada en la vista
    private _rol = EUsuarioRol;
    
    @Input() stats: ITopMenu = { }; // Variable que maneja lo que se muestra en el top menu
    
    @Output() operacion = new EventEmitter<IOperacion>();
    
    constructor(private $usuario: UsuarioService, private $router: Router) { }

    ngOnInit() { }

    /**
     * Función para retornar a la vista por defecto
     */
    home(): void {
        this.$router.navigateByUrl('/admin');
    }
    
    /**
     * Función para cerrar sesión
     */
    cerrarSesion(): void {
        this.$usuario.cerrarSesion()
            .subscribe(
                () => {
                    console.log('out');
                    this.$router.navigateByUrl('/login');
                },
                err => console.warn(err)
            )
    }
    
    /**
     * Función para ejecutar operaciones entre Componentes
     */
    setOperacion(vista: EVistas, valor?: string ) {
        const usr = this.$usuario.usuario;
        if ( usr.permisos <= this._rol.MANAGER || !valor || 
            (usr.permisos === this._rol.SUPER && _.findIndex(usr.manager, ['cola', valor]) > -1) 
        ) {
            this.operacion.emit(<IOperacion>{ vista: vista, subVista: valor});
        }
    }
    
    validar(queueName: string): boolean {
        const index = _.findIndex(this.$usuario.usuario.manager, ['cola', queueName]);
        return index > -1 ? true : false;
    }
}

export interface ITopMenu {
    current_view?: EVistas, 
    nombre?: string, 
    menu?: string[],
    stats?: {
        nombre: string, 
        valor: string
    }[]
}

export interface IOperacion {
    vista: EVistas,
    subVista: string
}

export enum EVistas {
    EXTENSIONES = <any>'Extensiones',
    GRUPOS = <any>'Grupos de Extensiones',
    INFORMES = <any>'Registros de llamadas',
    ESTADISTICAS = <any>'Estadísticas',
    AGENTES = <any>'Agentes',
    CALLCENTER = <any>'Callcenter',
    CALLCENTER_REPORTES = <any>'Registros de llamadas Callcenter',
    AGENTES_REPORTES = <any>'Informe de Agentes',
    CALLCENTER_STATS = <any>'Informes de Callcenter',
    IVR = <any>'IVR & DID\'s',
    TRONCALES = <any>'Troncales',
    SISTEMA = <any>'Sistema',
    USUARIO = <any>'Usuarios & Permisos',
    TIPIFICACION = <any>'Tipificación & Clasificación',
    CALLBACK = <any>'Callback | Click2Call',
    POLLS = <any>'Encuestas de Servicio',
    DIALER = <any>'Kerberus Dialer',
    SMS = <any>'Envio masivo de SMS',
    APIMANAGER = <any>'API Key Manager'
}
