import { Pipe, PipeTransform } from '@angular/core';
import { EApiKeyTipo } from '../../../_interfaces/apikey';

@Pipe({
  name: 'keyType'
})
export class KeyTypePipe implements PipeTransform {

    transform(value: string, tipo: EApiKeyTipo): any {
        return tipo === EApiKeyTipo.MANAGER_KEY ? 
                        { 
                            keyval: value.substring(0, value.indexOf(':')), 
                            keypass: value.substring(value.indexOf(':') + 1) 
                        } : { keyval: value };
    }

}
