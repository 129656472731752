import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { HeaderService } from './header.service';

import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { Subject } from 'rxjs/Subject';
import { FileUploader, Headers } from 'ng2-file-upload/ng2-file-upload'

import { IDBResponse, EDBResponseType } from '../_interfaces/responses';
import { Queue } from '../_interfaces/queue';
import * as moment from 'moment';

export interface IPerformance {
    cola: string,
    hora: string[],
    agente: {
        name: string,
        llamadas: {
            cant: number,
            tiempo: number
        }[]
    }[]
}

@Injectable()
export class QueueService {
    
    private upload$ = new Subject<IDBResponse>();
    uploader: FileUploader = new FileUploader({});
    
    constructor(private $http: Http, private $header: HeaderService) {
        const headers = <Headers[]>[{
                name: 'authorization',
                value: this.$header.getHeader('authorization')
            }];
        
        // Conf. 
        this.uploader.setOptions({ url: this.$header.getAPIurl() + '/queue/audio', headers: headers, itemAlias: 'audio_attach' });
        
        // Funciones uploading...
        this.uploader.onCompleteItem =  (item, response: any, status) => {
            const res = <IDBResponse>JSON.parse(response);
            this.uploader.removeFromQueue(item)
            this.upload$.next(res);
        };
        
        this.uploader.onAfterAddingFile = (item) => {
            this.upload$.next({
                tipo: EDBResponseType.OK, 
                data: item.file.name
            });
        }
        
        this.uploader.onErrorItem = (item: any, response: any, status: any) => {
            console.log('errr:', item, status, response);
            const res = <IDBResponse>JSON.parse(response);
            this.uploader.removeFromQueue(item)
            this.upload$.error(res);
        };        
    }
    
    getUploader(): FileUploader {
        return this.uploader;
    }
    
    getUploaderEvent(): Observable<IDBResponse> {
        return this.upload$.asObservable();
    }
    
    getQueue(nombre: string): Observable<Queue> {
        return this.$http.get(this.$header.getAPIurl() + '/queue/' + nombre, { headers: this.$header.getHeaders() })
            .map(res => res.json())
            .catch((err: Response) => Observable.throw(err.json()))
    }
    
    getQueues(): Observable<Queue[]> {
        return this.$http.get(this.$header.getAPIurl() + '/queues', { headers: this.$header.getHeaders() })
            .map(res => res.json())
            .catch((err: Response) => Observable.throw(err.json()))
    }
    
    modificarQueue(data: { queue: Queue, update: boolean}): Observable<any> {

        let http$: Observable<Response>;
        
        if (data.update === undefined) {
            http$ = this.$http.delete(this.$header.getAPIurl() + '/queue/' + data.queue.nombre, { headers: this.$header.getHeaders() });
            
        } else if (data.update === true) {
            http$ = this.$http.post(this.$header.getAPIurl() + '/queue/', data.queue, { headers: this.$header.getHeaders() });
            
        } else if (data.update === false) {
            http$ = this.$http.put(this.$header.getAPIurl() + '/queue/', data.queue, { headers: this.$header.getHeaders() });
        }    
        return http$
            .map(res => res.json())
            .catch((err: Response) => Observable.throw(err.json()))
    }
    
    getPerformace(queue: string, fecha: string): Observable<IPerformance> {
        const query = {
            fecha: {
                inicio: (!!fecha && fecha !== '' ? fecha : moment().format('YYYY-MM-DD')),
                fin: (!!fecha && fecha !== '' ? fecha : moment().format('YYYY-MM-DD'))
            },
            cola: queue
        };
        
        return this.$http.post(this.$header.getAPIurl() + '/agent_performance', query, { headers: this.$header.getHeaders() })
            .map(res => (res.json() as IPerformance[]).pop())            
            .catch((err: Response) => Observable.throw(err.json()))
    }
    
    getCallHistory(callid: string): Observable<Object[]> {
        return this.$http.get(this.$header.getAPIurl() + '/queue/call/' + callid, { headers: this.$header.getHeaders() })
            .map(res => res.json())
            .catch((err: Response) => Observable.throw(err.json()))
    }
    
    subirAudio(): void {
        this.uploader.queue.forEach(item => {            
            if ((item.file.size / 1024 / 1024) > 10) {
                this.upload$.error(<IDBResponse>{
                    tipo: EDBResponseType.ERR,
                    data: 'El archivo pesa más de 10Mb'
                });
            } else {
                item.upload();
            }
        });
    }
    
    // No lanzar llamada, borrarla de la lista de llamadas pendientes
    cancelarLlamada(llamada: {phone: string, queuename: string; id: string}): Observable<Response> {
        return this.$http.post(this.$header.getAPIurl() + '/queue/callback', llamada, { headers: this.$header.getHeaders() })
            .map(res => res.json());
    }
    
    devolverLlamada(llamada: {phone: string, queuename: string; id: string}, domain?: string): Observable<Response> {
        
        // TODO: 
        // Hacer solicitud de las reglas
        return this.$http.get(this.$header.getAPIurl() + '/callback', { headers: this.$header.getHeaders() })
            .map(res => res.json() as {operacion: string, valor: string, id: string, prefix: string[]}[])

            // Encontrar cuales de las reglas tiene el prefix
            .flatMap(rules => rules)

            // Filtrar solo aquellas que tengan el nombre de la cola y su operación sea Queue
            .filter(rule => {

                const reglaQueCoincide = rule.prefix.find(prefix => {
                    const prefijo = prefix.replace(/X/g, '');
                    const regEx = new RegExp('^' + prefijo + '[0-9]{' + prefix.replace(prefijo, '').length + '}$');
                    return regEx.test(llamada.phone);
                });

                return !!reglaQueCoincide && rule.operacion.indexOf('Queue') > -1 && rule.valor.indexOf(llamada.queuename) > -1 
            })

            // Solo la primera que haga match
            .first()
            
            // Concatenar dos observables que dependen del mismo map || filter
            .switchMap(rule => {
                console.log(rule)
                // Lanzar la llamada de callback con destino
                return this.$http.post(this.$header.getAPIurl() + '/callback', {numero: llamada.phone, id: rule.id}, { headers: this.$header.getHeaders() })
                
                    // Actualizar en db que la llamada ya fue lanzada
                    .concat(this.$http.post(this.$header.getAPIurl() + '/queue/callback', llamada, { headers: this.$header.getHeaders() }))
            })
            .map(res => res.json());
    }
}
