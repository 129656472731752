import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import * as _ from 'lodash';
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subscription } from 'rxjs/Subscription';

import { IPeerMonitor, IChannel, IQueueMonitor, IQueueAlert } from '../_interfaces/monitor';
import { HeaderService } from './header.service';
import { SOCKET_SERVER } from '../../_environments/environment';

@Injectable()
export class SocketService {
    
    private socket: SocketIOClient.Socket;    
    private peers$ = new BehaviorSubject<IPeerMonitor[]>([]);
    private queues$ = new BehaviorSubject<IQueueMonitor[]>([]);
    private channels$ = new BehaviorSubject<Object[]>([]);
    private queueAlerts$ = new Subject<IQueueAlert>();
    private queueCallback$ = new Subject<Object>();
    private sysInfo$ = new BehaviorSubject<Object>(undefined);
    private dialer$ = new Subject<any>();
    
    private messages$ = new Subject<{fuente: string, mensaje: string}>();
    
    private monitorSubscription: Subscription;
    
    constructor(private $header: HeaderService) {
        
        this.socket = io( (!!SOCKET_SERVER ? SOCKET_SERVER : '') + '/kerberusipbx?token=' + this.$header.getHeader('Authorization'));
        this.socket.connect();

        this.monitorSubscription = this.runMonitor().subscribe(() => { }, err => console.log(err));
    }
    
    detenerMonitor(): void {
        if (!this.monitorSubscription && !this.monitorSubscription.closed) {
            this.monitorSubscription.unsubscribe();
        }
    }
    
    setRooms(rooms: string[]) {
        rooms.forEach(room => {
            this.socket.emit('join', room);
        });
    }
    
    exitRooms(rooms: string[]) {
        rooms.forEach(room => {
            this.socket.emit('leave', room);
        });
    }
    
    enviarMensaje(mensaje: {fuente: string, mensaje: string}) {
        this.socket.emit('message', JSON.stringify(mensaje));
    }
    
    getChannels(): IChannel[] {
        return <IChannel[]>this.channels$.value;
    }
    
    getPeers(): Observable<IPeerMonitor[]> {
        return this.peers$.asObservable();            
    }
    
    getQueues(): Observable<IQueueMonitor[]> {
        return this.queues$.asObservable();
    }
    
    getQueueAlerts(): Observable<IQueueAlert> {
        return this.queueAlerts$.asObservable();
    }
    
    getQueueCallbacks(): Observable<Object> {
        return this.queueCallback$.asObservable();
    }
    
    getSysInfo(): Observable<Object> {
        return this.sysInfo$.asObservable();
    }
    
    getMensajes(idAgente: string): Observable<{fuente: string, mensaje: string}> {
        return this.messages$.asObservable()
            .filter(mensaje => mensaje.mensaje.indexOf('@' + idAgente + ' ') > -1);
    }
       
    /**
     * @param rooms Colas de llamada de las cuales es manager el usuario
     * @returns Observable { tipo: ETipoMonitor, data: Object}
     */
    private runMonitor(): Observable<any> {
        return new Observable((o: Observer<any>) => {
            try {
                this.socket.on('channels', jsonChannels => {
                    this.channels$.next(JSON.parse(jsonChannels))
                });

                this.socket.on('queueAlert', jsonAlertas => {
                    this.queueAlerts$.next(JSON.parse(jsonAlertas));
                });
                
                this.socket.on('queueCallback', jsonCallback => {
                    this.queueCallback$.next(JSON.parse(jsonCallback));
                });

                this.socket.on('peers', jsonPeers => {
                    const peersState = JSON.parse(jsonPeers);
                    if (!_.isEqual(this.peers$.value, peersState)) {
                        this.peers$.next(peersState);
                    }
                });
                
                this.socket.on('sysinfo', jsonSysInfo => {
                    this.sysInfo$.next(JSON.parse(jsonSysInfo));
                });

                this.socket.on('queues', jsonQueues => {
                    let queues: IQueueMonitor[] = JSON.parse(jsonQueues);
                    queues = _.sortBy(queues, ['nombre']);
                    
                    this.queues$.next(queues);
                });
                
                this.socket.on('message', jsonMessage => {
                    this.messages$.next(JSON.parse(jsonMessage));
                });
                
                this.socket.on('dialer', jsonMessage => {
                    this.dialer$.next(JSON.parse(jsonMessage));
                });
                
            } catch (e) {
                o.error(e);
            }
            
            return () => {
                this.socket.disconnect();
            }
        });
    }

    /**
     * Función para enviar comandos
     * @param comando IComando
     */
    enviarComando(comando: IComando): Observable<{ tipo: EResponse, data: Object}> {
        return new Observable((o: Observer<{ tipo: EResponse, data: Object}>) => {
            const cmdSocket = io('/kerberusipbx?token=' + this.$header.getHeader('Authorization'));
            
            cmdSocket.on('cmd-res', jsonCMDResponse => {
                const response: { tipo: EResponse | string, msj: Object} = JSON.parse(jsonCMDResponse);
                o.next({ tipo: EResponse[<string>response.tipo], data: response.msj});
                o.complete();
            });
            
            cmdSocket.on('connect', () => {
                comando.comando = EComando[comando.comando];
                comando.socketID = cmdSocket.nsp + '#' + cmdSocket.id;
                cmdSocket.emit('cmd-in', JSON.stringify(comando));
            });
            
            return () => {
                cmdSocket.disconnect();
            }
        })
    }
}

export enum EResponse {
    EXITOSO, FALLIDO
}

export interface IComando {
    comando: string | EComando,
    data: Object,
    socketID?: string,
}

export enum EComando {
    PING,
    XFER,
    RESET_STATS,
    AGENT_INFO,
    AGENT_LOGOUT,
    AGENT_PAUSE,
    QUEUE_RELOAD,
    QUEUE_PARAMETERS,
    HANGUP_CALL,
    AUDIO_XFORM,
    AUDIO_FORMAT,
    KERBERUS_FILE,
    KERBERUS_OPS,
    KERBERUS_POWER,
    MAKE_CALL,
    BACK_UP_CONF,
    SQL_MTT,
    KERBERUS_NETS,
    FIREWALL,
    KERBERUS_ROUTES,
    SYS_INFO,
    SET_DATE
}
