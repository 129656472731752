import { Component, OnInit } from '@angular/core';
import { Http, Response } from '@angular/http';
import { HeaderService } from '../../_services/header.service';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import * as moment from 'moment';
import * as _ from 'lodash';

import {EQueryType } from '../../_interfaces/informes';
import { of } from 'rxjs/observable/of';
import { from } from 'rxjs/observable/from';
import { switchMap, map, tap, flatMap, toArray, reduce, finalize } from 'rxjs/operators';
import { FileHandlerService } from '../../_services/file.service';

@Component({
    selector: 'agent_log',
    templateUrl: 'agent_log.component.html'
})
export class AgentLogComponent implements OnInit {
    logs: IQueryResult[];
    private http_: Subscription;
    constructor(private $http: Http, private $header: HeaderService, private $file: FileHandlerService) {}

    ngOnInit() { }
    
    ejecutarBusqueda(_query: IQuery) {
        $('#modalWait_kerberus').modal();
        
        if (!!this.http_ && !this.http_.closed) {
            this.http_.unsubscribe();
        }
        
        this.http_ = this.$http.post(this.$header.getAPIurl() + '/agent_log_summary', _query, { headers: this.$header.getHeaders() })
            .map(res => res.json())
            .catch((err: Response) => Observable.throw(err.json()))
            .subscribe(
                (logs: IQueryResult[]) => {
                    this.logs = logs;
                    $('#modalWait_kerberus').modal('hide');
                },
                err => console.log(err)
            )
    }
    
    descargarAgentLog(busqueda: IQuery) {
        $('#modalWait_kerberus').modal();
        of(busqueda).pipe(
            map(q => Object.assign({}, q, {queryType: EQueryType.LIST})),
            switchMap(query => this.$http.post(this.$header.getAPIurl() + '/agent_log', query, { headers: this.$header.getHeaders() })),
            flatMap(response => response.json() as {time: string, agente: string, nombre: string, event: string, motivo: string, tipo: string, autor: string, data2: string}[]),
            map(log => {
                const auxFecha = moment().set({'hours': 0, 'minutes': 0, 'seconds' : 0});
                return {
                    FECHA: moment(log.time).format('YYYY-MM-DD'),
                    HORA: moment(log.time).format('HH:mm:ss'),
                    AGENTE: log.agente,
                    NOMBRE: log.nombre,
                    EVENTO: log.event,
                    TIEMPO: log.data2 !== '' && (log.event === 'AGENTLOGOFF' || log.event === 'UNPAUSE') ? _.cloneDeep(auxFecha).add(log.data2, 'seconds').format('HH:mm:ss') : 'NO APLICA',
                    AUTOR: log.autor,
                    TIPO: log.tipo,
                    MOTIVO: log.motivo
                }
            }),
            toArray(),
            map(data => _.sortBy(data, ['FECHA', 'HORA'])),
            tap(data => this.$file.exportCSVFile(Object.keys(data[0]), data, 'Detalle_Agentes_' + new Date().getTime())),
            finalize(() => $('#modalWait_kerberus').modal('hide'))
        ).subscribe()
        
    }

    descargarResumen() {
        const fecha = moment().set('hours', 0).set('minutes', 0).set('seconds', 0);
        from(this.logs).pipe(
            map(ag => {
                const csvData = [];
                ag.data.forEach(nData => {
                    
                    csvData.push({
                        Agente: ag.agente,
                        Nombre: ag.nombre,
                        Fecha: nData.fecha,
                        'Hora Inicio': nData.fechaInicio !== '' ? moment(nData.fechaInicio).format('HH:mm:ss') : '',
                        'Hora Fin': nData.fechaFin !== '' ? moment(nData.fechaFin).format('HH:mm:ss') : '',
                        'Cant. Logins': nData.cantLogueo,
                        'Tiempo Conectado': (_.cloneDeep(fecha)).add(nData.onlineTime, 'seconds').format('HH:mm:ss'),
                        'Llamadas Atendidas': nData.cantLlamadas,
                        'Tiempo en Conversacion': (_.cloneDeep(fecha)).add(nData.convTime, 'seconds').format('HH:mm:ss'),
                        'Tiempo en Pausa': (_.cloneDeep(fecha)).add(nData.pauseTime, 'seconds').format('HH:mm:ss')
                    });
                });

                return csvData;
            }),
            reduce((acc, curr) => [...acc, ...curr], []),
            map(data => _.sortBy(data, ['Fecha'])),
            tap(data => this.$file.exportCSVFile(Object.keys(data[0]), data, 'Informe_Agentes_' + new Date().getTime()))
        ).subscribe()
    }

} 

export interface IQuery {
    fecha?: {
        inicio: string, 
        fin: string
    },
    idagente?: string,
    queryType: EQueryType;
}

export interface IQueryResult {
    agente: string, 
    nombre: string,
    data: {
        fecha: string,
        fechaInicio: string,
        fechaFin: string,
        cantLogueo: number,
        onlineTime: number,
        cantLlamadas: number,
        convTime: number,
        pauseTime: number
    }[]
}
