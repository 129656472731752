import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { PeerService } from '../../../_services/peer.service';
import { Subject } from 'rxjs/Subject';
import * as _ from 'lodash';

import { Peer, PeerPerfil } from '../../../_interfaces/peer';
import { IPeerMonitor } from '../../../_interfaces/monitor';

interface IChange extends SimpleChanges {
    peerMonitor: SimpleChange
}

@Component({
    selector: 'peer-tag',
    templateUrl: './peer.component.html'
})
export class PeerComponent implements OnInit, OnChanges {
    
    private personalView = false;
    private secretView = false;
    private peer: Peer;
    private viewPass$ = new Subject<Event>();
    
    @Input() readOnly: boolean;
    @Input() peerMonitor: IPeerMonitor;     
    @Output() peerOutput = new EventEmitter<{peer: Peer, update: boolean}>();
    
    constructor(private $peer: PeerService) { 
        this.viewPass$.asObservable()
            .subscribe(evento => console.log(evento));
    }

    ngOnInit() { }
    
    ngOnChanges(cambios: IChange) {
        if (cambios.peerMonitor && cambios.peerMonitor.currentValue) {
            console.log(this.peer);
            if (!!this.peerMonitor.usuario) {
                this.$peer.getPeer(this.peerMonitor.usuario)
                    .subscribe(peer => {
                        if (peer.length > 0) {
                            this.peer = peer[0];
                        } else {                            
                            this.peer = new Peer();
                            this.peer.usuario = Number.parseInt(this.peerMonitor.usuario);
                        }
                });
            }
            this.peer = new Peer();
        } 
    }
    
    guardarPeer(peer: Peer) {
        this.peerOutput.emit({
            peer: peer,
            update: this.readOnly
        });
    }
    
    eliminarPeer(peer: Peer) {
        this.peerOutput.emit({
            peer: peer,
            update: undefined
        });
    }
    
    removerPerfil(contexto: string) {
        const index = _.findIndex(this.peer.perfil, ['contexto', contexto]);
        this.peer.perfil.splice(index, 1);
    }
    
    addPerfil(contexto: string, limit: number) {

        if (_.findIndex(this.peer.perfil, ['contexto', contexto]) < 0) {
            this.peer.perfil.push({
                usuario: this.peer.usuario, 
                contexto: contexto,
                limit: (!!limit ? limit * 60 * 1000 : 60000)
            });
        }
    }
    
    addGrupo(index) {
        const grupos: { grupo: string }[] = <{ grupo: string }[]>_.cloneDeep(this.peer.grupos);
        if (_.findIndex(grupos, ['grupo', index]) < 0) {
            grupos.push({
                grupo: index
            });
            console.log(_.sortBy(grupos, ['grupo']))
        }
        this.peer.grupos = _.cloneDeep(_.sortBy(grupos, ['grupo']));
    }
    
    eliminarGrupo(indexgrupo: string) {
        const grupos: {grupo: string}[] = <{grupo: string}[]>this.peer.grupos;
        const idx = grupos.findIndex(g => g.grupo === indexgrupo);
        
        grupos.splice(idx, 1);
    }
} 
