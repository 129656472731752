import { Injectable} from '@angular/core';
import { Http, Response } from '@angular/http';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { Subject } from 'rxjs/Subject';
import {MD5, SHA1} from 'crypto-js';

import { Usuario } from '../_interfaces/usuario';
import { IDBResponse, EDBResponseType } from '../_interfaces/responses';

import { HeaderService } from './header.service';
import {LocalStorageService} from 'angular-2-local-storage';
import { of } from 'rxjs/observable/of';
import { _throw as throwError } from 'rxjs/observable/throw';
import { switchMap, map, flatMap, catchError } from 'rxjs/operators';

@Injectable()
export class UsuarioService {
    
    usuario: Usuario;
    private httpSubs: Subscription;
    
    constructor(private $http: Http, private $headers: HeaderService, private $localStorage: LocalStorageService) {}
    
    /**
     * @returns Observable<{token: string, usuario: string}>
     */
    // <editor-fold defaultstate="collapsed" desc="getSesionUsuario(): Observable<{token: string, usuario: string}>">
    getSesionUsuario(): Observable<{token: string, usuario: string}> {
        const sesion = this.$localStorage.get('kerberus_ipbx_token') as string;
        if (!!sesion) {
            this.$headers.setHeader({nombre: 'Authorization', valor: sesion});
            return this.$http.get(this.$headers.getAPIurl() + '/usuario/auth', {headers: this.$headers.getHeaders()})
                .map(res => res.json());
        } else {
            const _res = new Subject<{token: string, usuario: string}>();
            _res.error({'ERR': 'Sin logueo'});
            _res.complete();
            return _res.asObservable();
        }
    }
    // </editor-fold>
    
    /**
     * @param id idUsuario
     * @returns Observable Usuario
     */
    getUsuario(id: string): Observable<Usuario> {
        return new Observable((o: Observer<Usuario>) => {
            this.$http.get(this.$headers.getAPIurl() + '/usuario/' + id, { headers: this.$headers.getHeaders()})
                .map(res => (res.json() as Usuario[]))
                .subscribe(
                    usuario => {
                        if (!!usuario && usuario.length > 0) {
                            o.next(usuario.pop());
                        } else {
                            o.error('No existe el usuario');
                        }
                    },
                    err => o.error(err),
                    () => o.complete()
                );
        });
    }

    /**
     * @param usr: { usuario: string, password: string}
     * @returns Observable boolean
     */
    validarUsuario(usr: { usuario: string, password: string}): Observable<{token: string}> {
        // Encriptar el password
        return of(usr).pipe(
            map(user => Object.assign({}, user, {password: MD5(SHA1(usr.password).toString()).toString()}) ),
            switchMap(user => this.$http.post(this.$headers.getAPIurl() + '/usuario/auth', user)),
            map(res => res.json() as IDBResponse),
            flatMap(response => {
                if (response.tipo === EDBResponseType.AUTH_OK) {
                    this.$localStorage.set('kerberus_ipbx_token', response.data as string);
                    return of({token: response.data as string});
                } else {
                    return undefined;
                }
            }),
            catchError((err: Response) => throwError(err.json()))
        );
    }
    
    /**
     * @returns Observable<string>
     */
    cerrarSesion(): Observable<string> {
        return this.$http.delete(this.$headers.getAPIurl() + '/usuario/auth', {headers: this.$headers.getHeaders()})
            .map(res => (res.json() as IDBResponse))
            .map(res => {
                if (res.tipo === EDBResponseType.OK) {
                    this.$localStorage.remove('kerberus_ipbx_token');
                }
                return res.data as string;
            });
    }
    
    getUsuarios(): Observable<Usuario[]> {
        return this.$http.get(this.$headers.getAPIurl() + '/usuarios', { headers: this.$headers.getHeaders() })
            .map(res => res.json())
            .catch((err: Response) => Observable.throw(err.json()));
    }
    
    actualizarUsuario(data: { usuario: Usuario; update: boolean}) {
        let http$: Observable<Response>;

        // Encriptar el password del usuario
        let usuario = Object.assign({}, data.usuario);
        if (usuario.password && usuario.password !== '') {
            usuario = Object.assign({}, usuario, {password: MD5(SHA1(usuario.password).toString()).toString()});
        } else if (usuario.password && usuario.password === '') {
            delete usuario.password;
        }

        if (data.update === undefined) {
            http$ = this.$http.delete(this.$headers.getAPIurl() + '/usuario/' + usuario.usuario, { headers: this.$headers.getHeaders() });
        } else if (data.update) {
            http$ = this.$http.post(this.$headers.getAPIurl() + '/usuario', usuario, { headers: this.$headers.getHeaders() });
        } else {
            http$ = this.$http.put(this.$headers.getAPIurl() + '/usuario', usuario, { headers: this.$headers.getHeaders() });
        }

        return http$.map(res => res.json())
            .catch((err: Response) => Observable.throw(err.json()));
    }
}
