export class Network {
    mac: string;
    ip: string;
    mascara: string;
    inet: string;
    
    constructor() {}
}

export class Iptables {
    ip: string;
    allow: boolean;
    descr: string;
    
    constructor() {}
}

export interface IRuta {
    ruta: string
}
