import {Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import {Subscription} from 'rxjs/Subscription';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';

import { AgenteService } from '../_services/agente.service';
import { SocketService, EComando, EResponse } from '../_services/socket.service';
import { QueueService } from '../_services/queue.service';
import { FormasService } from '../_services/formas.service';
import { IAgentMonitor, EestadoAgentMonitor, IChannel, IQueueMonitor } from '../_interfaces/monitor';
import {TipificacionComponent} from './tipificacion.component';
import {EDBResponseType, Queue, IForma} from '../_interfaces/_all';

import * as uuid from '../_services/_objectID';

import { flatMap, map, toArray, tap, filter, concat, finalize, first, startWith } from 'rxjs/operators';
import { from } from 'rxjs/observable/from';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { interval } from 'rxjs/observable/interval';
import { of } from 'rxjs/observable/of';

@Component({
    selector: 'agente-comp',
    templateUrl: './agente.component.html',
    styles: [`
        body {
            background-color: white
        }
        .container-nav
        {
            margin-right: 25px;
        }
        .li-stat
        {
            color: #dcdcdc;
            padding: 17px;
        }
    `]
})
export class AgenteComponent implements OnInit, OnDestroy {
    
    @ViewChild(TipificacionComponent)
    private tipificacion: TipificacionComponent;
    
    private agente$: Observable<IAgentMonitor>;
    private queue$: Observable<Queue[]>;
    private rooms: string[];
    
    private icono: string;
    private btn_color: string;
    private tiempo_pausa = 0;
    
    private total_atendidas: number;
    private tiempo_enLinea: number;
    
    private queuesSubscription: Subscription;
    private mensajeSubscription: Subscription;
    
    private formularios$: Observable < IForma[]>;
    
    private xfer: {destino: string, cod?: string, tipo: string, src?: string};
    
    constructor(private $route: ActivatedRoute, 
        private $router: Router, 
        private $monitor: SocketService, 
        private $agente: AgenteService,
        private $queue: QueueService,
        private $formas: FormasService) { }

    ngOnInit() { 

        // Notificaciones
        if (Notification['permission'] !== 'granted' && Notification['permission'] !== 'denied') {
            Notification.requestPermission();
        }

        // Colas disponibles para ser transferidas llamadas
        this.queue$ = this.$queue.getQueues()
            .flatMap(queues => queues)
            .filter(q => q.directo !== '')
            .toArray();

        // Login de rooms
        this.queuesSubscription = this.$route.data
            .pipe(
                first(),
                map((data: {agente: {idagente: string}}) => data.agente.idagente),
                concat(this.$queue.getQueues()),
                toArray(),
                // tap(d => console.log(d)),
                map(latest => {
                    const [idagente, queues] = latest as [string, Queue[]];
                    const enQueue = queues.filter(q => 
                        (q.agentes as {idagente: string}[]).findIndex(a => a.idagente === idagente) > -1 
                    );

                    // Logearse en los cuartos donde el agente este presente.
                    const rooms = enQueue.reduce((acc, curr) => [...acc, curr.nombre], []);
                    this.$monitor.setRooms( rooms );
                    return rooms;
                }),
                finalize(() => {
                    if (this.queuesSubscription && !this.queuesSubscription.closed) {
                        this.queuesSubscription.unsubscribe();
                    }
                })   
            ).subscribe(
                rooms => this.rooms = rooms,
                err => console.error(err)
            );
        
        // Manejador de mensajes
        if (Notification['permission'] === 'granted') {
            this.mensajeSubscription = this.$route.data.
                pipe(
                    map((data: {agente: {idagente: string}}) => data.agente.idagente),
                    flatMap(idAgente => this.$monitor.getMensajes(idAgente)),
                    tap(mensaje => console.log(mensaje)),
                    map(mensaje => {
                        const options = {
                            icon: '/public/img/icon-512x512.png',
                            body: mensaje.mensaje,
                            requireInteraction: true
                        };
                        const audio = new Audio('/public/sounds/sound4.ogg');
                        audio.play();

                        const not = new Notification('Mensaje de ' + mensaje.fuente, options);
                        
                    })
                        
                ).subscribe();
        }
        
        const agenteQueue$ = (idAgente: string, queues: IQueueMonitor[]): Observable<IAgentMonitor> => {
            return from(queues).pipe(
                map(queue => queue.agents.find(a => a.idagente === idAgente)),
                filter(agente => agente !== undefined),                
                toArray(),
                filter(data => data.length > 0),
                // agente
                map(dataAgente => {
                    const agente = dataAgente.shift();
                    
                    this.total_atendidas += agente.stats.reduce((acc, curr) => acc += curr.completeAgente + curr.completeUsuario, 0);
                    this.tiempo_enLinea += agente.stats.reduce((acc, curr) => acc += curr.completeTiempo, 0);

                    // TODO reduce a la otra parte del agente.
                    switch (EestadoAgentMonitor[agente.estado]) {
                        case EestadoAgentMonitor.INBOUND_IDLE:
                            this.icono = 'fa-user-circle-o';
                            this.btn_color = 'btn-success';
                            break;
                        case EestadoAgentMonitor.OFFLINE:
                            this.icono = 'fa-user-circle-o';
                            this.btn_color = 'btn-default';
                            break;
                        case EestadoAgentMonitor.OUTBOUND_IDLE: {
                            this.icono = 'fa-user-circle-o';
                            this.btn_color = 'btn-info';

                            const fechaAgente = Number.parseInt(agente.fecha);
                            if (!!agente.last_call_date && !(agente.last_call_date instanceof Date)) {
                                agente.last_call_date = moment(agente.last_call_date).toDate();
                            } else if (!agente.last_call_date) {
                                agente.last_call_date = new Date();
                            }
                            this.tiempo_pausa = (fechaAgente - agente.last_call_date.getTime()) / 1000;

                            } break;
                        case EestadoAgentMonitor.INBOUND_BUSY:
                            this.icono = 'fa-user-circle-o';
                            this.btn_color = 'btn-danger';
                            break;
                        case EestadoAgentMonitor.INBOUND_BUSY_PAUSE:
                            this.icono = 'fa-clock-o';
                            this.btn_color = 'btn-danger';
                            break;
                        case EestadoAgentMonitor.OUTBOUND_BUSY:
                            this.icono = 'fa-user-circle-o';
                            this.btn_color = 'btn-primary';
                            break;
                        case EestadoAgentMonitor.PAUSE:
                            this.icono = 'fa-clock-o';
                            this.btn_color = 'btn-warning';

                            const _estado     = (!(agente.estado_fecha instanceof Date)) ? moment(agente.estado_fecha).toDate() : agente.estado_fecha;
                            const _lastCall   = (!!agente.last_call_date && !(agente.last_call_date instanceof Date)) ? moment(agente.last_call_date).toDate() : _estado;

                            const currentTime = Number.parseInt(agente.fecha);
                            if (_lastCall.getTime() > _estado.getTime()) { // Tomar el tiempo de 
                                this.tiempo_pausa = (currentTime - _lastCall.getTime()) / 1000;
                            } else {
                                this.tiempo_pausa = (currentTime - _estado.getTime()) / 1000;
                            }
                            break;
                        default:
                            this.icono = 'fa-user-circle-o';
                            this.btn_color = 'btn-default';
                            break;
                    }

                    return agente;
                })
            );
        };

        this.agente$ = this.$route.data
            .map((data: {agente: {idagente: string}}) => data.agente)
            .do(agente => console.log('Bienvenido Agente ', agente.idagente))
            .flatMap(agente => combineLatest(
                of(agente.idagente),
                this.$monitor.getQueues()
            )).pipe(
                tap(() => {
                    this.total_atendidas    = 0;
                    this.tiempo_enLinea     = 0;
                }),
                flatMap(latest => {
                    const [idagente, queues] = latest as [string, IQueueMonitor[]];
                    return agenteQueue$(idagente, queues);
                }),
                map(agente => {
                    const canales = this.$monitor.getChannels();

                    // Canal del agente - INICIO

                    // MODO INBOUND con llamada inbound
                    agente.agentChannel = canales.find(c => 
                        (c.Context === 'agent' || c.Context === 'agentCallback') && 
                        c.Channel.indexOf('/' + agente.idagente + '@agent') > -1 && 
                        c.Bridged !== '' &&
                        c.Application === 'AppQueue'
                    );

                    // MODO INBOUND con llamada transferida
                    if (!agente.agentChannel) {
                        agente.agentChannel = canales.find(c => 
                            c.Channel.indexOf('/' + agente.idagente + '@agent') > -1 && 
                            c.Bridged !== '' &&
                            c.Application === 'AppDial'
                        );
                    }

                    // Modo INBOUND pero con llamada saliente
                    if (!agente.agentChannel) {
                        agente.agentChannel = canales.find(c => 
                            (c.Context === 'agent' || c.Context === 'agentCallback') && 
                            c.Channel.indexOf('/' + agente.idagente + '@agent') > -1 && 
                            c.Application === 'AgentRequest' 
                        );
                    }

                    // Buscar Llamadas salientes en OUTBOUND o RINGBACK
                    if (!agente.agentChannel) { 
                        agente.agentChannel = canales.find(c => 
                            c.Channel.indexOf('/' + agente.logged_on + '-') > -1 && 
                            c.Application === 'Dial'
                        );
                    }

                    // Canal del agente - FIN

                    if (agente.agentChannel) {
                        switch (agente.agentChannel.Application) {
                            case 'AppQueue' : {
                                agente.channel = canales.find(c => 
                                    c.Bridged === agente.agentChannel.Bridged && 
                                    c.Application === 'Queue'
                                );
                                break;
                            }
                            case 'AppDial' : {
                                const canal = canales.find(c => 
                                    c.Bridged === agente.agentChannel.Bridged && 
                                    c.Application === 'Dial'
                                );
                                agente.channel = canal ? Object.assign({}, canal, {UniqueID: canal.peerAccount}) : undefined;
                                break;
                            }
                            case 'AgentRequest': { // Llamadas salientes de API o Transferidas
                                const canal = canales.find(c => 
                                    c.Channel.indexOf('/' + agente.idagente + '@') > -1 && 
                                    c.Bridged !== agente.agentChannel.Bridged
                                    // c.Application === 'Dial'
                                );
                                agente.channel = canal ? Object.assign({}, canal, {Name: '(N/A)', CallerID: canal.Exten}) : undefined;
                                break;
                            }
                            case 'Dial': {
                                agente.channel = Object.assign({}, agente.agentChannel, { Name: '(N/A)', CallerID: agente.agentChannel.Exten });
                                if (agente.modoAgente === 'OUTBOUND') {
                                    this.icono = 'fa-user-circle-o';
                                    this.btn_color = 'btn-primary';
                                }
                                break;
                            }
                            default: break;
                        }
                    }

                    // console.log(agente);
                    return agente;
                })
            );
        
        this.formularios$ = interval(15000).pipe(
            startWith(-1),
            flatMap(() => this.$formas.getFormularios())
        );
    }
    
    ngOnDestroy() {
        this.queuesSubscription.unsubscribe();
        if (this.mensajeSubscription && !this.mensajeSubscription) {
            this.mensajeSubscription.unsubscribe();
        }
    }
    
    cerrarSesion() {
        this.$agente.cerrarSesion()
            .subscribe(
                () => {
                    console.log('out');
                    this.$router.navigateByUrl('/login');
                },
                err => console.warn(err)
            );
    }
    
    enviarMensaje(origen: IAgentMonitor, mensaje: string) {
        // Impedir mandar mensajes a @agente
        if (mensaje.indexOf('@') > -1 ) {
            const destino = mensaje.substring(mensaje.indexOf('@'));
            if (destino.length > 0) {
                if (isNaN(Number.parseInt(destino.substring(1, 2)))) {
                    this.$monitor.enviarMensaje({
                        fuente: '@' + origen.idagente + ' ' + origen.nombre,
                        mensaje: mensaje
                    });
                } else {
                    console.log('Impedir', destino.substring(1, 2));
                }
            }
        }
    }
    
    transferirLlamada(xfer: {destino: string, cod?: string}) {
        
        let tipo = 'Cola de Callcenter';
        if (xfer.destino === '**') {
            tipo = 'Agente';
        } else if (xfer.destino === '***') {
            tipo = 'Extensión';
        }
            
        this.xfer = {
            destino: xfer.destino,
            cod: xfer.cod,
            tipo: tipo
        };
        $('#xferConfirm').modal();
    }
    
    ejecutarXfer(agente: IAgentMonitor) {
        const args = this.xfer.destino + this.xfer.cod;
        const xferChannel = agente.agentChannel.Application === 'AppQueue' ? 
                            agente.agentChannel : agente.channel;
                            
        this.$monitor.enviarComando({
            comando: EComando.XFER,
            data: {
                channel: xferChannel.Channel,
                destino: args, src: agente.idagente}
        })
        .subscribe(
            res => {
                console.log(res);
                $('#xferConfirm').modal('hide');
            },
            err => console.warn(err)
        );
    }
    
    Pausa(agente: IAgentMonitor, motivo: string, modo: string) {
        
        const idreason = uuid.ObjectID();
        
        this.$agente.setReason({
            idreason: idreason,
            tipo: 'PAUSA POR AGENTE',
            motivo: motivo, 
            autor: 'AGENTE ' + agente.idagente
        })
        .flatMap(response => {
            if (response.tipo === EDBResponseType.QUERY_OK) {
                // let data = {agente: this.agente.idagente, modo: modo, modoAgente: this.agente.modoAgente, cola: this.agente.stats[0].cola, reason: idreason };
                // Pausa en cada cola
                return Observable.from(this.rooms)
                    .map(queue => {
                        return { agente: agente.idagente, modo: modo, modoAgente: agente.modoAgente, cola: queue, reason: idreason };
                    })
                    .flatMap(data => this.$monitor.enviarComando({
                            comando: EComando.AGENT_PAUSE,
                            data: data
                    }));
            } else {
                const res = new Subject<{ tipo: EResponse, data: Object}>();
                res.next({
                    tipo: EResponse.FALLIDO,
                    data: response.data
                });
                return res.asObservable();
            }
        })
        .subscribe(
            response => {
                console.log(response);
                // $('#agente-ops').modal('hide');
            }, err => console.log(err)
        );
    }
    
    cargarFormulario(forma: IForma, canal: IChannel) {
        this.tipificacion.lanzarForma(forma, canal);
    }
} 
