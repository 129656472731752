import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({name: 'toTimeFormat'})
export class TimeFormatPipe implements PipeTransform {

    transform(value: string, format?: string) {
        if (value !== undefined) {
            try {
                value = moment(value).format((format || 'lll'));
            } catch (e) {}
        }
        return value;
    }
}
