export class Usuario {
    usuario: string;
    password: string;
    estado = EEstadoUsuario.ACTIVO;    
    email: string;
    permisos = EUsuarioRol.SUPER;
    //
    manager: QueueManager[] = [];
    
    constructor() {}    
}

export class QueueManager {    
    usuario: string;
    cola: string;
}

export class UsuarioRol {
    usuario: string;
    rol: EUsuarioRol
}

export enum EEstadoUsuario {
    INACTIVO, ACTIVO
}

export enum EUsuarioRol {
    ADMIN, MANAGER, SUPER, AUDITOR, GUEST
}
