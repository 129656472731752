import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Grupo, Miembro } from '../../_interfaces/grupo';
import * as _ from 'lodash';

@Component({
    selector: 'grupo-tag',
    templateUrl: 'grupo.component.html'
})
export class GrupoComponent implements OnInit {
    
    @Input() edicion: boolean;     // undefined => nuevo, true 
    @Input() grupo: Grupo;
    @Output() grupoEmitter = new EventEmitter<{ grupo: Grupo, update: boolean }>();
    @Output() editar = new EventEmitter<number>();
    
    constructor() { }

    ngOnInit() { }
    
    removerMiembro(usuario: string) {
        const miembros: Miembro[] = <Miembro[]>this.grupo.miembros;
        const index = _.findIndex(miembros, ['exten', usuario]);
        miembros.splice(index, 1);
    }
    
    guardar(grupo: Grupo) {
        this.grupoEmitter.emit({
            grupo: grupo, 
            update: this.edicion === undefined ? false : true
        });
    }
    
    eliminarGrupo(grupo: Grupo) {
        this.grupoEmitter.emit({
            grupo: grupo, 
            update: undefined
        });
    }
    
    seleccion(idgrupo: number) {
        this.editar.emit(idgrupo);
    }
}
