import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'ui-radio',
    templateUrl: 'radio.component.html'
})
export class RadioComponent implements OnInit {
    
    private _opciones: string[];
    @Input() set opciones(o: string[]) {
        this._opciones = (o || []);
    }
    get opciones() { return this._opciones }
    
    private _value: string;
    @Input() set value(v) {
        this._value = (v || '');
    }
    get value() { return this._value }
    
    constructor() { }

    ngOnInit() { }
    
    
} 