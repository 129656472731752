import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VpnTableComponent } from './vpn-table/vpn-table.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ExtensionMonitorComponent } from './extension/extension-monitor.component';
import { TroncalMonitorComponent } from './troncal-monitor/troncal-monitor.component';
import { PeerComponent } from './peer/peer.component';
import { VPNService } from '../../_services/vpn.service';
import { PipesModule } from '../../pipes/pipes.module';
import { UIModule } from '../../_extra/ui/ui.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule, 
        ReactiveFormsModule,
        PipesModule,
        UIModule
    ],
    providers: [VPNService],
    declarations: [ VpnTableComponent, ExtensionMonitorComponent, TroncalMonitorComponent, PeerComponent ],
    exports: [ VpnTableComponent, ExtensionMonitorComponent, TroncalMonitorComponent, PeerComponent ]
})
export class ConsolaExtModule { }
