import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReglaCallbackComponent } from './regla-callback/regla-callback.component';
import { UIModule } from '../../_extra/ui/ui.module';
import { CallbackService } from '../../_services/callback.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UIModule
    ],
    providers: [CallbackService],
    declarations: [ReglaCallbackComponent],
    exports: [ReglaCallbackComponent]
})
export class CallbackModule { }
