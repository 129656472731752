export interface ICallBack {
    readonly id?: string;
    AMD: boolean;
    prefix: string | string [];
    nombreTroncal: string;
    prepend: number;
    valor: string;
    operacion: string;
}

export interface ICallbackRequest {
    id: string;
    numero: string;
    extra?: Object;
    fecha?: string;
}

export interface ILocalCallback {
    destino: string;
    origen: string;
    extra: Object;
}

export enum EOpcionesCallback {
    ENVIO_A_DID = 'Goto(Entrante,%g,1)',
    MARCAR_A_EXTENSION = 'Gosub(marcacionInterna,s,1(%g,%d))', // 'Macro(marcacionInterna,%g,%d)'
    GRUPO_EXTENSIONES = 'Gosub(marcacionGrupo,s,1(${G_%g},%d))',
    COLA_CALLCENTER =  'Gosub(Queues,${EXTEN},1(%g))'    // %g = queuename,prio
}
