import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import {OnChanges, SimpleChanges, SimpleChange, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import {Observable} from 'rxjs/Observable';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Subscription } from 'rxjs/Subscription';
import { SocketService, EComando } from '../../_services/socket.service';
import { QueueService, IPerformance } from '../../_services/queue.service';
import { UsuarioService, } from '../../_services/usuario.service';
import { EUsuarioRol } from '../../_interfaces/usuario';
import { IQueueMonitor, IChannel, IAgentMonitor, EestadoAgentMonitor } from '../../_interfaces/monitor';
import { Queue } from '../../_interfaces/queue';

import { AuxiliaryComponent } from './auxiliary/auxiliary.component';
import { FileHandlerService } from '../../_services/file.service';
import { from } from 'rxjs/observable/from';
import { map, reduce, tap } from 'rxjs/operators';

interface ICallback {
    queuename: string, callid: string, phone: string, id: string
}

@Component({
    selector: 'callcenter',
    styleUrls: ['./consola_call.component.css'],
    templateUrl: './consola_call.component.html'
})
export class ConsolaCallcenterComponent implements OnInit, OnChanges, OnDestroy {
    
    @Input() nombre: string;
    @Output() queueDefault = new EventEmitter<string>();
    @Output() menuOptions = new EventEmitter<string[]>();
    @Output() menuStats = new EventEmitter<{nombre: string, valor: string}[]>();
    
    private _rol = EUsuarioRol;
    private filtro: string;
    private channels: IChannel[];
    private queue: IQueueMonitor;
    
    private queues: IQueueMonitor[];
    private noQueue: {nombre: string}[] = [];
    
    private queueEdicion: Queue;
    private readOnly: boolean;
    private toOpAgent: IAgentMonitor;
    private logs: IPerformance;
    
    private queues$: Subscription;
    private mensajes$: Subscription;
    private queueAlerts$: Subscription;
    private callbacksState$: Observable<ICallback[]>
    
    private historial: {event: string, time: string, src: string, dst: string, queuename: string}
    
    private agent: {
        enlinea: number,
        ocupado: number,   
        enpausa: number
    }
    
    @ViewChild(AuxiliaryComponent)
    private agentOpsComponent: AuxiliaryComponent;
    
    constructor(private $monitor: SocketService, private $queue: QueueService, private $usuario: UsuarioService, private $file: FileHandlerService) { 
        console.log('iniciando monitor');
        
        if (!!this.queues$ && !this.queues$.closed) {
            this.queues$.unsubscribe();
        }
        
        this.queues$ = this.$monitor.getQueues()
            .map(queues => {
                const menu = <string[]>[];
                queues.forEach(queue => {
                    // Iniciar la primer cola.
                    if (!this.filtro || this.filtro === '') {
                        // En caso de ser supervisor, ubicar una cola que tenga permisos
                        if (this.$usuario.usuario.permisos >= this._rol.SUPER && _.findIndex(this.$usuario.usuario.manager, ['cola', queue.nombre]) > -1 ) {
                            this.queueDefault.emit(queue.nombre);
                        }
                        
//                        else if (this.$usuario.usuario.permisos <= this._rol.MANAGER)
//                            this.queueDefault.emit(queue.nombre);
                    }
                    menu.push(queue.nombre);
                });
                this.menuOptions.emit(menu);
                
                // Cargar las conf de las graficas
                if (!this.filtro || this.filtro === '') {
                    this.queues = _.cloneDeep(queues);
                    this.noQueue.forEach(q => {
                        const index = this.queues.findIndex(q1 => q1.nombre === q.nombre);
                        this.queues.splice(index, 1);
                    })
                }
                
                return queues;
            })
            .flatMap(queues => Observable.from(queues))
            // Filtar solo la cola 
            .filter(queue => queue.nombre === this.filtro)            
            .map(queue => {
                // let _date = moment(0).add(-19, 'hours');
                const fechaConHoraCero = moment().set('hours', 0).set('minutes', 0).set('seconds', 0);
                if (!!queue) {
                    // Sort de agentes
                    queue.agents = _.sortBy(queue.agents, ['idagente']);
                    
                    const menuStats: {nombre: string, valor: string}[] = [
                        {
                            nombre: 'Nivel de Servicio',
                            valor: queue.stats.serviceLevel + '%'
                        },
                        {
                            nombre: 'Recibidas',
                            valor: queue.stats.answered
                        },
                        {
                            nombre: 'Abandonos',
                            valor: queue.stats.abandon
                        },
                        {
                            nombre: 'Prom. en Línea',
                            valor: _.cloneDeep(fechaConHoraCero).add(queue.stats.talktime, 'seconds').format('mm:ss')
                        },
                        {
                            nombre: 'Prom. Espera',
                            valor: _.cloneDeep(fechaConHoraCero).add(queue.stats.holdtime, 'seconds').format('mm:ss')
                        }
                    ];
                    this.menuStats.emit(menuStats);
                    
                    this.agent = {
                        ocupado: _.filter(queue.agents, a => {
                            return (a.estado.indexOf('BUSY') > -1)
                        }).length,
                        enpausa: _.filter(queue.agents, a => {
                            return (a.estado.indexOf('PAUSE') > -1)
                        }).length,
                        enlinea: _.filter(queue.agents, a => {
                            return (a.estado.indexOf('OFFLINE') === -1 && a.estado.indexOf('PAUSE') === -1)
                        }).length
                    }
                }                
                return queue;
            })
            .subscribe(
                queue => {                
                    if (!!queue) {
                        this.queue = Object.assign({}, queue, { 
                            agents: queue.agents.filter(a => EestadoAgentMonitor[a.estado] !== EestadoAgentMonitor.OFFLINE).concat(queue.agents.filter(a => EestadoAgentMonitor[a.estado] === EestadoAgentMonitor.OFFLINE)) 
                        });
                        this.channels = this.$monitor.getChannels();
                                                
                        // Callers
                        if (this.queue.callers.length > 0) {
                            this.queue.callers.forEach(caller => {
                                caller.posicion = Number.parseInt(caller.posicion as string);
                                caller.canal = this.channels.find(c => c.Channel === caller.canal)
                                // caller.canal = _.find(this.channels, ['Channel', caller.canal]);
                            });
                        }

                        // Ordenar las llamadas.
                        this.queue.callers = _.orderBy(this.queue.callers, 'posicion');
                    }
                }, 
                err => console.warn(err)
            );
    }

    ngOnChanges(cambio: IChange) {
        if (!!cambio.nombre) {
            this.filtro = cambio.nombre.currentValue;
        }
    }

    ngOnDestroy() {
        this.queues$.unsubscribe();
        this.mensajes$.unsubscribe();
        this.queueAlerts$.unsubscribe();
    }

    ngOnInit() {         

        // Notificaciones
        if (Notification['permission'] !== 'granted' && Notification['permission'] !== 'denied') {
            Notification.requestPermission()
        }
        
        // Logueo de manager // super en colas para mensajes
        const rooms: string[] = ['NONE'];
        this.$usuario.usuario.manager.forEach(queue => {
            rooms.push(queue.cola);
        });
        this.$monitor.setRooms(rooms);
        
        // Manejador de mensajes
        if (!!this.mensajes$ && !this.mensajes$.closed) {
            this.mensajes$.unsubscribe();
        }
        
        this.mensajes$ = this.$monitor.getMensajes(this.$usuario.usuario.usuario)
            // Filtrar mensajes
            .subscribe(
                mensaje => {
                    console.log(mensaje)
                    const options = {
                        icon: '/public/img/icon-512x512.png',
                        body: mensaje.mensaje,
                        requireInteraction: true
                    };
                    const audio = new Audio('/public/sounds/sound4.ogg');
                    audio.play();

                    const a = new Notification('Mensaje de ' + mensaje.fuente, options);
                },
                err => console.log(err)
            );
        
        // Manejador de alertas
        if (!!this.queueAlerts$ && !this.queueAlerts$.closed) {
            this.queueAlerts$.unsubscribe();
        }
        
        this.queueAlerts$ = this.$monitor.getQueueAlerts()
            .subscribe(mensaje => {
                // console.log(mensaje);
                let _mensaje = '';
                let _tipo = 'warning';
                switch (mensaje.evento) {
                    case 'ABANDON': {
                        _mensaje = 'Abandono de llamada despues de ' + mensaje.tiempo_espera + ' s de espera. Más info? click aquí.';
                    } break;
                    case 'AGENTLOGOFF' : {
                        _tipo = 'error';
                        if (!!this.queue && _.findLastIndex(this.queue.agents, ['idagente', mensaje.agente]) > -1) {
                            _mensaje = 'Agente ' + mensaje.agente + ' se há deslogueado.'
                        }
                    } break;
                    case 'COMPLETEAGENT' : {
                        _mensaje = 'El Agente ' + mensaje.agente + ' ha colgado la llamada.'
                    } break;
                    case 'RINGNOANSWER' : {
                        _tipo = 'error';
                        _mensaje = 'El Agente ' + mensaje.agente + ' no esta contestando llamadas.'
                    } break;
                }
                if (!!_mensaje && _mensaje.length > 0) {

                    const titulo = moment(mensaje.fecha).format('MMM DD HH:mm').toUpperCase() + ' Alerta' + (mensaje.cola !== 'NONE' ? ' en ' + mensaje.cola : '');
                    const options = {
                        icon: '/public/img/icon-512x512.png',
                        body: _mensaje,
                        requireInteraction: true
                    };
                    const audio = new Audio('/public/sounds/sound4.ogg');
                    audio.play();
                    
                    const not = new Notification(titulo, options);
                    if (mensaje.evento === 'ABANDON') {
                        not.onclick = () => this.getLlamada(mensaje.uniqueid); 
                    }
                }
            });
        
        this.callbacksState$ = this.$monitor.getQueueCallbacks()
            .map((callbacks: ICallback[]) => callbacks.filter(c => c.queuename === this.filtro));            
    }
    
    noQueueFn(nombre: string) {
        this.noQueue.push({
            nombre: nombre
        });
    }
    
    getLlamada(uniqueid: string): void {
        this.$queue.getCallHistory(uniqueid)
            .subscribe(
                res => {
                    if (!!res && res.length > 0) {
                        this.historial = <{event: string, time: string, src: string, dst: string, queuename: string}>res[0];
                        this.historial.time = moment(this.historial.time).format('LLL');

                        $('#callHistoryModal').modal();
                    }
                },
                err => console.warn(err)
            )
    }
    
    lanzarLlamada(call: ICallback) {
        let src = window.location.hostname;
        if (src.indexOf(':') > -1) {
            src = src.substring(0, src.lastIndexOf(':') - 1)
        }
        this.$queue.devolverLlamada(call, window.location.protocol + '//' + src)
            .subscribe(res => console.log(res));
    }
    
    dismiss(call: ICallback) {
        this.$queue.cancelarLlamada(call)
            .subscribe(res => console.log(res));
    }
    
    enviarMensaje(mensaje: string) {
        this.$monitor.enviarMensaje({
            fuente: '@' + this.$usuario.usuario.usuario,
            mensaje: mensaje
        });
    }
    
    crearQueue() {
        this.queueEdicion = new Queue();
        this.readOnly = false;
        $('#queueModal').modal();
    }
    
    editarQueue(queueMonitored: IQueueMonitor) {
        this.$queue.getQueue(queueMonitored.nombre)
            .subscribe(queue => {
                if (!!queue && queue.nombre) {
                    this.queueEdicion = queue;
                    this.readOnly = true;
                    $('#queueModal').modal();
                }
            }, 
            err => console.log(err))
    }
    
    /**
     * @param data {queue, update}
     * update = true Actualiza
     * update = false Crea un queue
     * update = undefined elimina
     */
    guardarCola(data: {queue: Queue, update: boolean}) {
        this.$queue.modificarQueue(data)
            .flatMap(() => this.$monitor.enviarComando({
                comando: EComando.KERBERUS_FILE,
                data: {tipo: 'QUEUES'}
            }))
            .map(() => (data.update !== true) ? EComando.QUEUE_RELOAD : EComando.QUEUE_PARAMETERS)
            .flatMap(tipoComando => this.$monitor.enviarComando({
                        comando: tipoComando,
                        data: { queue: data.queue.nombre }
            }))
            .subscribe(
                res => {
                    console.log(res)
                    $('#queueModal').modal('hide');
                    if (data.update === undefined) {
                        this.filtro = undefined;
                    }
                },
                err => console.log(err)
            );
    }
    
    reiniciarSts(nombre: string) {
        this.$monitor.enviarComando({
                comando: EComando.RESET_STATS,
                data: { queue: nombre }
            })
            .subscribe(
                res => console.log(res),
                err => console.log(err)
            );
    }
    
    cargarOPS(agente: IAgentMonitor) {
        this.toOpAgent = agente;
        this.agentOpsComponent.lanzarOps();
    }
    
    performance(fecha: string) {
        $('#modalWait_kerberus').modal();
        this.$queue.getPerformace(this.nombre, fecha)
            .subscribe(
                data => {
                    $('#modalWait_kerberus').modal('hide');
                    if (!!data) {
                        console.log(data)
                        this.logs = data;
                    }
                },
                err => console.warn(err)
            )
    }

    descargarCSV() {
        const fecha = moment().set('hours', 0).set('minutes', 0).set('seconds', 0);
        from(this.logs.agente).pipe(
            map(agente => {
                const datos: Object[] = []
                agente.llamadas.forEach((llamada, idx) => {
                    datos.push({
                        Hora: this.logs.hora[idx],
                        Agente: agente.name,
                        Llamadas: llamada.cant,
                        'Promedio en Linea': _.cloneDeep(fecha).add(llamada.tiempo, 'seconds').format('HH:mm:ss')
                    })
                });
                return datos;
            }),
            reduce((acc, curr) => [...acc, ...curr], []),
            map(data => _.sortBy(data, ['Hora'])),
            tap(data => this.$file.exportCSVFile(Object.keys(data[0]), data, 'Rendimiento_' + new Date().getTime())),
        ).subscribe()
    }
    
} 

interface IChange extends SimpleChanges {
    nombre: SimpleChange
}
