export interface IForma {
    id?: string,
    titulo: string,
    descripcion: string,
    fecha: Date | string,
    colas: string | string[],
    
    pregunta?: IFormaPregunta[]
}

export interface IFormaPregunta {
    orden: number,
    idformulario: string,
    tipo: string,
    pregunta: string,
    respuestas: {
        tipo: string,
        respuesta: string
    }[] | string 
}

export enum ETipoRespuesta {
    SIMPLE = <any>'SIMPLE',
    OTRO = <any>'OTRO'
}

export interface ITipificacionLog {
    callid: string,
    idtipificacion: string,
    idformulario: string,
    fecha: Date,
    autor: string,
    pregunta: string
    respuesta: string,
    tipo: string,
    
    opciones?: string[]
}

export interface IResultado {
    callid: string,
    idtipificacion: string
    fecha: string,
    autor: string,
    tipo: string,
    pregunta: string
    respuesta: string,
    queuename: string,
    src
}

export interface IResultadosQuery {
   fecha?: {
        inicio: string, 
        fin: string
    },
   idformulario: string,
   tipoQuery: EQueryType
}

export enum EQueryType {
    LIST, CSV
}
