import { NgModule } from '@angular/core';
import { DialerNumbersPipe } from './pipes/dialerNumbers.pipe';
import { DialerService } from '../../_services/dialer.service';

@NgModule({
    imports: [],
    exports: [DialerNumbersPipe],
    declarations: [DialerNumbersPipe],
    providers: [DialerService],
})
export class DialerModule { }
