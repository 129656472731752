import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'stringReplace'})
export class StringReplacePipe implements PipeTransform {

    transform(value: string, needle: string, replace: string) {
        if (value !== undefined) {
            try {
                value = value.split(needle).join(replace);
            } catch (e) {}
        }
        return value;
    }
}
