import { Component, OnInit} from '@angular/core';
import { IVRService } from '../../_services/ivr.service';
import { SocketService, EComando } from '../../_services/socket.service';
import { IVR } from '../../_interfaces/ivr';
import { Observable } from 'rxjs/Observable';

@Component({
    selector: 'ivr-tag',
    templateUrl: 'ivr.component.html'
})
export class IvrComponent implements OnInit {
    
    private readOnly = false;
    private ivrEdicion: IVR;
    private ivrs$: Observable<IVR[]>;
    
    constructor(private $ivr: IVRService, private $monitor: SocketService) { 
        this.crearNuevoDID();
    }

    ngOnInit() { 
        this.ivrs$ = this.$ivr.getDids();
    }
    
    crearNuevoDID() {
        this.readOnly = false;
        this.ivrEdicion = new IVR();
    }
    
    cargarDid(did: string) {
        this.$ivr.getDid(did)
            .subscribe(ivr => {
                this.readOnly = true;
                this.ivrEdicion = ivr;
            },
            err => console.log(err)
        );
    }
    
    guardarIVR(ivrData: {ivr: IVR, update: boolean}) {
        $('#modalWait_kerberus').modal();
        this.$ivr.actualizarIVR(ivrData)
            .flatMap(() => this.$monitor.enviarComando({
                comando: EComando.KERBERUS_FILE,
                data: { tipo: 'IVR_EXTRA' }
            }))
            .subscribe(
                res => {
                    $('#modalWait_kerberus').modal('hide');
                    this.ngOnInit();
                    console.log(res);
                }
            )
    }
} 
