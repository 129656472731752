import { Injectable } from '@angular/core';
import { Http } from '@angular/http'
import { Observable } from 'rxjs/Observable';
import { HeaderService } from './header.service';

import { of } from 'rxjs/observable/of';
import { switchMap, flatMap, tap, map } from 'rxjs/operators';

export interface IEncuentaFrm {
    id: string, 
    nombre: string, 
    descripcion: string, 
    ext_directa: string
    preguntas?: IPreguntaEncuesta[]
}

export interface IPreguntaEncuesta {
    pregunta: string, 
    audio: string
    orden?: number,
    minimo: number,    
    maximo: number,    
}

export interface IPollResults {id: number, uniqueid: string, pollid: string, cola: string, pregunta: string, respuesta: number, fecha: string, origen: string}

@Injectable()
export class PollsService {

    constructor(private $http: Http, private $headers: HeaderService) { }

    getEncuestas(): Observable<IEncuentaFrm[]> {
        return Observable.of('')
            .switchMap(() => this.$http.get(this.$headers.getAPIurl() + '/polls', { headers: this.$headers.getHeaders() }))
            .map(res => res.json() as IEncuentaFrm[])
            .catch((err: Response) => Observable.throw(err.json()));
    }

    getEncuesta(encuesta: IEncuentaFrm): Observable<IEncuentaFrm> {
        return Observable.of(encuesta.id)
            .switchMap(id => this.$http.get(this.$headers.getAPIurl() + '/polls/' + id, { headers: this.$headers.getHeaders() }))
            .map(res => res.json() as IPreguntaEncuesta[])
            .map(preguntas => Object.assign({}, encuesta, {preguntas: preguntas}))
            .catch((err: Response) => Observable.throw(err.json()));
    }
        
    guardarEncuenta(encuesta: IEncuentaFrm): Observable<boolean> {        
        return Observable.of(encuesta)
            .map(poll => {
                poll.preguntas.forEach((pregunta, indx) => {
                    pregunta.orden = indx;
                    const [archivo, ext] = pregunta.audio.split('.');
                    if (!!ext) {
                        pregunta.audio = archivo
                    }
                })
                return poll;
            })
            .switchMap(poll => this.$http.post(this.$headers.getAPIurl() + '/polls', poll, { headers: this.$headers.getHeaders() }))
            .map(res => res.status === 200)
    }
    
    eliminarEncuesta(id: string): Observable<boolean> {
        return Observable.of(id)
        .switchMap(idEncuesta => this.$http.delete( this.$headers.getAPIurl() + '/polls/' + idEncuesta, { headers: this.$headers.getHeaders() } ))
        .map(res => res.status === 200)
        .catch((err: Response) => Observable.throw(err.json()));
    }

    cargarResultados(queryFrm: object): Observable<Array<IPollResults[]>> {
        return of(queryFrm).pipe(
            switchMap(query => this.$http.post(this.$headers.getAPIurl() + '/polls/results', query, { headers: this.$headers.getHeaders() }) ),
            map(data => data.json())
        )
        .catch((err: Response) => Observable.throw(err.json()))
    }

    audioPollExiste(src: string): Observable<boolean> {
        return of(src).pipe(
            switchMap(query => this.$http.get(this.$headers.getAPIurl() + '/polls/audioExists/' + src, { headers: this.$headers.getHeaders() }) ),
            map(data => data.status === 200)
        )
        .catch((err: Response) => Observable.throw(err.json()))
    }
}
