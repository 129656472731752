import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'wait-tag',
    template: `
    <div class="modal fade" tabindex="-1" role="dialog" id="modalWait_kerberus">
        <div class="modal-dialog modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-body text-center">
                <img src="ajax-loader.gif" alt="">
                <span *ngIf="!!text"><br>{{text}}</span>
            </div>
          </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->
    `
})
export class WaitLoaderComponent implements OnInit {
    
    private text: string = '';
    private modalStatus: boolean = false;
        
    constructor() { }

    ngOnInit() { }
    
    getStatus(): boolean {
        return this.modalStatus
    }
    
    close(): void {
        this.modalStatus= false;
        $('#modalWait_kerberus').modal('hide');
    }
    
    show(text?: string): void {
        this.text = text;
        this.modalStatus = true;
        $('#modalWait_kerberus').modal('hide');
    }
} 
