import { Component, OnInit } from '@angular/core';
import { Usuario, QueueManager, EUsuarioRol } from '../../_interfaces/usuario';
import { Queue } from '../../_interfaces/queue';

import { Observable } from 'rxjs/Observable';
import * as _ from 'lodash';
import { UsuarioService } from '../../_services/usuario.service';
import { QueueService } from '../../_services/queue.service';

@Component({
    selector: 'usuarios',
    templateUrl: 'usuario.component.html'
})
export class UsuarioComponent implements OnInit {
    
    private rol = EUsuarioRol;
    private readOnly: boolean;
    private usuario: Usuario;
    private usuarios$: Observable<Usuario[]>;
    private queues$: Observable<Queue[]>;
    
    constructor(private $usuario: UsuarioService, private $queue: QueueService) { 
        this.nuevoUsuario();
    }

    ngOnInit() { 
        this.usuarios$ = this.$usuario.getUsuarios();
        this.queues$ = this.$queue.getQueues();
    }
    
    nuevoUsuario() {
        this.readOnly = false;
        this.usuario = new Usuario();
    }
    
    cargarUsuario(usuario: Usuario) {
        this.readOnly = true;
        this.usuario = _.cloneDeep(usuario);
    }
    
    agregarCola(queueName: string) {
        if (queueName && queueName !== '') {
            let colasPresentes = _.cloneDeep(this.usuario.manager);
            // Buscarlo antes de agregarlo
            if (_.findIndex(colasPresentes, ['cola', queueName]) < 0) {
                colasPresentes.push({
                    usuario: this.usuario.usuario,
                    cola: queueName
                });
                colasPresentes = _.cloneDeep(_.sortBy(colasPresentes, ['idagente']));
            }            
            this.usuario.manager = colasPresentes;
        }
    }
    
    removerCola(queueName: string) {
        this.usuario.manager.splice(_.findIndex(this.usuario.manager, ['cola', queueName]), 1);
    }
    
    guardarUsuario() {
        this.usuario.usuario = this.usuario.usuario.split(' ').join('.');
        this.$usuario.actualizarUsuario({
            usuario: this.usuario,
            update: this.readOnly
        }).subscribe(
            res => {
                console.log(res);
                this.ngOnInit();
            },
            err => console.warn(err)
        );
    }
    
    eliminarUsuario() {
        this.$usuario.actualizarUsuario({
            usuario: this.usuario,
            update: undefined
        }).subscribe(
            res => {
                console.log(res);
                this.ngOnInit();
                this.nuevoUsuario();
            },
            err => console.warn(err)
        );
    }
} 
