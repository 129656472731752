import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpModule, JsonpModule } from '@angular/http';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { IPBXComponent } from './app.component';

// Root Components
import { LoginComponent } from './login/login.component';
import { AdminComponent } from './admin/admin.component';
import { AgenteComponent } from './agente/agente.component';
import { AgenteConsoleModule } from './agente/agente.module';

import { AdminModule } from './admin/admin.module';
import { ReportesModule } from './admin/reportes/reportes.module';
import { IvrModule } from './admin/ivr/ivr.module';

// Services
import { HeaderService } from './_services/header.service';
import { UsuarioService } from './_services/usuario.service';
import { UserResolver, AgentResolver } from './_services/resolvers';
import { FormasService } from './_services/formas.service';

import {LocalStorageModule} from 'angular-2-local-storage';

import * as moment from 'moment';
import * as dayjs from 'dayjs';
import 'moment/locale/es';
import 'dayjs/locale/es';

import { WaitLoaderComponent } from './_extra/wait/wait.component';
import { ConsolaCallcenterComponent } from './admin/consola_call/consola_call.component';
import { CallbackComponent } from './admin/callback/callback.component';

@NgModule({
    providers: [HeaderService, UsuarioService, UserResolver, AgentResolver, FormasService],
    imports: [BrowserModule, HttpModule, HttpClientModule, JsonpModule, HttpClientJsonpModule, FormsModule, ReactiveFormsModule, AgenteConsoleModule,
        RouterModule.forRoot([
            {
                path: '',
                component: LoginComponent
            },
            {
                path: 'login',
                component: LoginComponent
            },
            {
                path: 'admin',
                children: [
                    {
                        path: '',
                        component: AdminComponent,
                        resolve: {
                            usuario: UserResolver
                        }
                    },
                    {
                        path: 'callcenter',
                        component: ConsolaCallcenterComponent,
                        resolve : {
                            usuario: UserResolver
                        }
                    },
                    {
                        path: 'callback',
                        component: CallbackComponent,
                        resolve : {
                            usuario: UserResolver
                        }
                    }
                ]
            },
            {
                path: 'agente',
                component: AgenteComponent,
                resolve: {
                    agente: AgentResolver
                }
            }
        ]),
        LocalStorageModule.withConfig({
            prefix: 'kerberusipbx_query' ,
            storageType: 'sessionStorage'
        }),
        AdminModule,
        ReportesModule,
        IvrModule
    ],
    declarations: [
        WaitLoaderComponent,
        IPBXComponent, 
        LoginComponent, 
        AdminComponent
    ],
    bootstrap: [ IPBXComponent ]
})
export class AppModule { 
    constructor() {
        moment.locale('es');
        dayjs.locale('es');
    }
}
