export class IVR {
    did = '';
    nombre = '';
    hold = 'default';
    horario: Horario[] = [];
    secuencia: Secuencia[] = [];
    
    constructor() {}
}

export class Horario {
    id: number;
    did: string;
    dia: string;
    horario: string;
    
    dia1?: string;
    dia2?: string;
}

export class Secuencia {
    did: string;
    contexto: string;
    index: number;
    opcion_ivr = '';
    opcion: string | EOpcion;
    valor: string;
    operacion: string;
    
    // nested
    subMenu?: Secuencia[];
    
    constructor() {}
}

export enum EOpcion {
    EXTENSION = <any>'MARCAR A EXTENSIÓN', 
    GRUPO = <any>'MARCAR A GRUPO', 
    QUEUE = <any>'COLA DE CALLCENTER', 
    SUBIVR = <any>'SUB MENÚ', 
    BACKGROUND = <any>'AUDIO CON OPCIONES', 
    PLAYBACK = <any>'AUDIO DE NOTIFICACIÓN', 
    DID = <any>'A OTRO DID', 
    DATABASE = <any>'DATABASE',
    INTERX = <any>'INTERCONEXIÓN', 
    MARCACION_EXT = <any>'MARCACIÓN EXTERNA', 
    COLGAR = <any>'COLGAR LLAMADA' 
}
