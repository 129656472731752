import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { IEncuentaFrm, PollsService, IPollResults } from '../../../_services/polls.service';
import { FileHandlerService } from '../../../_services/file.service';

import { finalize, tap, switchMap, groupBy, flatMap,  map, concat, toArray, reduce, share } from 'rxjs/operators'
import { debounceTime } from 'rxjs/operator/debounceTime';
import { from } from 'rxjs/observable/from';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { combineLatest } from 'rxjs/observable/combineLatest';
import * as _ from 'lodash';
import * as moment from 'moment';

interface IPollQuery {idEncuesta: string, fecha: {inicio: string, fin?: string}}

interface IChanges extends SimpleChanges {
    encuesta: ISimpleChg
}

interface ISimpleChg extends SimpleChange {
    currentValue: IEncuentaFrm
    previousValue: IEncuentaFrm
}

@Component({
    selector: 'resultados-poll',
    templateUrl: 'resultados.component.html'
})

export class ResultadosComponent implements OnInit, OnChanges {

    @Input()
    encuesta: IEncuentaFrm;

    querying = false;
    private tabActivo = 0;
    private plotOptions: Highcharts.Options;
    private pollResults: Array<IPollResults[]>;
    private uiPollResults$: Observable<Array<{cola: string, plots: Highcharts.PlotOptions[]}>>;
    
    constructor(private $polls: PollsService, private $files: FileHandlerService) { }
    
    ngOnChanges(changes: IChanges): void {
        if (!!changes.encuesta) {
            const cambios = changes.encuesta

            // Es el primer cargue
            if (!!cambios.currentValue && !cambios.previousValue ||
                (!!cambios.currentValue && !!cambios.previousValue && cambios.currentValue.id !== cambios.previousValue.id)
            ) {
                // ejecutar una búsqueda con fechas de hoy
                this.ejecutarBusqueda({
                    idEncuesta: cambios.currentValue.id,
                    fecha: {
                        inicio: moment().format('YYYY-MM-DD')
                    }
                })
            }
        }
    }

    ngOnInit() { 
        this.plotOptions = {
                chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                width: 400,
                height: 230,
                backgroundColor: '#fcfcfc'
            },
            colors: [ '#109618', '#3366CC', '#FF9900', '#0099c6', '#DD4477', '#DB4437', '#4285F4', '#990099', '#00CCFF', '#33CC99', '#CC3300', '#666666' ],
            title: {
                text: '',
                align: 'left'
            },
            subtitle: {
                text: '',
                align: 'left'
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.y}</b> ({point.percentage}%)'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '{point.percentage:.1f} %: ({point.y})'
                    }
                }
            },
            series: [],
            credits: {
                enabled: false
            }
        }
    }

    ejecutarBusqueda(query: IPollQuery) {
        this.querying = true;
        this.uiPollResults$ = this.$polls.cargarResultados(query).pipe(
            tap(data => this.pollResults = data),
            flatMap(agrupadoPorCola => from(agrupadoPorCola).pipe(
                
                // Agrupar por pregunta
                switchMap(registros => from(registros)),
                groupBy(registro => registro.pregunta),
                flatMap(porPregunta => porPregunta.reduce((acc, curr) => [...acc, curr], [])),

                flatMap(grupoPregunta => this.crearDataPoint(grupoPregunta)),
                // Crear data points
                map(dataPoints => {
                    const highChatPlot = _.cloneDeep(this.plotOptions);
                    
                    highChatPlot.title = {
                        text: dataPoints.titulo,
                        align: 'left'
                    }
                    highChatPlot.series.push({
                        name: 'Respuesta(s)',
                        data: dataPoints.dataPoints
                    })
                    
                    return {plot: highChatPlot, cola: dataPoints.cola};
                })
                
            )),

            // Agrupar los charts port cola
            groupBy( data => data.cola ),
            flatMap( grupo => grupo.reduce((acc, curr) => [...acc, curr], []) ),
            // agrupado por cola
            flatMap( agrpado => combineLatest(
                of(agrpado[0].cola), 
                // Reducir los plots de cada cola
                from(agrpado).pipe(
                    reduce((acc, curr: {plot, cola}) => [...acc, curr.plot], [])
                ))                    
            ),
            map(data => {
                const [cola, plots] = data;
                return { cola: cola, plots: plots }
            }),

            // Agrupar el vector de resultados [ [ plot1, plot2, plotn ], [], ... ]
            toArray(),

            // Agrupar plots por cola
            finalize(() => this.querying = false)
        )
            
    }

    private crearDataPoint(grupoPreguntas: IPollResults[]): Observable<{titulo: string, cola: string, dataPoints: {name: string, y: number}[]}> {        
        return from(grupoPreguntas).pipe(
            groupBy(registro => registro.respuesta),
            flatMap(grupo => grupo.reduce((acc, curr) => [...acc, curr], [])),
            map(grupoRespuesta => {
                return {
                    name: 'Respuestas con calif. ' + grupoRespuesta[0].respuesta,
                    y: grupoRespuesta.length
                }
            }),
            toArray(),
            // Ordernar de mayor calificacion a menor
            map(dataPoints => _.sortBy(dataPoints, 'name').reverse()),
            map(dataPoints => ({titulo: grupoPreguntas[0].pregunta, dataPoints: dataPoints, cola: grupoPreguntas[0].cola}))
        )
    }
    
    private exportarResultados(resultados: Array<IPollResults[]>) {

        const unoPorUno$ = from(resultados).pipe(
            flatMap(registroInterno => from(registroInterno)),
            share()
        );

        const headers$ = unoPorUno$.pipe(
            groupBy(registro => registro.pregunta), // Por pregunta
            flatMap(grupo => grupo.reduce((acc, curr) => [...acc, curr], [])), // agrupado por pregunta
            map(grupo => grupo[0].pregunta),
            toArray()
        )

        const registros$ = unoPorUno$.pipe(
            groupBy(registro => registro.uniqueid), // Por llamada
            flatMap(grupo => grupo.reduce((acc, curr) => [...acc, curr], [])), // agrupado por llamada,
            map(agrpado => {
                let preRegistro = {
                    Fecha: moment(agrpado[0].fecha).format('YYYY-MM-DD'),
                    Hora: moment(agrpado[0].fecha).format('HH:mm:ss'),
                    Origen: agrpado[0].origen,
                    Callid: agrpado[0].uniqueid,
                    Cola: agrpado[0].cola,
                }
                agrpado.forEach(registro => {
                    preRegistro = Object.assign(preRegistro, {
                        [registro.pregunta]: registro.respuesta
                    })
                })
                return preRegistro;
            }),
            toArray()
        )

        combineLatest(headers$, registros$).subscribe( data => {
            const [heads, registros] = data;
            console.log(heads, registros) 
            this.$files.exportCSVFile(
                ['Fecha', 'Hora', 'Origen', 'Callid', 'Cola', ...heads.sort()], 
                registros, 
                this.encuesta.nombre + '_' + (new Date()).getTime())
        })
    }
}
