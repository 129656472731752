import { NgModule } from '@angular/core';
import { FileUploadModule } from 'ng2-file-upload/ng2-file-upload';
import { PollComponent } from './poll/poll.component';
import { PreguntaComponent } from './pregunta/pregunta.component';
import { DragulaModule, DragulaService } from 'ng2-dragula';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ResultadosComponent } from './resultados/resultados.component';
import { ChartModule } from 'angular2-highcharts';
import { DatePickerModule } from '../../_extra/datepicker.module';
import { Ng2PaginationModule } from 'ng2-pagination';
import { FileHandlerService } from '../../_services/file.service';
import * as highcharts from 'highcharts';

@NgModule({
    imports: [
        CommonModule, 
        FormsModule, 
        ReactiveFormsModule, 
        FileUploadModule, 
        DragulaModule, 
        ChartModule.forRoot(highcharts), 
        DatePickerModule, 
        Ng2PaginationModule
    ],
    declarations: [PollComponent, PreguntaComponent, ResultadosComponent],
    exports: [PollComponent, PreguntaComponent, ResultadosComponent],
    providers: [FileHandlerService, DragulaService],
})
export class PollsModule { }
