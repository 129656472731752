import { Component, OnInit } from '@angular/core';
import { Troncal, TroncalPrefijo } from '../../_interfaces/troncal';
import * as _ from 'lodash';
import { Observable } from 'rxjs/Observable';

import { TroncalService } from '../../_services/troncal.service';
import { SocketService, EComando } from '../../_services/socket.service';
import { of } from 'rxjs';
import { flatMap, concat } from 'rxjs/operators';

@Component({
    selector: 'troncal-tag',
    templateUrl: 'troncal.component.html'
})
export class TroncalSetupComponent implements OnInit {

    private readOnly = false;
    private troncal: Troncal;
    private secretView = false;
    private troncal$: Observable<Troncal[]>;
    
    constructor(private $troncal: TroncalService, private $monitor: SocketService) { 
        this.nuevaTroncal();
    }

    ngOnInit() { 
        this.troncal$ = this.$troncal.getTroncales();
    }
    
    nuevaTroncal() {
        this.troncal = new Troncal();
        this.readOnly = false;
    }
    
    seleccionarTroncal(troncal: Troncal) {
        this.troncal = _.cloneDeep(troncal);
        this.readOnly = true;
    }
    
    presente(codec: string): boolean {
        const codecs: {codec: string}[] = <{codec: string}[]>this.troncal.codec;
        return (_.findIndex(codecs, ['codec', codec]) > -1 );
    }
    
    lanzarPrefijos() {
        $('#modalPrefijos').modal();
    }
    
    agregarPrefijo(prefijo: TroncalPrefijo) {
        this.troncal.prefijos.push(prefijo);
        $('#modalPrefijos').modal('hide');
    }
    
    eliminarPrefijo(index: number) {
        this.troncal.prefijos.splice(index, 1);
    }
    
    modCodec(codec: string, checked: boolean) {
        // console.log(codec, checked);
        const codecs: {codec: string}[] = <{codec: string}[]>this.troncal.codec;
        if (!!checked) {// agregar
            codecs.push({codec: codec });
        } else {
            codecs.splice(_.findIndex(codecs, ['codec', codec]), 1);
        }
    }
    
    guardarTroncal() {
        this.troncal.nombre = this.troncal.nombre.split(' ').join('_');
        this.troncal.usuario = this.troncal.usuario.split(' ').join('_');
        this.troncal.prefijos.forEach(prefijo => {
            prefijo.troncal = this.troncal.nombre;
        });
        
        $('#modalWait_kerberus').modal();
        of(this.troncal).pipe(
            flatMap(troncal => this.$troncal.actualizarTroncal({
                troncal: troncal,
                update: this.readOnly
            }).pipe(concat(this.$monitor.enviarComando({
                    comando: EComando.KERBERUS_FILE,
                    data: {tipo: troncal.protocolo.toUpperCase() + '_TRUNK'}
                })))
            )
        ).subscribe(
            () => {
                this.ngOnInit();
                $('#modalWait_kerberus').modal('hide');
            },
            err => console.error(err)
        );
    }
    
    eliminarTroncal() {
        $('#modalWait_kerberus').modal();
        this.$troncal.actualizarTroncal({
                troncal: this.troncal,
                update: undefined
            })
            .flatMap(() => this.$monitor.enviarComando({
                comando: EComando.KERBERUS_FILE,
                data: { tipo: this.troncal.protocolo.toUpperCase() + '_TRUNK'}
            }))
            .subscribe(res => {
                    console.log(res);
                    $('#modalWait_kerberus').modal('hide');
                    this.ngOnInit();
                    this.nuevaTroncal();
                },
                err => console.log(err)
            );
    }
} 
