import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';

import { Peer, PeerPerfil } from '../_interfaces/peer';
import { IPeerMonitor } from '../_interfaces/monitor';
import { HeaderService } from './header.service';
import { IDBResponse } from '../_interfaces/responses';

@Injectable()
export class PeerService {
    
    constructor(private $http: Http, private $headers: HeaderService) {
        
    }
    
    getPeer(usuario: string): Observable<Peer[]> {
        return this.$http.get(this.$headers.getAPIurl() + '/peer/' + usuario, { headers: this.$headers.getHeaders() })
            .map(res => res.json())
            .catch((err: Response) => Observable.throw(err.json()));
    }
    
    getPeers(): Observable<Peer[]> {
        return this.$http.get(this.$headers.getAPIurl() + '/peers', { headers: this.$headers.getHeaders() })
            .map(res => res.json())
            .catch((err: Response) => Observable.throw(err.json()));
    }
    
    /**
     * Funciones de edicion, creación y eliminacion de peers
     */
    updatePeer(data: { peer: Peer, update: boolean }): Observable<IDBResponse> {
        let http$: Observable<Response>;
        if (data.update === undefined) {
            http$ = this.$http.delete(this.$headers.getAPIurl() + '/peer/' + data.peer.usuario, { headers: this.$headers.getHeaders() })
        } else if (data.update) {
            http$ = this.$http.post(this.$headers.getAPIurl() + '/peer', data.peer, { headers: this.$headers.getHeaders() })
        } else {
            http$ = this.$http.put(this.$headers.getAPIurl() + '/peer', data.peer, { headers: this.$headers.getHeaders() })
        }
        return http$.map(res => res.json())
            .catch((err: Response) => Observable.throw(err.json()));
    }
}
