import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';

@Component({
    selector: 'datepicker-ng2',
    styles: ['.invalid { border:1px solid #FF0000 }'],
    template: `<input [readonly]="readonly" name="datepicker_{{id}}" id="datepicker_{{id}}" class="text-center form-control {{estilo}}" placeholder="{{placeholder}}" [ngModel]="fecha">`,
    providers: [
        { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: DatePicker }
    ]
 })
export class DatePicker implements OnInit, ControlValueAccessor, AfterViewInit {
    private id: string;
    private placeholder: string;
    private _onChange: (value: any) => void;    
    
    private _fecha: string;
    private _formato: string = 'yyyy-mm-dd';
    private _idioma: string = 'es';
    
    @Input() estilo: string;
    @Input() readonly: boolean = false;
    
    @Input('fechaInicial') set fecha(fecha){
        if (!!fecha) {
            fecha = moment( fecha ).format('YYYY-MM-DD');
            this._fecha = fecha;
        } else
            this._fecha = undefined;
    }
    get fecha() { return this._fecha }
    
    @Input() set idioma(idioma) {
        this._idioma = (idioma) || 'es';
    }
    get idioma() { return this._idioma; }
    
    @Input() set formato(formato) {
        this._formato = (formato) || 'yyyy-mm-dd';
    }
    get formato() { return this._formato; }
    
    constructor() { }
    
    ngAfterViewInit() {
        
        $('#datepicker_' + this.id).datepicker({
            format: this.formato,
            autoclose: true, 
            language: this.idioma,
            todayHighlight: true,
            weekStart: 0
        });
        this.changeManager();
    }

    ngOnInit() {
        this.id = this.guid();   
        this.placeholder = moment().format(this.formato.toUpperCase());
    }
    
    writeValue(value: any) {
        this.fecha = value;
    }
    
    registerOnChange(fn: (value: any) => void) {
        this._onChange = fn;
    }
    
    registerOnTouched(){}
    
    private changeManager() {
        $('#datepicker_' + this.id).change(() => {
            this.fecha = <string>$('#datepicker_' + this.id).val();
            this._onChange(this.fecha);
        });
    }
    
    actualizar(fecha: string | Date) {
        fecha = moment(fecha).format('YYYY-MM-DD');
        $('#datepicker_' + this.id).datepicker('update', fecha);
    }
    
    private guid() {
        function s4() {
          return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }
} 