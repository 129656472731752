import { Component, OnInit, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { IAgentMonitor, EDBResponseType, IAgentStatusReason } from '../../../_interfaces/_all';
import { SocketService, EComando, EResponse, IComando } from '../../../_services/socket.service';
import { AgenteService } from '../../../_services/agente.service';
import { UsuarioService } from '../../../_services/usuario.service';
import * as uuid from '../../../_services/_objectID';
import * as moment from 'moment';
import { Subject } from 'rxjs/Subject';
@Component({
    selector: 'agent-op',
    templateUrl: './auxiliary.component.html'
})
export class AuxiliaryComponent implements OnInit, OnChanges {
    
    motivo: { motivo: string, descripcion: string } = { motivo: '', descripcion: ''};
    @Input() agente: IAgentMonitor;
    @Input() cola: string
    
    private reason: IAgentStatusReason;
    
    constructor(private $monitor: SocketService, private $agente: AgenteService, private $usuario: UsuarioService) { }

    ngOnInit() { }
    
    ngOnChanges(cambios: IChange) {
        if (!!cambios.agente && !!cambios.agente.currentValue && !!this.agente.estado_idreason) {
            this.$agente.getReason(this.agente.estado_idreason)
                .subscribe(response => {
                    if (response.length > 0) {
                        this.reason = response[0];
                        if (!(this.reason.fecha instanceof Date)) {
                            this.reason.fecha = moment(this.reason.fecha).format('lll')
                        }
                    }
                }, 
                err => console.log(err))
        }
    }
    
    lanzarOps() {
        this.motivo = {
            motivo: '',
            descripcion: ''
        };
        $('#agente-ops').modal();
    }
    
    colgarLlamada(motivo: {motivo: string, descripcion: string}) {
        const idreason = uuid.ObjectID();
        this.$agente.setReason({
                idreason: idreason,
                tipo: motivo.motivo,
                motivo: motivo.descripcion, 
                autor: this.$usuario.usuario.usuario
            })
            .flatMap(() => this.$monitor.enviarComando({
                comando: EComando.HANGUP_CALL,
                data: { 
                    callid: this.agente.channel.UniqueID,
                    channel: (this.agente.agentChannel.Channel || this.agente.channel.Channel),
                    agente: this.agente.idagente,
                    reason: idreason
                }
            }))
            .subscribe(res => console.log(res));
        
        $('#agente-ops').modal('hide');
    }
    
    desloguearAgente(motivo: {motivo: string, descripcion: string}) {
        this.$monitor.enviarComando({
            comando: EComando.AGENT_LOGOUT,
            data: { agente: this.agente.idagente, modoAgente: this.agente.modoAgente }
        }).subscribe(res => console.log(res));
        
        $('#agente-ops').modal('hide');
    }
    
    pausaAgente(motivo: {motivo: string, descripcion: string}, tipo: boolean) {
        const idreason = uuid.ObjectID();
        this.$agente.setReason({
            idreason: idreason,
            tipo: motivo.motivo,
            motivo: motivo.descripcion, 
            autor: this.$usuario.usuario.usuario
        })
        .flatMap(response => {
            if (response.tipo === EDBResponseType.QUERY_OK) {
                const data = { agente: this.agente.idagente, modo: '', modoAgente: this.agente.modoAgente, cola: this.cola, reason: idreason }        
                if (tipo) { // pausa
                    data.modo = 'pause';
                } else {  // unpause
                    data.modo = 'unpause';
                }

                return this.$monitor.enviarComando({
                            comando: EComando.AGENT_PAUSE,
                            data: data
                        });
            } else {
                const res = new Subject<{ tipo: EResponse, data: Object}>();
                res.next({
                    tipo: EResponse.FALLIDO,
                    data: response.data
                });
                return res.asObservable();
            }
        })
        .subscribe(
            response => {
                console.log(response)
                $('#agente-ops').modal('hide');
            }, err => console.log(err)
        );
    }
} 

interface IChange extends SimpleChanges {
    agente: SimpleChange
}
