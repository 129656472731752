import {Directive, AfterViewInit, ElementRef, Input, Renderer } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';

@Directive({
    selector: '[ngDatePicker]', 
    providers: [
        {provide: NG_VALUE_ACCESSOR, multi: true, useExisting: DatePickerDirective }
    ]
})
export class DatePickerDirective implements AfterViewInit, ControlValueAccessor {
    private id: string;
    private placeholder: string;
    private _onChange: (value: any) => void;    
    
    private _fecha: string;
    private _formato = 'yyyy-mm-dd';
    private _idioma = 'es';
    
    @Input() estilo = 'form-control';
    
    @Input('fechaInicial') set fecha(fecha) {
        if (!!fecha) {
            fecha = moment( fecha ).format('YYYY-MM-DD');
            this._fecha = fecha;
        } else {
            this._fecha = undefined;
        }
    }
    get fecha() { return this._fecha }
    
    @Input() set idioma(idioma) {
        this._idioma = (idioma) || 'es';
    }
    get idioma() { return this._idioma; }
    
    @Input() set formato(formato) {
        this._formato = (formato) || 'yyyy-mm-dd';
    }
    get formato() { return this._formato; }
    
    constructor(private el: ElementRef, private render: Renderer) { 
        this.id = this.guid();
        this.render.setElementAttribute(this.el.nativeElement, 'id', 'datepicker_' + this.id);
        this.render.setElementAttribute(this.el.nativeElement, 'placeholder', moment().format(this.formato.toUpperCase()));
        this.render.setElementClass(this.el.nativeElement, this.estilo, true);
        this.render.setElementStyle(this.el.nativeElement, 'text-align', 'center');
    }
    
    ngAfterViewInit() {
        this.placeholder = moment().format(this.formato.toUpperCase());
        $('#datepicker_' + this.id).datepicker({
            format: this.formato,
            autoclose: true, 
            language: this.idioma,
            todayHighlight: true,
            weekStart: 0
        });
        if (!!this.fecha) {
            $('#datepicker_' + this.id).datepicker('update', moment( this.fecha ).format('YYYY-MM-DD'))
        }
        this.changeManager();
    }
    
    writeValue(value: any) {
        if (!!value) {
            $('#datepicker_' + this.id).datepicker('update', value);
            this.fecha = value;
        }
    }
    
    registerOnChange(fn: (value: any) => void) {
        this._onChange = fn;
    }
    
    registerOnTouched() {}
    
    private changeManager() {
        $('#datepicker_' + this.id).change(() => {
            this.fecha = <string>$('#datepicker_' + this.id).val();
            if (!!this._onChange) {
                this._onChange(this.fecha);
            }
        });
    }
    
    private guid() {
        function s4() {
          return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }
}
