import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ToFechaPipe, TextLimitPipe, Upper} from './tipificaciones.component';
import {ResumentTipificacionComponent} from './resumen.component';
import {FormularioComponent, ContieneOtroPipe} from './formulario.component';
import {UIModule} from '../../_extra/ui/ui.module';

import {DatePickerModule} from '../../_extra/datepicker.module';
import {Ng2PaginationModule } from 'ng2-pagination';
import {ChartModule} from 'angular2-highcharts';
import * as highcharts from 'highcharts';

@NgModule({
    imports: [BrowserModule, FormsModule, ReactiveFormsModule, ChartModule.forRoot(highcharts), DatePickerModule, Ng2PaginationModule, UIModule],
    declarations: [ContieneOtroPipe, ToFechaPipe, TextLimitPipe, Upper, ResumentTipificacionComponent, FormularioComponent],
    exports: [ContieneOtroPipe, ToFechaPipe, TextLimitPipe, Upper, ResumentTipificacionComponent, FormularioComponent]
})
export class Tipificacion {}
