import { Injectable} from '@angular/core';
import { Headers } from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import { API_ADDRESS } from '../../_environments/environment';

@Injectable()
export class HeaderService {
    private headers: Headers;
    private httpHeaders: HttpHeaders;
    private apiRoute = '/api/v0.1';
    private callbackPort = '0626';
    
    constructor() {
        this.headers = new Headers();
        this.httpHeaders = new HttpHeaders();
    }
    
    getAPIurl(): string {
        return (!!API_ADDRESS ? API_ADDRESS : '') + this.apiRoute;
    }
    
    setHeader(header: {nombre: string, valor: string}) {
        if (!!this.headers.get(header.nombre)) {
            this.headers.delete(header.nombre);
        }
        this.headers.append(header.nombre, header.valor);        
        // httpHeaders es inmutable
        this.httpHeaders = this.httpHeaders.delete(header.nombre).append(header.nombre, header.valor);
    }
    
    getHeader(nombre: string): string {
        return this.headers.get(nombre);
    }
    
    getHeaders() {
        return this.headers;
    }

    getHttpHeaders() {
        return this.httpHeaders;
    }

    getCallbackPort(): string {
        return this.callbackPort;
    }
}
